import React from 'react';
import classNames from 'classnames'
import { ElfCard } from 'elf-ui'
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import ImageIntroduce from '@/assets/img/product/zngs/image-introduce.png';
import ImageBusiness from '@/assets/img/product/zngs/image-business.png';
import ImageCase from '@/assets/img/product/zngs/image-case.png';
import Title from './Title'

const { Meta } = ElfCard;

const Service_zngs = () => {
  return (
    <div className={styles['rz-container']}>
      <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageIntroduce} />} coverStyle={{left: '20px'}} bodyClassName={styles['rz-card-lg']}>
        <Meta style={{marginTop: '-10px', paddingRight: '20px', paddingLeft: '25px'}}>
          <Title text={<FormattedMessage id="product.rz.introduce.title" />}/>
          <div className={styles['rz-text']} style={{marginTop: '10px', marginBottom: '5px'}}><FormattedMessage id="product.zngs.introduce.text1" /></div>
          <Title text={<FormattedMessage id="product.rz.characteristic.title" />} style={{marginBottom: '10px'}}/>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.zngs.characteristic.text1" /></span>
            <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text2" /></span>
            <span><FormattedMessage id="product.zngs.characteristic.text3" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.zngs.characteristic.text4" /></span>
            <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text5" /></span>
            <span><FormattedMessage id="product.zngs.characteristic.text6" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.zngs.characteristic.text7" /></span>
            <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text8" /></span>
            <span><FormattedMessage id="product.zngs.characteristic.text9" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.zngs.characteristic.text10" /></span>
            <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text11" /></span>
            <span><FormattedMessage id="product.zngs.characteristic.text12" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.zngs.characteristic.text13" /></span>
            <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text14" /></span>
            <span><FormattedMessage id="product.zngs.characteristic.text15" /></span>
          </div>
        </Meta>
      </ElfCard>
      <ElfCard offset={{right: '345px'}} bodyOffset={{right: '300px'}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={ImageBusiness} />} coverStyle={{right: '0px'}} style={{marginTop: 30}} 
      >
        <Meta >
          <Title text={<FormattedMessage id="product.business-object" />} style={{marginTop: '20px'}} />
          <div className={classNames(styles['rz-text'])} style={{marginTop: '20px', marginBottom: '20px'}}><FormattedMessage id="product.zngs.business-object.text" /></div>
          <Title text={<FormattedMessage id="product.business-scenario" />} style={{marginBottom: '20px'}} />
          <div className={styles['rz-text']}><span><FormattedMessage id="product.zngs.business-scenario.text" /></span></div>
        </Meta>
      </ElfCard>
      
      <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageCase} />} coverStyle={{left: '32px', width: '600px'}} bodyClassName={styles['rz-card-lg']} style={{marginTop: 30}} >
        <Meta style={{paddingRight: '20px', paddingLeft: '25px'}}>
          <div style={{lineHeight: '33px'}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.rz.case.title1" /></div>
          <div style={{lineHeight: '33px'}} className={styles['rz-case-text']}><FormattedMessage id="product.zngs.case.text" /></div>
        </Meta>
      </ElfCard>
    </div>
  );
};

export default Service_zngs;