export default {
  "recruit.site" : '工作地点',
  'recruit.position': '职位类别',
  'recruit.expand': '展开全部',
  'recruit.option.all': '全部',
  'recruit.option.view': '查看',
  'recruit.column.position.name': '职位名称',
  'recruit.column.position.type': '职位类别',
  'recruit.column.position.site': '工作地点',
  'recruit.column.position.info': '职位详情',
}