import React from 'react';
import {connect} from 'dva'
import Media from 'react-media';
import Banner from './Banner'
import Layout from './Layout'
import  Qs from 'qs';

import BannerMobile from './BannerMobile'
import IntroduceMobile from './IntroduceMobile'
import TeamMobile from './TeamMobile'
import PartnerMobile from './PartnerMobile'
import BusinessDistributionMobile from './BusinessDistributionMobile'
import RecruitMobile from './RecruitMobile'

const Company = (props) => {
  const { location:{search, state}, history} = props;
  const { sType } = Qs.parse(search.replace(/^\?/, ''));
  return (
    <div>
      <Banner />
      <Layout sType={sType} position={state ? state.position :undefined} history={history}/>
    </div>
  );
};

const CompanyMobile = (props) => {
  // const { location:{search, state}, history} = props;
  // const { sType } = Qs.parse(search.replace(/^\?/, ''));
  return (
    <div>
      <BannerMobile />
      <IntroduceMobile />
      <TeamMobile />
      <PartnerMobile />
      <BusinessDistributionMobile />
      <RecruitMobile {...props} />
    </div>
  );
};

export default connect()(props => (
  <Media query="(max-width: 999px)">
    {isMobile => isMobile ? <CompanyMobile {...props} /> : <Company {...props} />}
  </Media>
));
