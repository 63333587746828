import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import styles from './menu.m.less'
import { Modal} from 'antd'
import {fastDfsUrl} from 'utils/String'
import { Player, BigPlayButton } from 'video-react';

const Index = ({selectPath=[], menu=[], onClick}) => {
  const [hover, onHover] = useState('');
  const onItemClick = (key, path) => {
    onClick(`${path.startsWith('/') ? '' : '/'}${path}`);
  }

  const [visible, onVisible] = useState(false);
  const [video, onVideo] = useState(undefined);
  const videoRef = useRef(null);

  const openVideo = (url) => {
    const reload = !(video === url);
    onVideo(url);
    onVisible(true);
    setTimeout(() => {
      if(videoRef.current){
        videoRef.current.volume=0.3;
        const { player } = videoRef.current.getState();
        //当前播放时间
        if(reload){
          videoRef.current.load();
        }else{
          if(!player.ended){
            videoRef.current.play();
          }
        }
       
      }
    }, 100);
  }

  const closeVideo = () => {
    onVisible(false); 
    videoRef.current.pause();
  }
  const ulMenu = menu.map((item, index) => {
    if(item.link){
      return <li key={index}  
        onMouseEnter={() => onHover(item.key)} onMouseLeave={() => onHover('')}>
          <a href={item.link} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank">
            <div className={styles['menu-container']} >
              <div className={styles["text"]}>{item.title}</div>
              <div className={styles["under-ico"]}>
                <div className={styles["under-ring"]}></div>
                <div className={styles["under-line"]}></div>
              </div>
            </div>
          </a>
      </li>
    }
    if(item.video){
      return <li key={index}  
        onMouseEnter={() => onHover(item.key)} onMouseLeave={() => onHover('')}>
          <div className={styles['menu-container']} onClick={ () => openVideo(item.video)}>
            <div className={styles["text"]}>{item.title}</div>
            <div className={styles["under-ico"]}>
              <div className={styles["under-ring"]}></div>
              <div className={styles["under-line"]}></div>
            </div>
          </div>
      </li>
    }
    let actived = selectPath.length > 0 && selectPath[0].replace('/', '') === item.key.replace('/', '') ? "true" : "false";
    const hasChild = item.children && item.children.length>0;
    const renderChild = hasChild && item.children.map((it, i) => {
      let child_actived = "false";
      if(actived === "true" && selectPath.length > 1){
        child_actived = selectPath[1].replace('/', '') === it.key.replace('/', '') ? "true" : "false"
      }
      const onClick = () => {
        onHover('')
        onItemClick([item.key, it.key], `${item.key.startsWith('/') ? '' : '/'}${item.key}${it.key.startsWith('/') ? '' : '/'}${it.key}`)
      };
      return <div key={i} className={styles['submenu-item']} onClick={onClick} active={child_actived}>{it.title}</div>;
    })


    return <li key={index} onClick={hasChild ? null : () => onItemClick([item.key], item.key)} active={actived} 
      onMouseEnter={() => onHover(item.key)} onMouseLeave={() => onHover('')}>
      <div className={styles['menu-container']} has-childs={hasChild ? "true" : "false"}>
        <div className={styles["text"]}>{item.title}</div>
        <div className={styles["under-ico"]}>
          <div className={styles["under-ring"]}></div>
          <div className={styles["under-line"]}></div>
        </div>
        {
          hasChild && 
          <QueueAnim className={styles["submenu-container"]}
            animConfig={[
              { opacity: [1, 0], translateY: [0, 50] },
              { opacity: [1, 0], translateY: [0, 0] }
            ]}>
            {hover === item.key ? [
                <div key="list" className={styles["submenu-list"]} >{renderChild}</div>
            ] : null}
          </QueueAnim>
        }
      </div>
    </li>
  });

  return <div className={styles.menu}>
    <ul>
      {ulMenu}
    </ul>
    <Modal
        className={styles['modal-wrapper']}
        width='100%'
        visible={visible}
        onCancel={closeVideo}
        footer={null}
      >
        <Player 
          ref={videoRef} 
          autoPlay={true}
         >
           <BigPlayButton position="center" disabled />
           {
             video && <source src={fastDfsUrl(video)}/>
           }
        </Player> 
      </Modal>
  </div>;
};

Index.propTypes = {
  defaultActive: PropTypes.array.isRequired,
  menu: PropTypes.array.isRequired,
  onClick: PropTypes.func
};



export default Index;