import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import dva from 'dva';
import createLoading from 'dva-loading';
import {createBrowserHistory} from 'history'
// import VConsole from 'vconsole' //import vconsole

import { IntlProvider } from 'react-intl'; 
import { chooseLocale } from "./utils/Language";

moment.locale('zh-cn');
// if(process.env.NODE_ENV==="development"){
//     const vConsole = new VConsole();
// }


// 1. Initialize
const app = dva({
    history:createBrowserHistory()
});
app.use(createLoading());
// 注册视图
app.router(() => <App />);

//注册model
// app.model(require('./models/login').default);
// Model 下面采用遍历key获取到所有的model
require('./models').default.forEach(key => app.model(key.default))

// 启动应用
// app.start('#root');
const Root = app.start();
ReactDOM.render(<IntlProvider locale="zh-cn" messages={chooseLocale()} ><ConfigProvider locale={zhCN}><Root /></ConfigProvider></IntlProvider>, document.getElementById('root'));
// ReactDOM.render(<ConfigProvider locale={zhCN}><Root /></ConfigProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
