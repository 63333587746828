export default {
  "company.banner.text1" : '人力资源产业',
  'company.banner.text2': '一站式供应链数字科技服务解决方案提供商',
  'company.menu.company': '企业介绍',
  'company.menu.team': '团队介绍',
  'company.menu.partner': '合作伙伴',
  'company.menu.business': '业务分布',
  'company.menu.joinUS': '加入我们',
  'company.introduce.title':'企业介绍',
  // 'company.introduce.text':' 薪企链隶属于薪海科技（上海）有限公司，是一家人力资源产业供应链数字化科技服务云平台。公司总部位于上海，在北京、天津、广州、深圳、江苏、浙江、山东等地设有办事机构，核心团队来自股份制银行总行高管、知名互联网科技公司、人力资源服务公司资深管理者等。平台运用大数据、区块链、人工智能、云计算等技术，将人力资源劳务派遣外包业务实现线上化、数据化、场景化、自动化，为人力资源服务企业提供科技技术支持。薪企链通过对资产客户端、资金渠道端和员工端的无缝对接，形成一个闭环业务生态，有效为人力资源上下游企业、员工提供数字化服务，为其有效解决应收账款融资、发薪可视化、增信、提效等难题。',
  'company.introduce.text1':'薪海科技（上海）有限公司，是一家人力资源产业供应链数字科技服务云平台。',
  'company.introduce.text2':'薪海科技旗下共有',
  'company.introduce.text3':'三大平台',
  'company.introduce.text4':'，分别是：',
  'company.introduce.text5':'中国人力资源服务业上、下游企业B2B灵活用工撮合交易服务云平台——薪企链；',
  'company.introduce.text6':'中国人力资源服务业大数据云平台；',
  'company.introduce.text7':'中国人力资源服务供应链金融科技SaaS平台——薪企链。',
  'company.introduce.text8':'同时，薪海科技拥有',
  'company.introduce.text9':'四家全资子公司',
  'company.introduce.text10':'，分别是：',
  'company.introduce.text11':'薪海数字科技(烟台)有限公司、薪企链(深圳)科技有限公司、薪保链数字科技(南京)有限公司、优岗乐活(上海)人力资源科技有限公司',
  'company.introduce.text12':'。公司总部位于上海，在北京、天津、广州、深圳、江苏、浙江、山东等地设有办事机构，核心团队来自股份制银行总行高管、知名互联网科技公司、人力资源服务公司资深管理者等。',
  'company.introduce.text13':'平台运用大数据、区块链、人工智能、云计算等技术，将人力资源劳务派遣外包业务实现线上化、数据化、场景化、自动化，为人力资源服务企业提供科技技术支持。薪企链通过对资产客户端、资金渠道端和员工端的无缝对接，形成一个闭环业务生态，有效为人力资源上下游企业、员工提供数字化服务，为其有效解决融资、发薪、增信、提效等难题。',
  'company.introduce.text14':'',
  'company.introduce.text15':'',
  'company.introduce.text16':'',
  'company.introduce.text17':'',
  'company.honor.content.text1': '同薪协力',
  'company.honor.content.text2': '，再创佳绩',
  'company.soft.content.text1': '通过技术创新，助力',
  'company.soft.content.text2': '人力资源企业转型升级',
  'company.development.content.text1': '薪海人',
  'company.development.content.text2': '，不忘初衷，同心协力，砥砺前行',
  'company.business.title': '业务覆盖全国三十多个省、自治区、直辖市',
  'company.business.content': '上海、北京、广州、深圳、南京、苏州、昆山、杭州、宁波、厦门、福州、东莞、天津、山东等。',

  'company.introduce.link':'薪企链百科'
}