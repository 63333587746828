import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './index.m.less'
import classNames from 'classnames'
import {CSSTransition} from 'react-transition-group';

class index extends Component {

  constructor(props){
    super(props);
    this.state={
      active: 0,
      direction: 'R2L', //R2L:右进左出  L2R:左进右出
    }
    this.timer=undefined;
  }


  componentDidMount(){
    const {autoPlay=false, autoPlaySpeed=3000} = this.props;
    if(autoPlay){
      this.timer = setInterval(() => this.onToggleAuto(), autoPlaySpeed);
    }
  }

  UNSAFE_componentWillMount() {
    if(this.timer){
      clearInterval(this.timer);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    const {autoPlay, autoPlaySpeed} = newProps;
    if(autoPlay){
      this.timer = setInterval(() => this.onToggleAuto(), autoPlaySpeed);
    }else{
      if(this.timer){
        clearInterval(this.timer);
      }
    }

  }
  
  onToggleAuto = () => {
    const {active} = this.state;
    const {children=[] } = this.props;
    this.setState({
      direction: 'R2L',
      active: active < children.length -1 ? active + 1 : 0,
    })
  }

  onToggleDots = (next, direction) => {
    const {active} = this.state;
    if(active === next){
      return;
    }
    //面板切换
    if(next < active){
      this.setState({
        direction: direction || 'L2R',
        active: next,
      })
    }else if(next > active){
      this.setState({
        direction: direction || 'R2L',
        active: next,
      })
    }
    const {autoPlay=false, autoPlaySpeed=3000} = this.props;
    if(autoPlay){
      clearInterval(this.timer);
      this.timer = setInterval(() => this.onToggleAuto(), autoPlaySpeed);
    }
  }

  render() {
    const {dots=true, className={}, children=[] } = this.props;
    const {active, direction} = this.state;

    const elementRender = children.map((item, index) => {
      return <CSSTransition key={index}
              in={ active === index }
              timeout={ 400 }
              classNames={{
                appear: styles[`horiz${direction}-appear`],
                // appearActive: styles['horizontal-left-active-appear'],
                enter: styles[`horiz${direction}-enter`],
                enterActive: styles[`horiz${direction}-enter-active`],
                enterDone: styles[`horiz${direction}-enter-done`],
                // exit: styles[`horiz${direction}-exit`],
                // exitActive: styles[`horiz${direction}-exit-active`],
                // exitDone: styles[`horiz${direction}-done-exit`],
              }}
              unmountOnExit
              // onEntered={el => {el.style.color = 'blue'}}
              //是否第一次加载该组件时启用相应的动画渲染，css文件中有含有appear的均和此标签相关
              appear={false}
            >
              <div className={styles['carousel-container']}>
                {item}
              </div>
            </CSSTransition>
    })
    const dotRender = children.map((item, index) => {
      return <span key={index} onClick={() => this.onToggleDots(index)} actived={active === index ? 'true' : 'false'}></span>
    })
    return (
      <div className={classNames(styles.carousel, className)}>
        <div className={styles['carousel-content']}>
          {elementRender}
        </div>
        {
          dots && <div className={styles['carousel-thumb-default']}>
                    {dotRender}
                  </div>
        }
      </div>
    );
  }
}

index.propTypes = {
  autoPlay: PropTypes.bool,
  autoPlaySpeed: PropTypes.number,
  children: PropTypes.array.isRequired
};

export default index;