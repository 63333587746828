import http from '@http'

export async function list(params) {
    return http.post('/merchantext/recruitmentInfo/list', params);
}

export async function getParams(params) {
    return http.post('/merchantext/recruitmentField/getParam', params);
}

export async function getInfo(params) {
    return http.post('/merchantext/recruitmentInfo/detail', params);
}