import React from 'react';
import { ElfList } from 'elf-ui'
import QueueAnim from 'rc-queue-anim'; 
import {Row} from 'antd'
import styles from './index.m.less'
import Cover_BankJJ from '@/assets/img/company/partner/cover_bank_jj.png';
import Cover_BankSHNS from '@/assets/img/company/partner/cover_bank_shns.png';
import Cover_BankLH from '@/assets/img/company/partner/cover_bank_lh.png';
import Cover_BankWZ from '@/assets/img/company/partner/cover_bank_wz.png';
import Cover_BankJS from '@/assets/img/company/partner/cover_bank_js.png';
import Cover_BankSN from '@/assets/img/company/partner/cover_bank_sn.png';
import Cover_TKing from '@/assets/img/company/partner/cover_tking.png';
import Cover_RNO from '@/assets/img/company/partner/cover_rno.png';
import Cover_Engma from '@/assets/img/company/partner/cover_engma.png';
import Cover_Haier from '@/assets/img/company/partner/cover_haier.png';
import Cover_JD from '@/assets/img/company/partner/cover_jd.png';
import Cover_MT from '@/assets/img/company/partner/cover_mt.png';
import Cover_BCW from '@/assets/img/company/partner/cover_bcw.png';
import Cover_SF from '@/assets/img/company/partner/cover_sf.png';
import Cover_ELM from '@/assets/img/company/partner/cover_elm.png';
import Cover_BSKD from '@/assets/img/company/partner/cover_bskd.png';
import Cover_LXJM from '@/assets/img/company/partner/cover_lxjm.png';
import Cover_GT from '@/assets/img/company/partner/cover_gt.png';

const {Grid} = ElfList;

const partner_list = [Cover_BankJJ, Cover_BankSHNS, Cover_BankLH, 
  Cover_BankWZ, Cover_BankJS, Cover_BankSN,
  Cover_TKing, Cover_RNO, Cover_Engma,
  Cover_Haier, Cover_JD, Cover_MT,
  Cover_BCW, Cover_SF, Cover_ELM,
  Cover_BSKD, Cover_LXJM, Cover_GT
]

const Partner = () => {
  const _render_list = partner_list.map((item, i) => <div key={i} className={styles['partner-image']} 
      style={{background: `url(${item}) no-repeat center center`, backgroundSize: 'contain'}}></div>);
  return (
    <div className={styles['partner-container']}>
      <div className={styles.partner}>
        <Grid columns={3} gutter={[24, 20]}
        components = {(child) => <QueueAnim delay={200}  type="bottom" key="partner" ease="easeOutQuart" leaveReverse>{child}</QueueAnim>}
        onRow={(row, i) => <QueueAnim interval={100} type="bottom" key={i} >{row}</QueueAnim>}
        >
          {_render_list}
        </Grid>
      </div>
      
    </div>
  );
};

export default Partner;