export default {
  "xqldata.banner01.title" : '大数据可视化系统',
  'xqldata.banner01.text': '为企业整个业务流程提供画像服务，以数据交互可视化方式展现业务中台脉络运转。',
  "xqldata.banner02.title" : '大数据风险预警系统',
  'xqldata.banner02.text': '提供风险预警服务，全天候对企业进行监控，帮助风控进行指定维度审核，及时控制风险。',
  "xqldata.banner03.title" : '大数据智能风控系统',
  'xqldata.banner03.text': '提供风险控制服务，构建成熟的风控引擎和若干规则集进行业务驱动，风控模型构建，进一步数据验真和交叉验证。',
  "xqldata.banner04.title" : 'AI人工智能识别系统',
  'xqldata.banner04.text': '包括：通用场景文字识别、卡证文字识别、交通场景文字识别、财务票据文字识别、医疗票据文字识别、教育场景文字识别等。',

  "xqldata.visual.title" : '大数据可视化系统',
  "xqldata.visual.item-01.title" : '大数据业务地图',
  "xqldata.visual.item-01.text" : '业务遍布全国二十多个省直辖市自治区，各大区客户经理与风控经理同步协作为客户提供供应链金融服务，由大数据仓库汇总统计。',
  "xqldata.visual.item-02.title" : '客户类别分析',
  "xqldata.visual.item-02.text" : '大数据可视化系统将客户按照行业门类统计汇总，以便对行业垂直领域进行分析，根据客户业务主体提供个性化服务。',
  "xqldata.visual.item-03.title" : '大数据入库信息',
  "xqldata.visual.item-03.text" : '大数据仓库拥有包括工商，财务，经营，科技，资产，舆情等维度的16万+人力资源企业信息，并在不断扩充，更新。',
  "xqldata.visual.item-04.title" : '融资订单可视化',
  "xqldata.visual.item-04.text" : '根据业务流程，对统计周期内订单进度可视化，把控业务流程中各节点准确数据。',

  "xqldata.risk-warning.title" : '大数据风险预警系统',
  "xqldata.risk-warning.item-01.title" : '企业风险',
  "xqldata.risk-warning.item-01.text" : '诉讼风险、经营风险、负面消息、逾期风险和变更风险，系统根据风险级别分时段分频率监控企业，及时为风控经理预警风险、规划风险应对和实施风险应对措施。',
  "xqldata.risk-warning.item-02.title" : '回款监控',
  "xqldata.risk-warning.item-02.text" : '获取最近还款企业的还款数据，展示折线图曲线效果，帮助风控经理贷后分析企业还款趋势。',
  "xqldata.risk-warning.item-03.title" : '风险维度',
  "xqldata.risk-warning.item-03.text" : '展示获取企业风险等级趋势折线图和风险维度分布饼状图，帮助风控经理实施定量风险分析。',
  "xqldata.risk-warning.item-04.title" : '监控通知',
  "xqldata.risk-warning.item-04.text" : '实时监控企业风险，以微信公众号的形式定时推送给风控经理，帮助查看风险详情，并迅速跟踪定位到具体的风险内容，有效降低和避免风险发生概率。',


  "xqldata.risk-control.title" : '大数据智能风控系统',
  "xqldata.risk-control.item-01.title" : '风险管理',
  "xqldata.risk-control.item-01.text" : '事件模型，贷前、贷中和贷后模型。可以定义多个策略，每个策略都可以独立配置规则和单独打分，最终计算出模型总分。',
  "xqldata.risk-control.item-02.title" : '评分卡模型',
  "xqldata.risk-control.item-02.text" : '新用户进入评分系统时，会将每个分箱且进行WOE编码后的特征值乘上它们对应的权重，再加上基础分数，得到这名用户的评分值。',
  "xqldata.risk-control.item-03.title" : '企业征信报告',
  "xqldata.risk-control.item-03.text" : '数据源来源于第三方企业数据和第三方征信数据，具体包括企业基本信息、资产、工商、行政、司法、违法、财务、舆情、任职、人员和征信等第三方征信。',
  "xqldata.risk-control.item-04.title" : '风险征信查询',
  "xqldata.risk-control.item-04.text" : '两大查询模块，通过企业名称可以自由查询获取任意维度的信息。',


  "xqldata.artificial-intelligence.title" : 'AI人工智能识别系统',
  "xqldata.artificial-intelligence.item-01.title" : '名片识别',
  "xqldata.artificial-intelligence.item-01.text" : '支持对各类名片识别，包括姓名、公司、职位、邮编、邮箱、电话、网址、地址、手机号。  ',
  "xqldata.artificial-intelligence.item-02.title" : '车牌识别',
  "xqldata.artificial-intelligence.item-02.text" : '支持各类机动车车牌识别、并能同时识别图像中的多张车牌。',
  "xqldata.artificial-intelligence.item-03.title" : '行驶证识别',
  "xqldata.artificial-intelligence.item-03.text" : '对机动车行驶证主页及副页所有字段进行结构化识别。',
  "xqldata.artificial-intelligence.item-04.title" : '银行卡号识别',
  "xqldata.artificial-intelligence.item-04.text" : '支持对主流银行卡的卡号、有效期、发卡行、卡片类型进行结构化识别。',
  "xqldata.artificial-intelligence.item-05.title" : '驾驶证识别',
  "xqldata.artificial-intelligence.item-05.text" : '支持对机动车驾驶证正页及副页所有字段进行结构化识别。',
  "xqldata.artificial-intelligence.item-06.title" : '发票识别',
  "xqldata.artificial-intelligence.item-06.text" : '支持对增值税普票、专票、卷票、电子发票、区块链发票的所有字段进行结构化识别。',
  
}