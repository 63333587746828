/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import police from '@/assets/img/police.png'
import voucher from '@/assets/img/voucher.png'
import wechat from '@/assets/img/wechat-xql.png'
import wechatCustomer from '@/assets/img/wechat-xql-customer.png'
import styles from './footer.m.less';
import  Qs from 'qs';
import {fastDfsUrl} from 'utils/String'

const Footer = (props) => {
    const {history} = props;
    const gotoRouter = (path, sType, position) => {
        history.push({pathname: path, search: Qs.stringify({sType: sType}), state:{ position: position }})
    }
    return (
        <div className={styles.footer}>
            <div className={styles["content-container"]}>
                <div className={styles.content}>
                    <div className={styles["text-conatiner"]} style={{flex: '1', paddingRight: 50}}>
                        <div className={styles["text-title"]}><FormattedMessage id="system.footer.company" /></div>
                        <div><FormattedMessage id="system.footer.company.phone" /></div>
                        <div><FormattedMessage id="system.footer.company.hotline" /></div>
                        <div><FormattedMessage id="system.footer.company.email" /></div>
                        <div><FormattedMessage id="system.footer.company.working-time" /></div>
                        <div><FormattedMessage id="system.footer.company.address-sh" /></div>
                        <div><FormattedMessage id="system.footer.company.address-sz" /></div>
                        <div><FormattedMessage id="system.footer.company.address-zj" /></div>
                    </div>
                    <div className={styles["text-conatiner"]} style={{width: 200}}>
                        <div className={styles["text-title"]}><FormattedMessage id="system.footer.about.xql" /></div>
                        <div onClick={() => gotoRouter('/company.htm', "cp")} className={styles["text-link"]}><FormattedMessage id="system.footer.about.company" /></div>
                        <div onClick={() => gotoRouter('/company.htm', 'cp', 'honor-position')} className={styles["text-link"]}><FormattedMessage id="system.footer.about.honor" /></div>
                        <div onClick={() => gotoRouter('/company.htm', 'cp', 'development-position')} className={styles["text-link"]}><FormattedMessage id="system.footer.about.develop" /></div>
                        <div onClick={() => gotoRouter('/company.htm', 'tm')} className={styles["text-link"]}><FormattedMessage id="system.footer.about.team" /></div>
                        <div onClick={() => gotoRouter('/company.htm', 'pt')}className={styles["text-link"]}><FormattedMessage id="system.footer.about.partner" /></div>
                        <div onClick={() => gotoRouter('/company.htm', 'bn')}className={styles["text-link"]}><FormattedMessage id="system.footer.about.business" /></div>
                    </div>
                    <div className={styles["text-conatiner"]} style={{width: 200}}>
                        <div className={styles["text-title"]}><FormattedMessage id="system.footer.product.hot" /></div>
                        <div onClick={() => gotoRouter('/product.htm/gzdz')} className={styles["text-link"]}><FormattedMessage id="system.footer.product.salary" /></div>
                        <div onClick={() => gotoRouter('/product.htm/zngs')} className={styles["text-link"]}><FormattedMessage id="system.footer.product.intelligent-time" /></div>
                        <div onClick={() => gotoRouter('/product.htm/bxdl')} className={styles["text-link"]}><FormattedMessage id="system.footer.product.insurance-agent" /></div>
                        <div onClick={() => gotoRouter('/product.htm/b2b')} className={styles["text-link"]}><FormattedMessage id="system.footer.product.B2B" /></div>
                    </div>
                    <div className={styles["wechat-conatiner"]} style={{width: 290 }}>
                        <div className={styles["wechat"]} style={{flex: '1'}}>
                            <div className={styles["wechat-img"]} style={{background: `url(${wechat}) no-repeat center center`, backgroundSize: 'contain'}}></div>
                            <div className={styles["wechat-text"]}><FormattedMessage id="system.footer.wechat-xql" /></div>
                        </div>
                        <div className={styles["wechat"]} style={{width: 120 }}>
                            <div className={styles["wechat-img"]} style={{background: `url(${wechatCustomer}) no-repeat center center`, backgroundSize: 'contain'}}></div>
                            <div className={styles["wechat-text"]}><FormattedMessage id="system.footer.wechat-xql-customer" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["copyright-container"]}>
                <div className={styles.copyright}>
                    <span>
                        <FormattedMessage id="system.footer.copyright" />
                    </span>
                    <span>
                        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank"><FormattedMessage id="system.footer.icp" /></a>
                    </span>
                    <span>
                        <img src={police} alt=""  width={14} height={14} />
                        <a href="https://tsm.miit.gov.cn/dxxzsp/" target="_blank"  style={{marginLeft: 4}} ><FormattedMessage id="system.footer.miit"/></a>
                    </span>
                    <span>
                        <img src={voucher} alt="" width={20} height={20} />
                        <a href={fastDfsUrl("xinhai1/M00/02/63/oYYBAGFuKP6ALxOeAC2O78oD99g790.jpg")} target="_blank" style={{marginLeft: 8, textDecoration: 'underline'}} ><FormattedMessage id="system.footer.voucher"/></a>
                    </span>
                </div>
                
            </div>
            
        </div>
    );
};

export default Footer;