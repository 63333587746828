import React, { useState ,useEffect, useRef} from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames'
import {Row, Col, Carousel} from 'antd'
import { Player, BigPlayButton } from 'video-react';
import {ElfTimeline} from 'elf-ui'
import {fastDfsUrl} from 'utils/String'
import styles from './mobile.m.less'
import HonorImage_01 from '@/assets/img/company/honor/image-01.png'
import HonorImage_03 from '@/assets/img/company/honor/image-03.png'
import HonorImage_04 from '@/assets/img/company/honor/image-04.png'
import HonorImage_05 from '@/assets/img/company/honor/image-05.png'
import HonorImage_06 from '@/assets/img/company/honor/image-06.png'
import HonorImage_08 from '@/assets/img/company/honor/image-08.png'
import HonorImage_09 from '@/assets/img/company/honor/image-09.png'
import HonorImage_11 from '@/assets/img/company/honor/image-11.png'
import HonorImage_12 from '@/assets/img/company/honor/image-12.png'
import HonorImage_13 from '@/assets/img/company/honor/image-13.png'
import HonorImage_14 from '@/assets/img/company/honor/image-14.png'

import SoftImagePage1_01 from '@/assets/img/company/software/image-page1-01.jpg';
import SoftImagePage1_02 from '@/assets/img/company/software/image-page1-02.jpg';
import SoftImagePage1_03 from '@/assets/img/company/software/image-page1-03.jpg';
import SoftImagePage1_04 from '@/assets/img/company/software/image-page1-04.png';
import SoftImagePage1_05 from '@/assets/img/company/software/image-page1-05.png';
import SoftImagePage1_06 from '@/assets/img/company/software/image-page1-06.png';
import SoftImagePage1_07 from '@/assets/img/company/software/image-page1-07.jpg';
import SoftImagePage1_08 from '@/assets/img/company/software/image-page1-08.jpg';
import SoftImagePage2_01 from '@/assets/img/company/software/image-page2-01.png';
import SoftImagePage2_02 from '@/assets/img/company/software/image-page2-02.png';
import VideoPost from '@/assets/img/company/introduce/video-post.png';
import '../../../node_modules/video-react/dist/video-react.css'

const honor_list = [
  [
    {
      children: [
        {image: HonorImage_11, title: '金典奖（行业）年度十大创新企业', year: '2021', month: '05-23'},
        {image: HonorImage_12, title: '金典奖（行业）最具影响力十佳明星企业', year: '2021', month: '05-23'}
      ]
    },
    {
      children: [
        {image: HonorImage_13, title: '优秀现代供应链服务商', year: '2021', month: '03-30'},
        {image: HonorImage_06, title: '中国(宁波)人力资源服务创新创业大赛三等奖', year: '2020', month: '11-05'}
      ]
    },
    {image: HonorImage_01, title: '2021中国人力资源服务机构TOP100强', year: '2021', month: '03-26'},
    {
      children: [
        {image: HonorImage_08, title: '中国（行业）领军企业', year: '2021', month: '01-09'},
        {image: HonorImage_09, title: '中国金融科技创新企业', year: '2021', month: '01-09'}
      ]
    },
  ],
  [
    {image: HonorImage_14, title: '2020年南京人力资源产业优秀品牌产品', year: '2020', month: '07-01'},
    {image: HonorImage_05, title: '2020中国人力资源最佳数字化服务创新奖', year: '2020', month: '12-25'},
    {image: HonorImage_03, title: '2020年度人力资源最具成长力品牌奖', year: '2020', month: '08-29'},
    {image: HonorImage_04, title: '2020年度人力资源创新服务大奖', year: '2020', month: '08-09'}
  ]
]

const soft_list = [
  [
    {image: SoftImagePage1_01},
    {image: SoftImagePage1_02},
    {image: SoftImagePage1_03},
    {image: SoftImagePage1_04},
    {image: SoftImagePage1_05},
    {image: SoftImagePage1_06}
  ],
  [
    {image: SoftImagePage1_07},
    {image: SoftImagePage1_08},
    {image: SoftImagePage2_01},
    {image: SoftImagePage2_02}
  ]
]

const development_buttons = ['2021', '2018-2020'];

const development_list = [
  {button:'2021', title: '2021年10月', text: ['B2B用工交易平台上线']},
  {button:'2021', title: '2021年9月', text: ['A轮融资']},
  {button:'2021', title: '2021年8月', text: ['高新企业认定']},
  {button:'2021', title: '2021年7月', text: ['公安部三级等保认证']},
  {button:'2021', title: '2021年6月', text: ['2020-2021年度中国区数字化创新领先企业50强','首届中国（南京）国际人力资源信息化创新创业大赛创新产品三等奖']},
  {button:'2021', title: '2021年5月', text: [<span>荣获<span style={{fontWeight: 600}}>金典奖</span>（行业）年度十大创新企业</span>,<span>荣获<span style={{fontWeight: 600}}>金典奖</span>（行业）最具影响力十佳明星企业</span>]},
  {button:'2021', title: '2021年4月', text: ['与商务部研究院联合开发“企业数字征信系统”启动','2020年度南京市人力资源产业科技创新先锋奖']},
  {button:'2021', title: '2021年3月', text: ['2021中国人力资源服务机构TOP100强','2021中国供应链金融生态优秀现代供应链服务商','薪企链平台与建行深圳分行系统正式打通']},
  {button:'2021', title: '2021年1月', text: '薪海科技荣获”中国（行业）领军企业'},
  {button:'2018-2020', title: '2020年12月', text: ['荣获”2020中国人力资源','先锋服务机构最佳数字化服务创新奖”','获得第10项计算机软件著作登记证']},
  {button:'2018-2020', title: '2020年11月', text: ['智能工时信息采集系统（即工时本）版正式上线','荣获“2020中国(宁波)人力资源服务创新创业大赛三等奖”']},
  {button:'2018-2020', title: '2020年10月', text: '薪海科技大数据智能风控平台正式上线'},
  {button:'2018-2020', title: '2020年8月', text: '薪海科技荣获“2020年度（人力资源)最具成长力品牌奖”'},
  {button:'2018-2020', title: '2020年4月', text: '薪海科技与微众银行签署战略合作协议'},
  {button:'2018-2020', title: '2019年9月', text: '“薪企链”平台及“薪账本”产品正式上线'},
  {button:'2018-2020', title: '2018年12月', text: '薪海科技（上海）有限公司成立'}
]

const Introduce = ({position, history}) => {
  
  const [currentSoft, setCurrentSoft] =useState(0);
  const softRef = useRef(null);
  const [currentHonor, setCurrentHonor] =useState(0);
  const honorRef = useRef(null);
  const [depButton, onDepButton] =useState(development_buttons[0]);
  const [maxCount, onMaxCount] =useState(5);
  const [video, onVideo] =useState(false);
  const videoRef = useRef(null);
  useEffect(()=> {
    if(position){
      let positionElement = document.getElementById(position);
      if(positionElement) { 
        positionElement.scrollIntoView(); 
      }
    }
  },[position])

  const onPlay = () => {
    if(video){
      return;
    }
    onVideo(true);
    setTimeout(() => {
      if(videoRef.current){
        videoRef.current.volume=0.3;
        videoRef.current.play();
      }
    }, 100);
  }


  const honorRender = honor_list.map((_list, i) => {
    let _rebuild_honor_list=[];
    let _item =[];
    _list.forEach((it, _i) => {
      _item.push(it)
      if(_i%2===1){
        _rebuild_honor_list.push(_item);
        _item=[];
      }
    })
    const _honor_layout = _rebuild_honor_list.map((_row, k) => {
      const _children = _row.map((_col, _k) => {
        let _item = null;
        if(_col.children){
          _item = _col.children.map((_child, _i) => {
            return <div key={_i}  className={styles["honor-image"]} 
            style={{width: '244px', height: '160px', margin: 'auto', marginBottom: _i!==_col.children.length-1 ? 24 :0 , background: `url(${_child.image}) no-repeat center center`, backgroundSize: 'cover'}}></div>
          })
        }else{
          _item = <div key={i} className={styles["honor-image"]} style={{width: '244px', height: '344px', margin: 'auto', background: `url(${_col.image}) no-repeat center center`, backgroundSize: 'cover'}}></div>
        }
        return (
          <Col key={_k} span={12} >{_item}</Col>
        )
      })
      return (
        <Row key={k} gutter={16} style={{marginBottom: k=== _rebuild_honor_list.length-1 ? 0 : 30}} >
          {_children}
        </Row>
      )
    })
    return <div key={i} >{_honor_layout}</div>
  });

  const softRender = soft_list.map((_list, i) => {
    let _rebuild_list=[];
    let _item =[];
    _list.forEach((it, _i) => {
      _item.push(it)
      if(_i%2===1){
        _rebuild_list.push(_item);
        _item=[];
      }
    })
    const _soft_layout = _rebuild_list.map((_row, k) => {
      const _children = _row.map((_col, _k) => (
        <Col key={_k} span={12} ><div key={k} className={styles["soft-image"]} style={{background: `url(${_col.image}) no-repeat center center`, backgroundSize: 'cover', margin: 'auto'}}></div></Col>
      ))
      return (
        <Row key={k} gutter={16} style={{marginBottom: k=== _rebuild_list.length-1 ? 0 : 30}} >
          {_children}
        </Row>
      )
    })
    return <div key={i} >{_soft_layout}</div>
  });
  const _development_list = development_list.filter(v=>v.button === depButton);
  return (
    <div id="company-introduce">
      <div className={styles['introduce-container']}>
        <div className={styles["introduce"]}>
          <div className={styles['introduce-info']}>

            <div className={styles['introduce-company-title']}>
              <div className={classNames(styles['company-title'], 'font-blod')}><FormattedMessage id="company.introduce.title" /></div>
              <div className={styles['company-title-bg']}></div>
            </div>

            <div className={styles['introduce-info-image']} onClick={onPlay} >
              {
                !video && <div className={styles['introduce-video-btn']} ></div>
              }
              <Player ref={videoRef} 
              poster={VideoPost} 
              >
                <BigPlayButton position="center" disabled />
                <source src={fastDfsUrl("xinhai1/M00/01/50/oYYBAGDn6-KAcmY6FxJrTB_lX-A010.mp4")}/>
              </Player> 
            </div>
            
            <div className={styles['introduce-company-text']}>
              <div style={{marginBottom: 24}}><FormattedMessage id="company.introduce.text1" /></div>
              <div>
                <span><FormattedMessage id="company.introduce.text2" /></span>
                <span className="font-blod"><FormattedMessage id="company.introduce.text3" /></span>
                <span><FormattedMessage id="company.introduce.text4" /></span>
              </div>
              <ul>
                <li><FormattedMessage id="company.introduce.text5" /></li>
                <li><FormattedMessage id="company.introduce.text6" /></li>
                <li><FormattedMessage id="company.introduce.text7" /></li>
              </ul>
              <div style={{marginTop: 24}}>
                <span><FormattedMessage id="company.introduce.text8" /></span>
                <span className="font-blod"><FormattedMessage id="company.introduce.text9" /></span>
                <span><FormattedMessage id="company.introduce.text10" /></span>
              </div>
              <div>
                <span className="font-blod"><FormattedMessage id="company.introduce.text11" /></span>
                <span><FormattedMessage id="company.introduce.text12" /></span>
              </div>
              <div><FormattedMessage id="company.introduce.text13" /></div>
            </div>
          </div>
          <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('company-honor').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
        </div>

        <div className={styles["honor"]} id="company-honor" >
          <div className={styles["honor-title"]}></div>
          <div className={styles["honor-content"]}>
            <span className="font-blod"><FormattedMessage id="company.honor.content.text1" /></span><span ><FormattedMessage id="company.honor.content.text2" /></span>
          </div>
          <div className={styles['honor-carousel']}>
            <Carousel ref={honorRef} afterChange={(current) => setCurrentHonor(current)} dots={false} >
              {honorRender}
            </Carousel>
          </div>
          {
            currentHonor!==0 ? <div className={styles['soft-left-arrow']} onClick={() => honorRef.current.slick.slickGoTo(currentHonor>0? currentHonor-1 : 0 )} ></div>
            : <div className={styles['soft-left-arrow-disbaled']}></div>
          }
          {
            currentHonor!==honor_list.length-1 ? <div className={styles['soft-right-arrow']} onClick={() => honorRef.current.slick.slickGoTo(currentHonor< honor_list.length-1 ? currentHonor+1 : honor_list.length-1)} ></div>
            : <div className={styles['soft-right-arrow-disabled']}></div>
          } 
          <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('company-soft').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
        </div>

        <div className={styles["soft"]} id="company-soft">
          <div className={styles["soft-title"]}></div>
          <div className={styles["soft-content"]}>
            <span><FormattedMessage id="company.soft.content.text1" /></span><span className="font-blod"><FormattedMessage id="company.soft.content.text2" /></span>
          </div>
          <div className={styles['soft-list-container']}>
            <Carousel ref={softRef} afterChange={(current) => setCurrentSoft(current)} dots={false}>
              {softRender}
            </Carousel>
          </div>
          {
            currentSoft!==0 ? <div className={styles['soft-left-arrow']} onClick={() => softRef.current.slick.slickGoTo(currentSoft>0? currentSoft-1 : 0 )} ></div>
            : <div className={styles['soft-left-arrow-disbaled']}></div>
          }
          {
            currentSoft!==soft_list.length-1 ? <div className={styles['soft-right-arrow']} onClick={() => softRef.current.slick.slickGoTo(currentSoft< soft_list.length-1 ? currentSoft+1 : soft_list.length-1)} ></div>
            : <div className={styles['soft-right-arrow-disbaled']}></div>
          } 
          <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('company-development').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
        </div>
      </div>
      <div className={styles["development-container"]} style={{position: 'relative'}} id="company-development">
        <div id="development-position" className={styles['position-anchor']}></div>
        <div id="development" className={styles["development"]}>
          <div className={styles["development-title"]}></div>
          <div className={styles["development-content"]}>
            <span className="font-blod"><FormattedMessage id="company.development.content.text1" /></span><span><FormattedMessage id="company.development.content.text2" /></span>
          </div>
          <div className={styles['development-button']}>
            {
              development_buttons.map((it,i)=><div key={i} actived={it===depButton?'true':'false'} onClick={()=>{onDepButton(it);if(it!== depButton){onMaxCount(5);}}}>{it}</div>)
            }
          </div>
          <div style={{padding: '0px 30px'}} >
            <ElfTimeline data={_development_list.filter(v=>v.button === depButton).filter((it, i) => maxCount === -1 || i < maxCount )} lineHeight={110} titleClassName={styles["line-text"]} contentClassName={styles["line-text"]} />
            {
              maxCount > 0 && _development_list.length > maxCount && <div className={styles['development-loadmore']} style={{marginTop: 240}} onClick={() => onMaxCount(-1)}>加载更多</div> 
            }
            {
              maxCount === -1 && <div className={styles['development-loadmore']} style={{marginTop: 170}}  onClick={() => onMaxCount(5)}>收起</div> 
            }
          </div>
        </div>
        <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('team-introduce').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
      </div>
    </div>
  );
};

export default Introduce;