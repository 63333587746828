export default {
    "home": "首页",
    "home.banner.saascloud1" : '打造全球领先的人力资源产业',
    'home.banner.saascloud2': '供应链数字科技服务云平台',
    'home.banner.switch1.text1': '欢迎入驻',
    'home.banner.switch1.text2': '薪企链(中国)用工市场',
    'home.banner.switch1.text3': '发布需求、展示信息、查找商机、在线交易',
    'home.banner.switch1.text4': '立即扫码入驻',
    'home.banner.switch2.text1': '欢迎申请',
    'home.banner.switch2.text2': '成为渠道商',
    'home.banner.switch2.text3': '全方位渠道支持，一对一合作赋能',
    'home.banner.switch2.text4': '立即扫码入驻',

    'home.product.content.text1': '多元化',
    'home.product.content.text2': '产品解决方案，助力人力资源企业',
    'home.product.content.text3': '降本增效',
    'home.product.service.part1': '薪酬融资服务',
    'home.product.service.part2': '社保垫资服务',
    'home.product.service.part3': '智能工时采集服务',
    'home.product.service.part4': '商业保险代理服务',
    'home.product.service.part5': '电子合同签约服务',
    'home.product.service.part6': '劳务费支付可视化服务',
    'home.product.service.part7': `人力资源服务项目
    "采""供"撮合服务`,
    'home.product.service.part8': '银行等资金方合作服务',
    'home.product.service.gzdz': '日结/预付工资垫资',
    'home.product.service.qyxy': '企业信用展示',
    'home.product.service.b2b': 'B2B用工撮合交易',
    'home.product.service.oes': '订单用工场景',
    
    
    'home.industry.content.text1': '多年行业领域深耕，',
    'home.industry.content.text2': '业务',
    'home.industry.content.text3': '范围',
    'home.industry.content.text4': '覆盖广',
    'home.industry.left.text1': '已加入全国',
    'home.industry.left.text2': '人力资源服务协会',
    'home.industry.left.text3': '用工企业数据',
    'home.industry.left.text4': '人资服务企业',
    'home.industry.left.text5': '协助企业发薪',
    'home.industry.right.text1': '业务覆盖',
    'home.industry.right.text2': '（省/直辖市/自治区）',
    'home.industry.right.text3': '服务',
    'home.industry.right.text4': '人力资源公司',
    'home.industry.right.text5': '服务用工企业',
    'home.industry.right.text6': '累计提供融资',
    'home.industry.unit.wan': '万+',
    'home.industry.unit.yi': '亿+',
    'home.industry.unit.plus': '+',
    'home.industry.data.note': '（数据截至2021年11月）',

    'home.news.content.text1': '聚焦',
    'home.news.content.text2': '行业热点，获取',
    'home.news.content.text3': '实时资讯',
    'home.news.bars.text1': '活动风采',
    'home.news.bars.text2': '企业资讯',
    'home.news.bars.text3': '行业动态',
    'home.news.list.loadmore': '查看更多',
    
    
    "home.contact.action1.title" : '聊天类',
    "home.contact.action1.option1" : '官网热线',
    "home.contact.action1.option1.text" : '400-850-2036',
    "home.contact.action1.option2" : '客服电话',
    "home.contact.action1.option2.text" : '137-9526-8282',
    "home.contact.action1.option3" : '客服微信',
    "home.contact.action1.option3.text" : '官方客服微信',
    "home.contact.action1.option4" : '薪企链公众号',
    "home.contact.action1.option4.text" : '薪企链微信公众号',
    "home.contact.action2.title" : '产品类',
    "home.contact.action2.option1" : '薪企链渠道商',
    "home.contact.action2.option1.text" : '小程序二维码',
    "home.contact.action2.option2" : '薪企链B2B平台',
    "home.contact.action2.option2.text" : '小程序二维码',
    "home.contact.action2.option3" : '工时本',
    "home.contact.action2.option3.text" : '小程序二维码',
}