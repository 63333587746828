import React from 'react';
import classNames from 'classnames'
import styles from './index.m.less'

const Title = ({text, style, className}) => {
  return (
    <div className={classNames(styles['title-container'], className)} style={style}>
      <div className={classNames(styles['title-text'], 'font-blod')}>{text}</div>
      <div className={styles['title-bg']}></div>
    </div>
  );
};

export default Title;