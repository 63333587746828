import React, { Component } from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'antd';
import QueueAnim from 'rc-queue-anim';
import styles from './index.m.less'
import Banner01_bg from '@/assets/img/xql-data/banner-01-background.png';
import Banner01_image from '@/assets/img/xql-data/banner-01-image.png';
import Banner01_Hexagon from '@/assets/img/xql-data/banner-01-hexagon.png';
import Banner01_Shine from '@/assets/img/xql-data/banner-01-shine.png';
import Banner01_Ornament from '@/assets/img/xql-data/banner-01-ornament.png';
import Banner02_bg from '@/assets/img/xql-data/banner-02-background.png';
import Banner02_image from '@/assets/img/xql-data/banner-02-image.png';
import Banner02_shield from '@/assets/img/xql-data/banner-02-shield.png';
import Banner02_cube from '@/assets/img/xql-data/banner-02-cube.png';
import Banner03_bg from '@/assets/img/xql-data/banner-03-background.png';
import Banner03_image from '@/assets/img/xql-data/banner-03-image.png';
import Banner03_rocket from '@/assets/img/xql-data/banner-03-rocket.png';
import Banner03_matrix from '@/assets/img/xql-data/banner-03-matrix.png';



class Banner extends Component {

  constructor(props){
    super(props);
    this.state={
      autoplay: true
    }
    this.banner = null;
  }

  render() {
    const {autoplay} = this.state;
    const itemProps ={ 
      onMouseEnter: ()=> {
        if(autoplay){
          this.banner.slick.slickPause();
        }
      },
      onMouseLeave : ()=> {
        if(autoplay){
          this.banner.slick.slickPlay();
        }
      }
    }
    return (
      <Carousel ref={ref => (this.banner = ref)}
      autoplay={autoplay} autoplaySpeed={5000} 
      dots={false} pauseOnHover={false} >
        <div>
          <div className={styles.banner} style={{backgroundColor: '#0A003F'}}>
            <div className={styles['banner-layer']} style={{background: `url(${Banner01_bg}) no-repeat center bottom`, backgroundSize: 'contain'}}>
              <div className={styles['banner-layer-content']} style={{width: '456px',marginLeft: '92px'}} {...itemProps}>
                <div className={styles['banner-layer-title']}><FormattedMessage id="xqldata.banner01.title" /></div>
                <div className={classNames(styles['banner-layer-text'])}><FormattedMessage id="xqldata.banner01.text" /></div>
              </div>
              <img style={{position: 'absolute',right: '75px',bottom: '30px'}} src={Banner01_image} alt="" srcSet="" width={395} height={408} />
              <img style={{position: 'absolute',right: '435px', top: '10px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
              <img style={{position: 'absolute',right: '400px', top: '80px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
              <img style={{position: 'absolute',right: '75px', top: '12px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
              <img style={{position: 'absolute',right: '112px', top: '75px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
              <div style={{position: 'absolute', width: '520px', top: '24px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00DBB4FF)'}}></div></div>
              <div style={{position: 'absolute', width: '380px', top: '235px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00DBB4FF)'}}></div></div>
              <div style={{position: 'absolute', width: '575px', bottom: '35px'}}><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00DBB4FF)'}}></div></div>
              <img className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '195px', top: '-50px'}} src={Banner01_Hexagon} alt="" srcSet="" width={150} height={144} />
              <img className={styles['banner-fade-animation']} style={{position: 'absolute',right: '159px', bottom: '110px'}} src={Banner01_Shine} alt="" srcSet="" width={226} height={199} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.banner} style={{backgroundColor: '#000000'}}>
            <div className={styles['banner-layer']} style={{background: `url(${Banner02_bg}) no-repeat center bottom`, backgroundSize: 'contain'}}>
              <div className={styles['banner-layer-content']} style={{width: '480px',marginLeft: '144px'}} {...itemProps}>
                <div className={styles['banner-layer-title']}><FormattedMessage id="xqldata.banner02.title" /></div>
                <div className={classNames(styles['banner-layer-text'])}><FormattedMessage id="xqldata.banner02.text" /></div>
              </div>
              <img style={{position: 'absolute',right: '1px',bottom: '-10px'}} src={Banner02_image} alt="" srcSet="" width={520} height={410} />
              <img className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '170px', top: '-68px'}} src={Banner02_shield} alt="" srcSet="" width={176} height={200} />
              <div style={{position: 'absolute', width: '620px', top: '24px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00DBB4FF)'}}></div></div>
              <div style={{position: 'absolute', width: '470px', top: '235px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00DBB4FF)'}}></div></div>
              <div style={{position: 'absolute', width: '750px', bottom: '35px'}}><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00DBB4FF)'}}></div></div>
              
              <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '465px', top: '-5px'}}>
                <img style={{position: 'relative', zIndex: '10', opacity: '0.4', filter: 'opacity(50%)'}} src={Banner02_cube} alt="" srcSet="" width={20} height={25} />
              </div>
              <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '490px', top: '130px'}}>
                <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'sepia(10%) opacity(80%)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
              </div>
              <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '385px', top: '35px'}}>
                <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'brightness(60%)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
              </div>
              <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '321px', top: '150px'}}>
                <img style={{position: 'relative', zIndex: '10', filter: 'blur(0.4px)'}} src={Banner02_cube} alt="" srcSet="" width={40} height={50} />
                <div style={{position: 'absolute', width: '40px', height: '180px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF7FF)', top: '35px', opacity: '0.4'}}></div>
              </div>

              <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '145px', top: '25px'}}>
                <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'brightness(90%)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
              </div>
              <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '30px', top: '50px'}}>
                <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'blur(0.6px)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
              </div>
              <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '40px', top: '185px'}}>
                <img style={{position: 'relative', zIndex: '10', filter: 'blur(0.6px)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                <div style={{position: 'absolute', width: '25px', height: '160px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF7FF)', top: '20px', opacity: '0.4'}}></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.banner} style={{backgroundColor: '#000000'}}>
            <div className={styles['banner-layer']} style={{background: `url(${Banner03_bg}) no-repeat center bottom`, backgroundSize: 'contain'}}>
              <div className={styles['banner-layer-content']} style={{width: '504px',marginLeft: '135px'}} {...itemProps}>
                <div className={styles['banner-layer-title']}><FormattedMessage id="xqldata.banner03.title" /></div>
                <div className={classNames(styles['banner-layer-text'])}><FormattedMessage id="xqldata.banner03.text" /></div>
              </div>
              <img style={{position: 'absolute',right: '0px',bottom: '-38px'}} src={Banner03_image} alt="" srcSet="" width={490} height={444} />
              <div style={{position: 'absolute', width: '665px', top: '66px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00BAFFFF)'}}></div></div>
              <div style={{position: 'absolute', width: '545px', top: '236px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00BAFFFF)'}}></div></div>
              <div style={{position: 'absolute', width: '292px', bottom: '36px'}}><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to right, #FFFFFF00, #00BAFFFF)'}}></div></div>
              
              <img style={{position: 'absolute',right: '192px', top: '-50px'}} src={Banner03_rocket} alt="" srcSet="" width={112} height={196} />
              <img className={styles['banner-fade-animation']} style={{position: 'absolute', zIndex: '10', right: '212px', bottom: '115px'}} src={Banner03_matrix} alt="" srcSet="" width={73} height={56} />

              <QueueAnim animConfig={[{ opacity:[1, 0] }]} delay={100} interval={500}>
                <div key="line-left-01" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '330px', bottom: '0px'}} ></div>
                <div key="line-middle" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '245px', bottom: '0px'}} ></div>
                <div key="line-right-01" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '90px', bottom: '0px'}} ></div>
                <div key="line-right-02" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '120px', bottom: '0px'}} ></div>
                <div key="line-left-02" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '400px', bottom: '0px'}} ></div>
                <div key="line-left-03" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '360px', bottom: '0px'}} ></div>
                <div key="line-right-03" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '150px', bottom: '0px'}} ></div>
              </QueueAnim>

              <QueueAnim animConfig={[{ opacity:[1, 0] }]} delay={100} interval={800}>
                <div key="dot-01" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '5px', height: '5px',right: '240px', bottom: '100px'}} ></div>
                <div key="dot-02" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '8px', height: '8px',right: '260px', bottom: '100px'}} ></div>
                <div key="dot-03" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '6px', height: '6px',right: '250px', bottom: '100px'}} ></div>
                <div key="dot-04" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '10px', height: '10px',right: '225px', bottom: '100px'}} ></div>
                <div key="dot-05" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '8px', height: '8px',right: '250px', bottom: '100px'}} ></div>
                <div key="dot-06" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '12px', height: '12px',right: '230px', bottom: '100px'}} ></div>
                <div key="dot-07" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '9px', height: '9px',right: '240px', bottom: '100px'}} ></div>
              </QueueAnim>
            </div>
          </div>
        </div>
      </Carousel>
    );
  }
}

export default Banner;