import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.m.less'

const Card = ({dataSource=[], renderCover, renderItem, onClick, loadMore}) => {
  const renderList = dataSource.map((item, i) => <div key={i} className={styles['list-item-border']} onClick={() => onClick(item)}>
    <div className={styles['list-item']}>
      {
        renderCover && <div className={styles['item-cover']}>
          {renderCover(item)}
          <div className={styles.triangle}></div>
          <div className={styles.dot}></div>
        </div>
      }
      <div className={styles['item-content']}>{renderItem(item)}</div>
    </div>
  </div>)
  return (
    <div className={styles.list}>
      {renderList}
      {loadMore}
    </div>
  );
};

Card.propTypes = {
  dataSource: PropTypes.array.isRequired,
  renderCover: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  loadMore: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Card;