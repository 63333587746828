import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import ImageIntroduce from '@/assets/img/product/htqy/image-introduce.png';
import ImageBusiness from '@/assets/img/product/dz/image-introduce.png';
import Title from './TitleMobile'

const Service_htqy = () => {
  return (
    <div className={styles['rz-container']}>
      <div className={styles['rz-service-title']} ><FormattedMessage id="home.product.service.part5" /></div>

      <div className={styles['rz-card']} ><img alt="" src={ImageIntroduce} width="100%" /></div>
      <Title style={{marginTop : 20}} text={<FormattedMessage id="product.rz.introduce.title" />}/>
      <div style={{marginTop : 10}} className={styles['rz-text']} ><FormattedMessage id="product.htqy.introduce.text1" /></div>
      <Title style={{marginTop : 20, marginBottom: 10}} text={<FormattedMessage id="product.rz.characteristic.title" />} />
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.htqy.characteristic.text1" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.htqy.characteristic.text2" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.htqy.characteristic.text3" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.htqy.characteristic.text4" /></span>
      </div>

      <div style={{marginTop : 20}} className={styles['rz-card']} ><img alt="" src={ImageBusiness} width="100%" /></div>
      <Title style={{marginTop : 20}}  text={<FormattedMessage id="product.business-object" />}/>
      <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.htqy.business-object.text" /></div>
      <Title text={<FormattedMessage id="product.business-scenario" />} />
      <div className={styles['rz-text']} style={{marginTop: '10px', marginBottom: '20px'}}>
        <div><FormattedMessage id="product.htqy.business-scenario.text1" /></div>
        <div><FormattedMessage id="product.htqy.business-scenario.text2" /></div>
        <div><FormattedMessage id="product.htqy.business-scenario.text3" /></div>
      </div>
      <Title text={<FormattedMessage id="product.business-service" />}  />
      <div className={classNames(styles['rz-text'])} style={{marginTop: '10px'}}><FormattedMessage id="product.htqy.business-service.text" /></div>

      <div style={{lineHeight: '33px', marginTop : 20}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.rz.case.title1" /></div>
      <div style={{lineHeight: '33px'}} className={styles['rz-case-text']}><FormattedMessage id="product.htqy.case.text" /></div>

      {/* <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageIntroduce} />} coverStyle={{left: '20px'}} bodyClassName={styles['rz-card-lg']}>
        <Meta >
          
        </Meta>
      </ElfCard>
      <ElfCard offset={{right: '345px'}} bodyOffset={{right: '300px'}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={ImageBusiness} />} coverStyle={{right: '0px'}} style={{marginTop: 30}} 
      >
        <Meta style={{paddingRight: '0px'}}>
          
        </Meta>
      </ElfCard>
      
      <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageCase} />} coverStyle={{left: '32px', width: '600px'}} bodyClassName={styles['rz-card-lg']} style={{marginTop: 30}} >
        <Meta style={{paddingRight: '20px', paddingLeft: '25px'}}>
          
        </Meta>
      </ElfCard> */}
    </div>
  );
};

export default Service_htqy;