import React, { Component } from 'react';
import classNames from 'classnames'
import {connect} from 'dva'
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import  Qs from 'qs';


@connect(({recruitment, loading }) => {
  return ({
    sites: recruitment.sites,
    jobs: recruitment.jobs,
    list: recruitment.list,
    loading: loading.effects['recruitment/list'] || false,
  })
}) 
class index extends Component {

  constructor(props){
    super(props);
    this.state={
      siteActived: '',
      siteExpand: false,
      jobActived: '',
      jobExpand: false,
    }
  }


  componentDidMount(){
    this.getParams();
  }


  getParams = () => {
    const {dispatch} =  this.props;
    dispatch({
      type: 'recruitment/getParams',
      payload: {},
    });
  }

  onChangeTab = (type, activeKey) => {
    const {dispatch} =  this.props;
    const { siteActived, jobActived} = this.state;
    if(type === '02'){
      this.setState({
        siteActived: activeKey
      }, () =>{
        dispatch({
          type: 'recruitment/list',
          payload: {
            "site": activeKey,
            "post": jobActived,
          },
        });
      })
    }else if(type === '01'){
      this.setState({
        jobActived: activeKey
      }, () =>{
        dispatch({
          type: 'recruitment/list',
          payload: {
            "site":siteActived,
            "post":activeKey,
          },
        });
      })
    }
    
  }

  isOnExpand = (items=[]) => {
    let _word = 0;
    items.forEach(it => {
      _word = _word + it.fieldName.length
    })
    const width = _word * 16 + (items.length=== 0 ? 0 : ((items.length -1) * 24))
    return width > 1000
  }
  

  render() {
    const {siteActived, siteExpand, jobActived, jobExpand} = this.state;
    const {sites, jobs, list, loading, history} = this.props;

    const isSiteExpand = this.isOnExpand(sites);
    const isJobExpand = this.isOnExpand(jobs);

    const columns = [
      {
        title: <FormattedMessage id="recruit.column.position.name" />,
        dataIndex: 'jobName',
        align: 'center',
        width: '30%',
      },
      {
        title: <FormattedMessage id="recruit.column.position.type" />,
        dataIndex: 'jobCategory',
        align: 'center',
        width: '20%',
        render: val => val || '--'
      },
      {
        title: <FormattedMessage id="recruit.column.position.site" />,
        dataIndex: 'recruitSite',
        align: 'center',
        width: '40%',
        render: val => val || '--'
      },
      {
        title: <FormattedMessage id="recruit.column.position.info" />,
        align: 'center',
        width: '10%',
        render: (text, record) => <div className={styles['row-info']} onClick={() => history.push({pathname: '/company/recruitinfo', search: Qs.stringify({id: record.id}), })}><FormattedMessage id="recruit.option.view" /></div>
      }
    ];

    return (
      <div className={styles.recruit}>
        <div className={styles.options}>
          <div className={styles["options-item"]}>
            <div className={styles["option-label"]}><FormattedMessage id="recruit.site" /></div>
            <div className={styles["option-tabs"]}>
              <div className={classNames(styles['option'], styles['option-shrink'])}>
                <div actived={siteActived === '' ? 'true' : "false"} onClick={() => this.onChangeTab("02", '')}><FormattedMessage id="recruit.option.all" /></div>
                {
                  sites.map((it, i) => <div key={i} actived={siteActived === it.fieldName ? 'true' : "false"} onClick={() => this.onChangeTab("02", it.fieldName)}>{it.fieldName}</div>)
                }
                {
                  isSiteExpand && !siteExpand && <div className={styles["option-expand"]} style={{cursor: 'pointer'}} onClick={() => this.setState({siteExpand : true})}><FormattedMessage id="recruit.expand" /></div>
                }
              </div>
            </div>
            

          </div>
          <div className={styles["options-item"]}>
            <div className={styles["option-label"]}><FormattedMessage id="recruit.position" /></div>
            <div className={classNames(styles["option-tabs"])}>
              <div className={classNames(styles['option'], !jobExpand ? styles['option-shrink'] : {})}>
                <div actived={jobActived === '' ? 'true' : "false"} onClick={() => this.onChangeTab("01", '')}><FormattedMessage id="recruit.option.all" /></div>
                {
                  jobs.map((it, i) => <div key={i} actived={jobActived === it.fieldName ? 'true' : "false"} onClick={() => this.onChangeTab("01", it.fieldName)}>{it.fieldName}</div>)
                }
              </div>
            </div>
            {
              isJobExpand && !jobExpand && <div className={styles["option-expand"]} style={{cursor: 'pointer'}} onClick={() => this.setState({jobExpand : true})}><FormattedMessage id="recruit.expand" /></div>
            }
          </div>
        </div>
        <div className={styles.content}>
          <Table rowKey="id" columns={columns} dataSource={list} className={styles.table}
          rowClassName={styles['table-row']} loading={loading}
          pagination={false} scroll={list.length > 8 ? { y: 480 } : {}} />
        </div>
      </div>
    );
  }
}

export default index;