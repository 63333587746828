import React, {useEffect} from 'react';
import classNames from 'classnames'
import {connect} from 'dva'
import Media from 'react-media';
import { FormattedMessage } from 'react-intl';
import {ElfList} from 'elf-ui';
import styles from './index.m.less'
import ServiceIcon1 from '@/assets/img/product/icon_01.png'
import ServiceIcon2 from '@/assets/img/product/icon_02.png'
import ServiceIcon3 from '@/assets/img/product/icon_03.png'
import ServiceIcon4 from '@/assets/img/product/icon_04.png'
import ServiceIcon5 from '@/assets/img/product/icon_05.png'
import ServiceIcon6 from '@/assets/img/product/icon_06.png'
import ServiceIcon7 from '@/assets/img/product/icon_07.png'
import ServiceIcon8 from '@/assets/img/product/icon_08.png'
import ProductRouter from './product-router'
import ProductRouterMobile from './product-router-mobile'

const {Grid} = ElfList

const products = [
  { path: 'gzdz', icon: ServiceIcon1, text: <FormattedMessage id="home.product.service.gzdz" /> },
  { path: 'zngs', icon: ServiceIcon2, text: <FormattedMessage id="home.product.service.part3" /> },
  { path: 'qyxy', icon: ServiceIcon3, text: <FormattedMessage id="home.product.service.qyxy" /> },
  { path: 'bxdl', icon: ServiceIcon4, text: <FormattedMessage id="home.product.service.part4" /> },
  { path: 'htqy', icon: ServiceIcon5, text: <FormattedMessage id="home.product.service.part5" /> },
  { path: 'lwzf', icon: ServiceIcon6, text: <FormattedMessage id="home.product.service.part6" /> },
  { path: 'b2b', icon: ServiceIcon7, text: <FormattedMessage id="home.product.service.b2b" /> },
  { path: 'oes', icon: ServiceIcon8, text: <FormattedMessage id="home.product.service.oes" /> },
]

const Index = ({history}) => {
  const {location: {pathname, state}} = history
  const seleceKey = pathname.split('/').filter(r => r)[1];
  const imageProps = {
    width: 60,
    height: 60
  }
  const position=state ? state.position :undefined;
  const timeStamp = state ? state.timeStamp :undefined;
  const renderProducts = products.map((item, i) => {
    return <div key={i} className={styles["menu-item"]} actived={seleceKey === item.path ? 'true' : 'false'} onClick={() => history.push({pathname: `/product.htm/${item.path}`, state:{ position: 'menu-position', timeStamp: new Date().getTime() }})}>
        <img src={item.icon} alt="" srcSet="" {...imageProps} />
        <div className={styles["menu-item-text"]} style={item.style}>{item.text}</div>
    </div>
  })

  useEffect(()=> {
    if(position){
      let positionElement = document.getElementById(position);
      if(positionElement) { 
        positionElement.scrollIntoView(); 
      }
    }
  },[position, pathname, timeStamp])

  return (
    <div>
      <div className={styles.banner}>
        <div className={styles['banner-layer']}>
          <div className={styles['banner-layer-image']}></div>
          <div className={classNames(styles['banner-layer-text'])}>
            <span className='font-blod'><FormattedMessage id="product.banner.text1" /></span>
            <span><FormattedMessage id="product.banner.text2" /></span>
            <span className='font-blod'><FormattedMessage id="product.banner.text3" /></span>
          </div>
          
        </div>
      </div>
      <div className={styles.content} style={{position: 'relative'}}>
        <div id="menu-position" className={styles['position-menu']}></div>
        <div className={styles.menu}>
          <Grid columns={4} gutter={30}>
            {renderProducts}
          </Grid>
        </div>
        <div>
          <ProductRouter />
        </div>
      </div>
      
    </div>
  );
};

const Mobile = () => {
  return (
    <ProductRouterMobile />
  );
};

export default connect()(props => (
  <Media query="(max-width: 999px)">
    {isMobile => isMobile ? <Mobile {...props} /> : <Index {...props} />}
  </Media>
));