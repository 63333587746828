import enUS from "../locale/en-US";
import zhCN from "../locale/zh-CN";

// 获取语言
export function getLanguage() {
    const lang = navigator.language || navigator.userLanguage; // 常规浏览器语言和IE浏览器
    const localStorageLang = localStorage.getItem("lang");
    const defaultLang = localStorageLang || lang;
    return defaultLang;
}
  
  // 修改html.lang显示
export function changeHtmlLang(lang) {
    return document.getElementById("lang").lang = lang;
}
  
  // 设置语言
export function setLanguage(lang) {
    const defaultLang = localStorage.setItem("lang", lang);
    return defaultLang;
}
  
  // 匹配语言
export function chooseLocale() {
    switch (getLanguage()) {
    case "en":
    //   changeHtmlLang(getLanguage());
      return enUS;
    case "zh":
    //   changeHtmlLang(getLanguage());
      return zhCN;
    default:
    //   changeHtmlLang(getLanguage());
      return zhCN;
    }
}