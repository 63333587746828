export default {
    "product.banner.text1" : '多元化',
    'product.banner.text2': '产品解决方案助力人力资源企业',
    'product.banner.text3': '降本增效',

    'product.business-object': '业务对象',
    'product.business-scenario': '业务场景',
    'product.business-service': '服务详情',
    //薪酬融资
    'product.rz.introduce.title': '产品介绍',
    'product.rz.introduce.text1': '为人力资源服务公司提供灵活、方便、及时、安全的人力费用应收账款融资解决方案，满足灵活用工垫资发薪的融资需要。',
    'product.rz.characteristic.title': '产品特点',
    'product.rz.characteristic.text1': '1.获得融资主要依据用工企业',
    'product.rz.characteristic.text2': '应付账款质量',
    'product.rz.characteristic.text3': '2.无需房地产抵押，只需',
    'product.rz.characteristic.text4': '提供项目应收账款的账期和金额',
    'product.rz.characteristic.text5': '3.线上提报资料、线上审批，',
    'product.rz.characteristic.text6': '当天申请，当天放款',
    'product.rz.characteristic.text7': '4.满足日结发薪需要，针对日薪产品做到周放款，日发薪，资金成本是',
    'product.rz.characteristic.text8': '常规价格的60%',
    'product.rz.characteristic.text9': '5.按日计息，用几天计算几天利息，',
    'product.rz.characteristic.text10': '资金成本低',
    'product.rz.day.title': '日薪垫',
    'product.rz.day.text1': '场景应用',
    'product.rz.day.text2': '人力资源公司派遣/外包员工存在工资日结或日预支的业务场景。',
    'product.rz.day.text3': '需求群体',
    'product.rz.day.text4': '人力资源公司（劳务派遣、人力外包）。',
    'product.rz.day.text5': '产品特点',
    'product.rz.day.text6': '额度高、审批快、按天计算、用几天算几天，资金成本低。',
    'product.rz.week.title': '周薪垫',
    'product.rz.week.text1': '人力资源公司（劳务派遣、人力外包、人事代理）/外包员工存在工资日结或日预支的业务场景。',
    'product.rz.week.text2': '人力资源公司（劳务派遣、人力外包、人事代理）',
    'product.rz.month.title': '月薪垫',
    'product.rz.month.text1': '人力资源公司派遣/外包员工存在工资月结等工资发放需求',
    'product.rz.month.text2': '人力资源公司、用工单位、平台、灵活用工企业',
    'product.rz.month.text3': '线上提报、线上审核，操作简单、随借随还、下款快，无需抵押',
    'product.rz.cycle.title': '循环垫',
    'product.rz.cycle.text1': '长期的人力输出合作，存在相对固定的应收账款长期项目',
    'product.rz.cycle.text2': '人力资源公司、用工单位、平台、灵活用工企业',
    'product.rz.cycle.text3': '总额度循环垫资、一次签约、循环操作',
    'product.rz.case.title1': '经典案例',
    'product.rz.case.text1': '福建某人力资源外包公司通过薪企链实现劳务费应收账款融资，自2019年10月合作开始，累计融资1.2亿元',
    'product.rz.case.title2': '全程线上化',
    'product.rz.case.text2': '线上开户——平台创建发放单——发起融资申请——资金方审核放款——回款清分',

    //社保垫资
    'product.dz.introduce.text1': '为社保代理服务企业提供灵活、方便、及时、安全的社保费用应收账款融资解决方案。',
    'product.dz.characteristic.text1': '1.',
    'product.dz.characteristic.text2': '操作简单',
    'product.dz.characteristic.text3': '、方便快捷，一次签约、循环操作；',
    'product.dz.characteristic.text4': '2.',
    'product.dz.characteristic.text5': '无需抵押',
    'product.dz.characteristic.text6': '，只需项目存在应收账款即可申请；',
    'product.dz.characteristic.text7': '3.线上提报资料、线上初审，专款专用；',
    'product.dz.characteristic.text14': '4.按日计息，用几天算几天，',
    'product.dz.characteristic.text15': '资金成本低。',
    'product.dz.business-object.text': '社保服务代理企业企业、员工',
    'product.dz.business-scenario.text': '应用于社保代理服务企业为企业垫付社保的需求。',

    //智能工时
    'product.zngs.introduce.text1': '薪企链打造的一款应用于员工现场考勤管理的小程序版工时信息采集系统，为用工企业和人力资源服务企业提供智能排班、工时采集、考勤管理等服务，为人力资源服务企业发日薪提供数据依据，为资金方垫资提供真实性的交易数据。',
    'product.zngs.characteristic.text1': '1.',
    'product.zngs.characteristic.text2': '只需一个软件',
    'product.zngs.characteristic.text3': '：考勤、打卡、发薪，全部在“工时本”小程序中完成，一体化处理。',
    'product.zngs.characteristic.text4': '2.通过',
    'product.zngs.characteristic.text5': '二维码进行信息采集及录入',
    'product.zngs.characteristic.text6': '：解决录入/管理/核对繁琐性，减少工作量，便于统一管理，保障发薪数据的准确性。',
    'product.zngs.characteristic.text7': '3.',
    'product.zngs.characteristic.text8': '考勤地点精准定位',
    'product.zngs.characteristic.text9': '，工时数据实时更新。',
    'product.zngs.characteristic.text10': '4.',
    'product.zngs.characteristic.text11': '智能排班',
    'product.zngs.characteristic.text12': '：设置任意排班信息，排班数据实时查看。',
    'product.zngs.characteristic.text13': '5.',
    'product.zngs.characteristic.text14': '本地化部署',
    'product.zngs.characteristic.text15': '：绑定企业微信公众号，树立品牌形象，提高员工粘性。',
    'product.zngs.business-object.text': '人力资源服务企业、用工单位、员工',
    'product.zngs.business-scenario.text': '适用于用工企业、劳务派遣、外包公司的员工考勤管理、智能排班、工时采集业务场景。',
    'product.zngs.case.text': '河南某人力集团公司使用“智能工时采集系统”，薪企链为其发日结垫资，业务量快速增长。小程序绑定微信服务号（企业招聘号）实现企业私域流量沉淀2000人/月。',

    //保险代理
    'product.bxdl.introduce.text1': '用工企业、人力公司通过薪企链平台可以办理中国人民保险公司为雇主和员工投保“雇主责任险”、“员工意外伤害险”等保险类产品。',
    'product.bxdl.characteristic.text1': '1.线上操作，无需面签，',
    'product.bxdl.characteristic.text2': '拍照投保',
    'product.bxdl.characteristic.text3': '，方便快捷；',
    'product.bxdl.characteristic.text4': '2.',
    'product.bxdl.characteristic.text5': '产品多样',
    'product.bxdl.characteristic.text6': '，企业可根据自身需求自主选择组合产品；',
    'product.bxdl.characteristic.text7': '3.按天投保、按天承保，人数灵活、随时增减人数，',
    'product.bxdl.characteristic.text8': '降低保费支出',
    'product.bxdl.characteristic.text9': '；',
    'product.bxdl.characteristic.text10': '4.7X24小时响应，当日投保、次日即可生效；',
    'product.bxdl.characteristic.text11': '5.',
    'product.bxdl.characteristic.text12': '理赔速度快',
    'product.bxdl.characteristic.text13': '，',
    'product.bxdl.characteristic.text14': '保额赔付高',
    'product.bxdl.characteristic.text15': '，医疗费用0免赔，误工费最高可赔180天；',
    'product.bxdl.characteristic.text16': '6.',
    'product.bxdl.characteristic.text17': '专属定制',
    'product.bxdl.characteristic.text18': '，',
    'product.bxdl.characteristic.text19': '一对一服务',
    'product.bxdl.characteristic.text20': '，根据实时情况及需求调整方案；',
    'product.bxdl.characteristic.text21': '7.综合费用成本低，安全可靠值得信赖。',
    'product.bxdl.characteristic.text22': ' ',
    'product.bxdl.characteristic.text23': ' ',
    'product.bxdl.business-object.text': '人力资源服务企业、用工单位、员工',
    'product.bxdl.business-scenario.text': '用工企业、人力公司需为员工购买商业类保险，或者员工自主购买商业类保险。',
    'product.bxdl.insurance.title1': '场景应用：',
    'product.bxdl.insurance.title2': '保险期限：',
    'product.bxdl.insurance.title3': '保险标的：',
    'product.bxdl.insurance.title4': '赔付范围：',
    'product.bxdl.insurance.title5': '产品特点：',
    'product.bxdl.liability.title': '雇主责任险',
    'product.bxdl.liability.text1': '企业与员工已签订“书面”雇用合同，雇佣关系确立',
    'product.bxdl.liability.text2': '一年',
    'product.bxdl.liability.text3': '企业应承担的医疗费及经济赔偿责任',
    'product.bxdl.liability.text4': '死亡赔偿金、伤残赔偿金、误工费用、医疗费用等',
    'product.bxdl.liability.text5': '保费税前可扣除，与其他商业保险不冲突，不存在赔付顺序的问题',
    'product.bxdl.casualty.title': '团体意外伤害险',
    'product.bxdl.casualty.text1': '企业自愿为员工购买，无需签订劳动合同。',
    'product.bxdl.casualty.text2': '一年',
    'product.bxdl.casualty.text3': '员工的身体，即员工因意外而导致身体受到伤害。',
    'product.bxdl.casualty.text4': '死亡给付、残废给付、医疗给付、住院津贴等。',
    'product.bxdl.casualty.text5': '保费低，额度高、投保周期短，适用场景广泛。',

    //合同签约
    'product.htqy.introduce.text1': '各客户可在平台使用电子合同进行签约。如用工企业与人力资源企业之间的招投标交易服务，或者人力资源企业与外派员工之间的劳动、劳务合同等。帮助企业节省时间，降低采购成本。',
    'product.htqy.characteristic.text1': '1.在线签约，解决异地签约难题，有效避免漏签、错签等情况；',
    'product.htqy.characteristic.text2': '2.实名认证，合法合规，规避签约不合规风险，降低合同纠纷；',
    'product.htqy.characteristic.text3': '3.批量签约，无需面签，节约成本，提升效率；',
    'product.htqy.characteristic.text4': '4.签约数据云储存，不丢失、不篡改，安全有效；',
    'product.htqy.business-object.text': '人力资源服务企业、用工单位、员工',
    'product.htqy.business-scenario.text1': '用工单位和人力资源企业在招投标撮合交易环节进行电子合同签约；',
    'product.htqy.business-scenario.text2': '人力资源企业与员工签署劳动合同；',
    'product.htqy.business-scenario.text3': '薪企链与平台客户签署合作协议。',
    'product.htqy.business-service.text': '招投标合同、劳动合同、融资服务合同、其他业务场景下的电子合同',
    'product.htqy.case.text': '上海某人力资源公司，用薪企链平台e签宝的电子合同签约，全国业务签约全部线上化。',

    //劳务费支付可视化服务
    'product.lwzf.introduce.text1': '劳务费支付可视化服务为用工企业和人力资源服务一级供应商、二级供应商和多级供应商、员工薪酬支付监督服务。平台提供智能发薪服务。产品通过平台与合作银行的账户体系，保证用工企业支付的劳务费用资金不被挪用，及时发至员工手中。',
    'product.lwzf.characteristic.text1': '1.员工信息实名认证，无法篡改；',
    'product.lwzf.characteristic.text2': '2.企业账户，银行开立，线上提交开户资料，无需面签，开户方便快捷；',
    'product.lwzf.characteristic.text3': '3.根据需求设定账户甲乙方共管，平台触碰不到资金，资金安全有保证；',
    'product.lwzf.characteristic.text4': '4.发薪流程可定制，层级审批，有效把控资金挪用风险；',
    'product.lwzf.characteristic.text5': '5.不限银行、不限地域，员工发薪批量操作，提高发薪效率，降低人力成本；',
    'product.lwzf.characteristic.text6': '6.7✖24小时实时发放，资金秒到；',
    'product.lwzf.characteristic.text7': '7.发薪记录随时可查，一键批量导出，简单易操作。',
    'product.lwzf.business-object.text': '人力资源服务企业、用工单位、员工',
    'product.lwzf.business-scenario.text': '应用于用工企业、人力公司需对整个发薪流程进行监管或实时查看。',
    'product.lwzf.business-service.text1': '为企业提供资金支付监管、智能员工发薪服务；',
    'product.lwzf.business-service.text2': '平台与合作银行的账户体系，保证用工企业支付的劳务费用资金不被挪用，及时发至员工手中。',
    'product.lwzf.case.text': '北京某公司，委托薪企链平台薪资支付监管。将款项支付给劳务派遣公司在平台开立的监管账户内，发薪给员工，每月支付金额2000万发薪给员工，安全高效，避免乙方挪用风险。',

    //B2B用工撮合交易
    'product.rzch.introduce.text1': '薪企链，B2B用工交易服务平台。与商务部研究院合作，为甲方用工企业、乙方人资企业、学校、产业园，主要提供九项服务：',
    'product.rzch.introduce.text2': '①用工企业、（一手单）人资企业、（二手单）人资企业需求、供应之间的撮合交易服务；',
    'product.rzch.introduce.text3': '②招投标垫资融资服务；',
    'product.rzch.introduce.text4': '③履约保证金垫资融资服务；',
    'product.rzch.introduce.text5': '④成交项目预付款垫资融资服务；',
    'product.rzch.introduce.text6': '⑤供应链上下游企业、员工，资金结算可视化监管；',
    'product.rzch.introduce.text7': '⑥用工企业需求信息发布；',
    'product.rzch.introduce.text8': '⑦人资企业供应信息发布；',
    'product.rzch.introduce.text9': '⑧企业展示/诚信展示（360维度标签动态画像）；',
    'product.rzch.introduce.text10': '⑨用工岗位/生活环境展示（工作岗位、生活场所可视化）。',

    'product.rzch.characteristic.text': '平台运用与中国商务部贸易经济合作研究院联合创建巜企业数字征信系统》，为企业动态画像，并受委对外展示，提高企业招投标的成功率，和对外融资能力。平台为企业开设名片展示窗口，提高企业知名度和品牌影响力，助力招聘。',
    'product.rzch.business-object.text': '人力资源产业上、下游企业。',
    'product.rzch.business-scenario.text': '应用于人力资源产业全业务场景。',
    'product.rzch.business-service.text1': '用工企业信用画像展示；',
    'product.rzch.business-service.text2': '人力外包派遣项目招投标服务；',
    'product.rzch.business-service.text3': '员工就业单位场景可视化；',
    'product.rzch.business-service.text4': '其他人力资源产业链数字服务。',
    'product.rzch.case.text1': '招标：',
    'product.rzch.case.text2': '浙江某物流公司：通过薪海云平台发布劳务项目外包招标需求，多家企业勇跃参加投标，比过往可选择企业增加2倍，降本增效。',
    'product.rzch.case.text3': '投标：',
    'product.rzch.case.text4': '浙江某外包公司：浙江某外包公司通过薪海云获悉劳务招标项目信息，积极参加投标，战胜其他投标人，顺利拿到项目。并通过平台找到二手单供应商，保证业务落实。',

    //银行等资金方合作服务
    'product.yhzj.introduce.text': '薪企链平台串联产业链上的各方，实现信息流、资金流、业务流、商流“四流合一”的闭环业务生态，运用智能动态风控、大数据画像等技术，以及对应收账款确权、协助资金监管等工作。目前建设银行、九江银行等多家银行已与薪企链合作。',


    //日结/预付工资垫资
    'product.gzdz.introduce.text': '薪企链针对灵活用工市场出现的发日薪或者提前申请工资预支的场景，进一步为企业解决员工留存难而推出了“日结垫资””预付工资垫资“两大创新产品，放款金额依据项目应收账款，不限金额，不限账期，不限地域，期限灵活、按天计息、用几天算几天，无需抵押，资金成本低，7天垫一次，一个月垫4次，有效满足人力资源服务公司发日薪的需求。',
    'product.gzdz.characteristic.text': '额度高、审批快、按天计算、用几天算几天，资金成本低',
    'product.gzdz.business-object.text': '人力资源公司（劳务派遣、人力外包）',
    'product.gzdz.business-scenario.text': '人力资源公司派遣/外包员工存在工资日结或预付工资的业务场景。',
    'product.gzdz.case.title1': '经典案例',
    'product.gzdz.case.text1': '福建某人力资源外包公司通过薪企链实现劳务费应收账款融资，自2019年10月合作开始，累计融资1.2亿元',
    'product.gzdz.case.title2': '全程线上化',
    'product.gzdz.case.text2': '线上开户——平台创建发放单——发起融资申请——资金方审核放款——回款清分',

    //企业信用展示
    'product.qyxy.introduce.text': '薪企链能够为人资企业、用工单位、学校、产业园，提供官网、产品、企业信息、办公场景、广告等内容展示及推广宣传，进一步帮助人资企业、用工单位、学校、产业园提高品牌知名度。',

    //订单用工场景
    'product.oes.introduce.text': '薪企链平台内，供需双方在线接单、发单及交易，应聘员工及人资公司在线可以看到用工单位的办公场景、企业介绍等内容，便于应聘员工及人资公司及时了解用工单位相关信息并进行决策。',
}