import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {ElfList} from 'elf-ui';
import styles from './index.m.less'
import { Spin, Icon } from 'antd';
import {fastDfsUrl} from 'utils/String'

const ListCover = ({dataSource=[], total=0, loading=false, onLoad}) => {

  const loadMore = onLoad && total > dataSource.length ? <div style={{marginTop: 20}} className={styles['load-more']} onClick={() => loading ? {} : onLoad()}>
    <Spin spinning={loading} indicator={<Icon type="loading" style={{ fontSize: 18, color: '#333333' }}  spin />}>
      <span>加载更多</span>
    </Spin>
  </div> : null;

  return (
    <div className={styles['list-cover-container']}>
      <ElfList.Card dataSource={dataSource} 
        renderCover={(item) => <img src={fastDfsUrl(item.cover)} alt="" srcSet=""/>}
        renderItem={ item => <div className={styles['list-cover-item']}>
            <div className={classNames(styles.title, 'font-blod')}>{item.articleTitle}</div>
            <div className={styles.text}>{item.articleIntroduce}</div>
            <div className={styles.date}>{item.publicDate}</div>
        </div>} 
        onClick={(item) => window.open(item.articleUrl)} 
        loadMore={loadMore}
      />
    </div>
  );
};

ListCover.propTypes = {
  dataSource: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onLoad: PropTypes.func
};

export default ListCover;