import React from 'react';
// import { Modal} from 'antd'
import styles from './index.m.less';
import { FormattedMessage } from 'react-intl';
// import {fastDfsUrl} from 'utils/String'
// import { Player, BigPlayButton } from 'video-react';
import WxB2B from '@/assets/img/home/banner/wx-b2b.png'
import WxChannel from '@/assets/img/home/banner/wx-channel.png'

const Banner = () => {
  // const [visible, onVisible] = useState(false);
  // const videoRef = useRef(null);
  // const openModal = () => {
  //   onVisible(true);
  //   setTimeout(() => {
  //     if(videoRef.current){
  //       videoRef.current.volume=0.3;
  //       const { player } = videoRef.current.getState();
  //       //当前播放时间
  //       // console.log(player.currentTime)
  //       if(!player.ended){
  //         videoRef.current.play();
  //       }
        
  //     }
  //   }, 100);
  // }

  // const closeModal = () => {
  //   onVisible(false); 
  //   videoRef.current.pause();
  // }

  return (
    <div className={styles.banner}>
      {/* <video width="100%" autoPlay="autoplay" 
      loop="loop" muted="muted" 
      airplay="allow" 
      src={fastDfsUrl("xinhai1/M00/01/50/oYYBAGDn7CGARZMUAMMJVE2lHzc427.mp4")}
      className={styles['banner-video']}></video> */}
      
      <div className={styles["cloud-layer"]}>
        <div className={styles["cloud-layer-image"]}></div>
        <div className={styles["cloud-layer-text"]}>
          <div >
            <FormattedMessage id="home.banner.saascloud1" />
          </div>
          <div>
            <FormattedMessage id="home.banner.saascloud2" />
          </div>
        </div>
        <div className={styles["cloud-layer-switch"]}>
          <div className={styles["switch-l"]}>
            <div className={styles['switch-text']}>
              <div style={{fontSize: '24px', lineHeight: '33px', textShadow: '2px 2px 4px #002A87'}}><FormattedMessage id="home.banner.switch1.text1" /></div>
              <div style={{fontSize: '34px', lineHeight: '44px', textShadow: '2px 2px 4px #002A87'}}><FormattedMessage id="home.banner.switch1.text2" /></div>
              <div style={{fontSize: '16px', lineHeight: '22px', marginTop: '22px'}}><FormattedMessage id="home.banner.switch1.text3" /></div>
            </div>
            <div className={styles['switch-code']}>
              <div className={styles['switch-wx']}>
                <img src={WxB2B} alt="" srcSet=""/>
              </div>
              <div className={styles['switch-wx-text']}><FormattedMessage id="home.banner.switch1.text4" /></div>
            </div>
          </div>
          <div className={styles["switch-r"]}>
            <div className={styles['switch-text']}>
              <div style={{fontSize: '24px', lineHeight: '33px', textShadow: '2px 2px 4px #341A8F'}}><FormattedMessage id="home.banner.switch2.text1" /></div>
              <div style={{fontSize: '34px', lineHeight: '44px', textShadow: '2px 2px 4px #341A8F'}}><FormattedMessage id="home.banner.switch2.text2" /></div>
              <div style={{fontSize: '16px', lineHeight: '22px', marginTop: '22px'}}><FormattedMessage id="home.banner.switch2.text3" /></div>
            </div>
            <div className={styles['switch-code']}>
              <div className={styles['switch-wx']}>
                <img src={WxChannel} alt="" srcSet=""/>
              </div>
              <div className={styles['switch-wx-text']}><FormattedMessage id="home.banner.switch2.text4" /></div>
            </div>
          </div>
        </div> 
      </div>
      {/* <div className={styles["action-bar"]}>
        <div className={styles["action-bar-video-play"]} onClick={openModal}>
        </div>
      </div>

      <Modal
        className={styles['video-wrapper']}
        width='100%'
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <Player ref={videoRef} 
          autoPlay={true}
         >
           <BigPlayButton position="center" disabled />
          <source src={fastDfsUrl("xinhai1/M00/01/50/oYYBAGDn6-KAcmY6FxJrTB_lX-A010.mp4")}/>
        </Player> 
        
        
      </Modal> */}
      {/* <video width="100%" id="video" autoplay="" loop="" muted="" x-webkit-airplay="true" controls="" airplay="allow" webkit-playsinline="true" playsinline="true" 
        src={fastDfsUrl("xinhai1/M00/00/26/wKgKFGC3PNiAINFzCvnNcOTzI6Y151.m4v")} class="bg-video"></video> */}
    </div>
  );
};

export default Banner;