import { save } from '@/services/cooperate';

export default {
    namespace : 'cooperate',
    state : {
    },

    reducers : {
        save(state,  { payload }){
            return { ...state, ...payload }
        }
    },

    effects : {
        *save({payload},{call, put } ){
            //call：axios异步请求
            const response = yield call(save, payload);
            if(response && response.status === '0'){
                return true
            }else{
                return false;
            }
        },
    },

}