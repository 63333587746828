import React, { Component } from 'react';
import classNames from 'classnames'
import {connect} from 'dva'
import Media from 'react-media';
import { FormattedMessage } from 'react-intl';
import {ElfList} from 'elf-ui';
import styles from './index.m.less'
import mobileStyles from './mobile.m.less'
import IconActivity from '@/assets/img/home/news/icon-activity.png'
import IconCompany from '@/assets/img/home/news/icon-company.png'
import IconIndustry from '@/assets/img/home/news/icon-industry.png'
import Activities from './Activities'
import ListCover from './ListCover'
import ListCoverMobile from './ListCoverMobile'
import {Row, Col} from 'antd'

const {Grid} = ElfList

class News extends Component {
  constructor(props){
    super(props);
    this.state={
      actived: 'L02',
      currentPage: 1,
      currentSize: 6,
    }
  }

  componentDidMount(){
    const {actived, currentPage, currentSize} = this.state;
    this.getData({type: actived, pageSize: currentPage*currentSize });
  }

  getData = (params) => {
    const {dispatch} =  this.props;
    dispatch({
      type: 'news/list',
      payload: {
        pageNum: 1,
        ...params
      },
    });
  }

  onActive = (key) => {
    const {currentSize} = this.state;  
    this.setState({
      actived: key,
      current: 1
    }, ()=> {
      this.getData({type: key, pageSize: currentSize });
    })
  }

  onLoadmore = () => {
    const {actived, currentPage, currentSize} = this.state; 
    const current = currentPage + 1;
    this.setState({
      currentPage: current
    }, ()=> {
      this.getData({type: actived, pageSize: current*currentSize });
    })
  }

  render() {
    const {actived} = this.state;
    const {list, total, loading, isMobile} = this.props;
    const imageProps = {
      width: 26,
      height: 26
    }

    if(isMobile){
      return (
        <div className={mobileStyles.main} >
          <div className={mobileStyles['main-layout']}>
            <div className={mobileStyles.title}></div>
            <div className={mobileStyles.content}>
              <span ><FormattedMessage id="home.news.content.text1" /></span>
              <span><FormattedMessage id="home.news.content.text2" /></span>
              <span ><FormattedMessage id="home.news.content.text3" /></span>
            </div>
            <div className={mobileStyles["news-container"]}>
              <div className={mobileStyles["switch-bar"]}>
                <Row gutter={16}>
                  <Col span={8} >
                    <div className={mobileStyles["switch-item"]} onClick={() => this.onActive("L02")} active={ actived === "L02" ? 'true' : "false"}>
                      <img src={IconActivity} alt="" srcSet="" {...imageProps} />
                      <span className={mobileStyles["switch-item-text"]}><FormattedMessage id="home.news.bars.text1" /></span>
                      <div className={mobileStyles["switch-under-line"]}></div>
                    </div>
                  </Col>
                  <Col span={8} >
                    <div className={mobileStyles["switch-item"]} onClick={() => this.onActive("L03")} active={ actived === "L03" ? 'true' : "false"}>
                      <img src={IconCompany} alt="" srcSet="" {...imageProps} />
                      <span className={mobileStyles["switch-item-text"]}><FormattedMessage id="home.news.bars.text2" /></span>
                      <div className={mobileStyles["switch-under-line"]}></div>
                    </div>
                  </Col>
                  <Col span={8} >
                    <div className={mobileStyles["switch-item"]} onClick={() => this.onActive("L04")} active={ actived === "L04" ? 'true' : "false"}>
                      <img src={IconIndustry} alt="" srcSet="" {...imageProps} />
                      <span className={mobileStyles["switch-item-text"]}><FormattedMessage id="home.news.bars.text3" /></span>
                      <div className={mobileStyles["switch-under-line"]}></div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className={mobileStyles.content} >
                {(()=>{
                  switch(actived){
                      case "L02":return <ListCoverMobile dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />; 
                      case "L03":return <ListCoverMobile dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />; 
                      case "L04":return <ListCoverMobile dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />; 
                      default:return <ListCoverMobile dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      )
    }

    

    return (
      <div>
        <div className={styles.banner}>
          <div className={styles['banner-layer']}>
            <div className={styles['banner-layer-image']}></div>
            <div className={classNames(styles['banner-layer-text'])}>
              <span ><FormattedMessage id="news.banner.text1" /></span>
              <span className='font-blod'><FormattedMessage id="news.banner.text2" /></span>
              <span ><FormattedMessage id="news.banner.text3" /></span>
              <span className='font-blod'><FormattedMessage id="news.banner.text4" /></span>
            </div>
          </div>
        </div>
        <div className={styles.layout}>
          <div className={styles.menu}>
            <Grid columns={3} gutter={30}>
              <div key="cp" className={styles["menu-item"]} onClick={() => this.onActive("L02")} actived={ actived === "L02" ? 'true' : "false"}>
                <img src={IconActivity} alt="" srcSet="" {...imageProps} />
                <span className={styles["menu-item-text"]}><FormattedMessage id="home.news.bars.text1" /></span>
              <div className={styles["menu-under-line"]}></div>
            </div>
            <div key="tm" className={styles["menu-item"]} onClick={() => this.onActive("L03")} actived={ actived === "L03" ? 'true' : "false"}>
              <img src={IconCompany} alt="" srcSet="" {...imageProps} />
              <span className={styles["menu-item-text"]}><FormattedMessage id="home.news.bars.text2" /></span>
              <div className={styles["menu-under-line"]}></div>
            </div>
            <div key="pt" className={styles["menu-item"]} onClick={() => this.onActive("L04")} actived={ actived === "L04" ? 'true' : "false"}>
              <img src={IconIndustry} alt="" srcSet="" {...imageProps} />
              <span className={styles["menu-item-text"]}><FormattedMessage id="home.news.bars.text3" /></span>
              <div className={styles["menu-under-line"]}></div>
            </div>
            </Grid>
          </div>
          <div className={styles.content}>
            {(()=>{
                switch(actived){
                    case "L02":return <Activities dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />; 
                    case "L03":return <ListCover dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />; 
                    case "L04":return <ListCover dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />; 
                    default:return <Activities dataSource={list} total={total} onLoad={this.onLoadmore} loading={loading} />;
                 }
               }
            )()}
          </div>
        </div>
      </div>
    );
  }
}



export default connect(({news, loading }) => {
  return ({
    total: news.total,
    list: news.list,
    loading: loading.effects['news/list'] || false,
  })
})(props => (
  <Media query="(max-width: 999px)">
    {isMobile => <News {...props} isMobile={isMobile}/>}
  </Media>
));