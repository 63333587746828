import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames'
import QueueAnim from 'rc-queue-anim'; 
import {Row, Col} from 'antd'
import styles from './mobile.m.less'
import Cover_BankJJ from '@/assets/img/company/partner/cover_bank_jj.png';
import Cover_BankSHNS from '@/assets/img/company/partner/cover_bank_shns.png';
import Cover_BankLH from '@/assets/img/company/partner/cover_bank_lh.png';
import Cover_BankWZ from '@/assets/img/company/partner/cover_bank_wz.png';
import Cover_BankJS from '@/assets/img/company/partner/cover_bank_js.png';
import Cover_BankSN from '@/assets/img/company/partner/cover_bank_sn.png';
import Cover_TKing from '@/assets/img/company/partner/cover_tking.png';
import Cover_RNO from '@/assets/img/company/partner/cover_rno.png';
import Cover_Engma from '@/assets/img/company/partner/cover_engma.png';
import Cover_Haier from '@/assets/img/company/partner/cover_haier.png';
import Cover_JD from '@/assets/img/company/partner/cover_jd.png';
import Cover_MT from '@/assets/img/company/partner/cover_mt.png';
import Cover_BCW from '@/assets/img/company/partner/cover_bcw.png';
import Cover_SF from '@/assets/img/company/partner/cover_sf.png';
import Cover_ELM from '@/assets/img/company/partner/cover_elm.png';
import Cover_BSKD from '@/assets/img/company/partner/cover_bskd.png';
import Cover_LXJM from '@/assets/img/company/partner/cover_lxjm.png';
import Cover_GT from '@/assets/img/company/partner/cover_gt.png';

const partner_list = [
  Cover_BankJJ, Cover_BankSHNS, Cover_BankLH, 
  Cover_BankWZ, Cover_BankJS, Cover_BankSN,
  Cover_TKing, Cover_RNO, Cover_Engma,
  Cover_Haier, Cover_JD, Cover_MT,
  Cover_BCW, Cover_SF, Cover_ELM,
  Cover_BSKD, Cover_LXJM, Cover_GT
]

const Partner = () => {
  const getChild = (num, d, i) => {
    const t = num + 1;
    if (i > t *2 -1 || i < num * 2) {
      return null;
    }
    return (
      <Col span={12} key={i} >
        <div className={styles['partner-image']} style={{background: `url(${d}) no-repeat center center`, backgroundSize: 'contain'}}></div>
      </Col>
    );
  } 
  let children = [];
  for (let i = 0; i < Math.ceil(partner_list.length / 2); i++) {
      children.push(
        <QueueAnim key={i} delay={100} component={Row} componentProps={{gutter: 16, style: {marginBottom : 25}}} type="bottom">
          {partner_list.map(getChild.bind(this, i)).filter(item => item)}
        </QueueAnim>
      );
  } 
  return (
    <div className={styles['partner-container']} id="company-partner" >
      <div className={styles.partner}>
        <div className={styles['partner-conrainer-title']}>
          <div className={classNames(styles['partner-title'], 'font-blod')}><FormattedMessage id="company.menu.partner" /></div>
          <div className={styles['partner-title-bg']}></div>
        </div>
        <div className={styles['partner-list']}>
          <OverPack playScale={0.1}>
            <QueueAnim delay={50} type="bottom" key="partner" ease="easeOutQuart" leaveReverse>
              {children}
            </QueueAnim> 
          </OverPack>
        </div>
      </div>
      <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('company-business').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
    </div>
  );
};

export default Partner;