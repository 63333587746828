import React, { Component } from 'react';
import {connect} from 'dva'
import Media from 'react-media';
import { ContainerQuery } from 'react-container-query';
import classNames from 'classnames';
import { Layout } from 'antd'
import Context from './MenuContext'
import HeaderView from './Header'
import FooterView from './Footer'
import BasicRouter from '../routers/basic'


/**
 * 媒体查询
 */
const query = {
    'screen-xs': {
      maxWidth: 575,
    },
    'screen-sm': {
      minWidth: 576,
      maxWidth: 767,
    },
    'screen-md': {
      minWidth: 768,
      maxWidth: 991,
    },
    'screen-lg': {
      minWidth: 992,
      maxWidth: 1199,
    },
    'screen-xl': {
      minWidth: 1200,
    },
};


class BasicLayout extends Component {

  getContext() {
    const { location, isMobile } = this.props;
    return {
      location,
      isMobile
    };
  }


  render() {
    const { isMobile } = this.props;
      const layout = (screen) => (<Layout >
              <HeaderView {...this.props} fixed={true} />
              <BasicRouter />
              { !isMobile && <FooterView {...this.props}/>}
              
          </Layout>)
      return (
          <ContainerQuery query={query}>
              {params => (
                <Context.Provider value={this.getContext()}>
                  <div className={classNames(params)}>{layout(params)}</div>
                </Context.Provider>
              )}
              
          </ContainerQuery>
          
      );
  }
}

// BasicLayout = injectIntl(BasicLayout)

export default connect()(props => (
    <Media query="(max-width: 999px)">
      {isMobile => <BasicLayout {...props} isMobile={isMobile} />}
    </Media>
  ));