import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import styles from './mobile.m.less'
import { Spin, Icon, Row, Col } from 'antd';
import {fastDfsUrl} from 'utils/String'

const ListCover = ({dataSource=[], total=0, loading=false, onLoad}) => {

  const _data = [];
  let _item =[];
  dataSource.forEach((it, i) => {
    _item.push(it)
    if(i%2===1){
      _data.push(_item);
      _item=[];
    }
  })
  const renderList = _data.map((item, index) => {
    const _children = item.map((_c, key) => (
      <Col key={key} span={12} >
        <div key={index} className={styles["activies-item"]} onClick={() => window.open(_c.articleUrl)} >
          <img src={fastDfsUrl(_c.cover)} alt="" srcSet=""  />
          <div className={styles['text-container']}>
            <div className={styles['text-title']}>{_c.articleTitle}</div>
            <div className={styles['text-date']}>{moment(_c.publicDate).format('YYYY年MM月DD日')}</div>
          </div>
        </div>
      </Col>
    ))
    return (
      <Row key={index} gutter={16} style={{marginBottom: 20}} >
        {_children}
      </Row>
    )
  })
  

  return (
    <div className={styles.activies}>
      {renderList}
      {
        onLoad && total > dataSource.length && <div style={{marginTop: 30}} className={styles['load-more']} onClick={() => loading ? {} : onLoad()}>
          <Spin spinning={loading} indicator={<Icon type="loading" style={{ fontSize: 18, color: '#333333' }}  spin />}>
            <span>加载更多</span>
          </Spin>
        </div>
      }
    </div>
  );
};

ListCover.propTypes = {
  dataSource: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onLoad: PropTypes.func
};

export default ListCover;