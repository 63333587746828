import React, { Component } from 'react';
import { Modal, Form, Input, message, Button } from 'antd';
import Media from 'react-media';
import {connect} from 'dva'
import { FormattedMessage } from 'react-intl';
import QueueAnim from 'rc-queue-anim';
import styles from './index.m.less'
import DistributionMap from './DistributionMap'
import SupportIcon1 from '@/assets/img/cooperate/support-icon-1.png'
import SupportIcon2 from '@/assets/img/cooperate/support-icon-2.png'
import SupportIcon3 from '@/assets/img/cooperate/support-icon-3.png'
import IconApply from '@/assets/img/cooperate/icon-apply.png'
import PublicityImage1 from '@/assets/img/cooperate/publicity-image-1.png'
import PublicityImage2 from '@/assets/img/cooperate/publicity-image-2.png'
import PublicityImage3 from '@/assets/img/cooperate/publicity-image-3.png'
import PublicityImage4 from '@/assets/img/cooperate/publicity-image-4.png'
import PublicityImage5 from '@/assets/img/cooperate/publicity-image-5.png'
import PublicityImage6 from '@/assets/img/cooperate/publicity-image-6.png'
import PublicityImage7 from '@/assets/img/cooperate/publicity-image-7.png'
import PublicityImage8 from '@/assets/img/cooperate/publicity-image-8.png'
import PublicityImage9 from '@/assets/img/cooperate/publicity-image-9.png'
import IconArrow from '@/assets/img/cooperate/icon-arrow.png'
import IconOr from '@/assets/img/cooperate/icon-or.png'
import CooperateMobile from './CooperateMobile'

class Cooperate extends Component {

  constructor(props){
    super(props);
    this.state={
      visible: false,
      active: '01'
    }
  }


  onVisible = (visible) => {
    this.setState({
      visible
    })
  }

  onChangeActive = (active) =>{
    this.setState({
      active
    })
  }

  render() {
    const {visible, active} = this.state;
    return (
      <div>
        <div className={styles.banner}>
          <div className={styles['banner-layer']}>
          </div>
        </div>
        
        <div className={styles.publicity}>
          <div className={styles['publicity-container']}>
            <div className={styles['publicity-menu']}>
              <div actived={active==='01' ? 'true' : 'false'} onClick={() => this.onChangeActive('01')}><FormattedMessage id="cooperate.publicity.menu1" /></div>
              <div actived={active==='02' ? 'true' : 'false'} onClick={() => this.onChangeActive('02')}><FormattedMessage id="cooperate.publicity.menu2" /></div>
              <div actived={active==='03' ? 'true' : 'false'} onClick={() => this.onChangeActive('03')}><FormattedMessage id="cooperate.publicity.menu3" /></div>
            </div>
            {(()=>{
              switch(active){
                case "01":return <div className={styles['publicity-content']} >
                  <QueueAnim key="menu-01-01" animConfig={[{ opacity:[1, 0] }]} delay={100} interval={300} className={styles['publicity-item']} >
                    <img key="menu-01-01-01" src={PublicityImage1} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-01-02" animConfig={[{ opacity:[1, 0] }]} delay={1000} className={styles['publicity-item-arrow']} >
                    <img key="menu-01-02-01" src={IconArrow} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-01-03" animConfig={[{ opacity:[1, 0] }]} delay={500} interval={300} className={styles['publicity-item']} >
                    <img key="menu-01-03-01" src={PublicityImage2} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-01-04" animConfig={[{ opacity:[1, 0] }]} delay={1000} className={styles['publicity-item-arrow']} >
                    <img key="menu-01-04-01" src={IconArrow} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-01-05" animConfig={[{ opacity:[1, 0] }]} delay={900} interval={300} className={styles['publicity-item']} >
                    <img key="menu-01-05-01" src={PublicityImage3} alt="" srcSet=""/>
                  </QueueAnim>
                </div>;
                case "02":return <div className={styles['publicity-content']}>
                  <QueueAnim key="menu-02-01" animConfig={[{ opacity:[1, 0] }]} delay={100} interval={300} className={styles['publicity-item']} >
                    <img key="menu-02-01-01" src={PublicityImage4} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-02-02" animConfig={[{ opacity:[1, 0] }]} delay={1000} className={styles['publicity-item-arrow']} >
                    <img key="menu-02-02-01" src={IconArrow} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-02-03" animConfig={[{ opacity:[1, 0] }]} delay={500} interval={300} className={styles['publicity-item']} >
                    <img key="menu-02-03-01" src={PublicityImage5} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-02-04" animConfig={[{ opacity:[1, 0] }]} delay={1000} className={styles['publicity-item-arrow']} >
                    <img key="menu-02-04-01" src={IconArrow} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-02-05" animConfig={[{ opacity:[1, 0] }]} delay={900} interval={300} className={styles['publicity-item']} >
                    <img key="menu-02-05-01" src={PublicityImage6} alt="" srcSet=""/>
                  </QueueAnim>
                </div>;
                case "03": return <div className={styles['publicity-content']}>
                  <QueueAnim key="menu-03-01" animConfig={[{ opacity:[1, 0] }]} delay={100} interval={300} className={styles['publicity-item']} >
                    <img key="menu-03-01-01" src={PublicityImage7} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-03-02" animConfig={[{ opacity:[1, 0] }]} delay={1000} className={styles['publicity-item-arrow']} >
                    <img key="menu-03-02-01" src={IconArrow} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-03-03" animConfig={[{ opacity:[1, 0] }]} delay={500} interval={300} className={styles['publicity-item']} >
                    <img key="menu-03-03-01" src={PublicityImage8} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-03-04" animConfig={[{ opacity:[1, 0] }]} delay={1000} className={styles['publicity-item-arrow']} >
                    <img key="menu-03-04-01" src={IconArrow} alt="" srcSet=""/>
                  </QueueAnim>
                  <QueueAnim key="menu-03-05" animConfig={[{ opacity:[1, 0] }]} delay={900} interval={300} className={styles['publicity-item']} >
                    <img key="menu-03-05-01" src={PublicityImage9} alt="" srcSet=""/>
                  </QueueAnim>
                </div>;
                default:return null;
              }
            })()}
          </div>
        </div>
        <div className={styles.support}>
          <div className={styles['support-container']}>
            <div className={styles['support-content']}>
              <div className={styles['support-title']}><FormattedMessage id="cooperate.support.title" /></div>
              <div className={styles['support-title-gradient']} style={{marginTop: 20}}><FormattedMessage id="cooperate.support.sub-title1" /></div>

              <div className={styles['support-text-blue']} style={{marginTop: 20}}><FormattedMessage id="cooperate.support.text1" /></div>
              <div className={styles['support-icon-items']} style={{marginTop: 20, width: '555px'}} >
                <div className={styles['icon-image']} style={{background: `url(${SupportIcon1}) no-repeat center center`, backgroundSize: 'cover'}}></div>
                <div className={styles['icon-text']} style={{width: '134px'}}><FormattedMessage id="cooperate.support.icon1.text" /></div>
                
                <div className={styles['icon-image']} style={{background: `url(${SupportIcon2}) no-repeat center center`, backgroundSize: 'cover'}}></div>
                <div className={styles['icon-text']} style={{width: '114px'}}><FormattedMessage id="cooperate.support.icon2.text" /></div>

                <div className={styles['icon-image']} style={{background: `url(${SupportIcon3}) no-repeat center center`, backgroundSize: 'cover'}}></div>
                <div className={styles['icon-text']} style={{width: '95px'}}><FormattedMessage id="cooperate.support.icon3.text" /></div>
              </div>
              <div className={styles['support-text-blue']} style={{marginTop: 20}}><FormattedMessage id="cooperate.support.text2" /></div>
              <div className={styles['support-text']} style={{width: '520px'}}><FormattedMessage id="cooperate.support.text3" /></div>
              <div className={styles['support-text-blue']}><FormattedMessage id="cooperate.support.text4" /></div>
              <div className={styles['support-text']}><FormattedMessage id="cooperate.support.text5" /></div>
              <div className={styles['support-text-blue']}><FormattedMessage id="cooperate.support.text6" /></div>
              <div className={styles['support-text']}><FormattedMessage id="cooperate.support.text7" /></div>

              <div className={styles['support-title-gradient']} style={{marginTop: 20}}><FormattedMessage id="cooperate.support.sub-title2" /></div>
              <div className={styles['support-text']} style={{fontSize: '18px', color: '#002A8C', fontWeight: 600, marginTop: 20}}><FormattedMessage id="cooperate.support.text8" /></div>
              
              <div className={styles['support-text']} style={{fontWeight: 600}}>
                <FormattedMessage id="cooperate.support.text9" />
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text10" /></span>
              </div>
              <div className={styles['support-text']} >
                <span><FormattedMessage id="cooperate.support.text11" /></span>
              </div>
              <div className={styles['support-text']} style={{ marginTop: 20}}>
                <span><FormattedMessage id="cooperate.support.text12" /></span>
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text13" /></span>
              </div>
              <div className={styles['support-text']} >
                <span><FormattedMessage id="cooperate.support.text20" /></span>
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text21" /></span>
              </div>
              <div className={styles['support-text']} >
                <span><FormattedMessage id="cooperate.support.text22" /></span>
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text23" /></span>
              </div>

              <div className={styles['support-text']} style={{fontWeight: 600, marginTop: 20}}>
                <span><FormattedMessage id="cooperate.support.text17" /></span>
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text18" /></span>
              </div>

              <div className={styles['support-text']} style={{fontWeight: 600, marginTop: 20}}>
                <span><FormattedMessage id="cooperate.support.text27" /></span>
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text38" /></span>
              </div>
               
              <div className={styles['support-text']} style={{fontWeight: 600, marginTop: 20}}>
                <span><FormattedMessage id="cooperate.support.text37" /></span>
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text14" /></span>
              </div>
              
              <div className={styles['support-text']} style={{fontWeight: 600, marginTop: 20}}>
                <span><FormattedMessage id="cooperate.support.text47" /></span>
              </div>
              <div className={styles['support-text']}>
                <span><FormattedMessage id="cooperate.support.text16" /></span>
              </div>
              
              
            </div>
            <div className={styles['support-image']}></div>
          </div>
        </div>
        <div className={styles.channel}>
          <div className={styles['channel-container']}>
            <div className={styles['channel-apply']} >
              <div className={styles['apply-button']} onClick={() => this.onVisible(true)}>
                <div ><img src={IconApply} alt="" srcSet=""/></div>
                <div className={styles['apply-text']}><FormattedMessage id="cooperate.apply.text" /></div>
              </div>
            </div>
            <div className={styles['channel-content']}>
              <div style={{width: 480}}>
                <div className={styles['channel-title']}><FormattedMessage id="cooperate.channel.left.title" /></div>
                <div className={styles['channel-text']}>
                  <div><FormattedMessage id="cooperate.channel.left.text1" /></div>
                  <div><FormattedMessage id="cooperate.channel.left.text2" /></div>
                  <div><FormattedMessage id="cooperate.channel.left.text3" /></div>
                  <div><FormattedMessage id="cooperate.channel.left.text4" /></div>
                  <div><FormattedMessage id="cooperate.channel.left.text5" /></div>
                  <div><FormattedMessage id="cooperate.channel.left.text6" /></div>
                  <div><FormattedMessage id="cooperate.channel.left.text7" /></div>
                </div>
              </div>
              <div style={{width: 430}}>
                <div className={styles['channel-title']}><FormattedMessage id="cooperate.channel.right.title" /></div>
                <div className={styles['channel-text']}><FormattedMessage id="cooperate.channel.right.text" /></div>
              </div>
            </div>
            <div className={styles['channel-bottom-image']}></div>
          </div>
        </div>
        <DistributionMap />
        <Modal
          className={styles['contact-wrapper']}
          visible={visible}
          maskClosable={false}
          keyboard={false}
          style={{top: 100}}
          width={600}
          onOk={() => this.onVisible(false)}
          onCancel={() => this.onVisible(false)}
          footer={null}
        >
          <div className={styles['contact-container']}>
            <div className={styles['contact-title']}><FormattedMessage id="cooperate.contact.title" /></div>
            <div className={styles['contact-form']}>
              <ContactForm key={new Date().getTime()} onClose={() => this.onVisible(false) } />
            </div>
          </div>
        </Modal>
        
      </div>
    );
  }
}





const ContactForm = connect(({loading }) => {
  return ({
    loading: loading.effects['cooperate/save'] || false,
  })
})(Form.create()( props => {
  const { form: {getFieldDecorator, validateFields}, onClose, dispatch, loading } = props;
  const formProps = {
    labelAlign: 'left',
    colon: false,
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 },
    },
  }

  const _onSumbit = (e) => {
    e.preventDefault();
    validateFields((errors, values) => {
      if(!errors){
        dispatch({
          type: 'cooperate/save',
          payload: {
            cjName: values.name,
            cjPhone: values.phone,
            cjCorname: values.company,
            cjOpinion: values.opinion.trim(),
            cjType : '0'
          },
        }).then(br => {
          if(br){
            message.success('提交成功');
            onClose();
          }
        });
      }
    });
  }


  return <Form {...formProps} onSubmit={(e) => _onSumbit(e)}>
          <Form.Item label="公司名称">
            {getFieldDecorator('company', {
              rules: [
                { required: true, message: '请输入公司名称'},
                { max: 50, message: '请输入50字以内字符',},
              ],
              getValueFromEvent: (event) => {
                return event.target.value.replace(/\s+/g,"")
              },
            })(<Input placeholder='请输入公司名称' maxLength={50} autoComplete='off' />)}
          </Form.Item>
          <Form.Item label="姓名" >
            {getFieldDecorator('name', {
              rules: [
                { required: true,message: '请输入姓名',},
                { max: 10, message: '请输入10字以内字符',},
              ],
              getValueFromEvent: (event) => {
                return event.target.value.replace(/\s+/g,"")
              },
            })(<Input placeholder='请输入姓名' maxLength={10} autoComplete='off' />)}
          </Form.Item>
          <Form.Item label="联系号码" >
            {getFieldDecorator('phone', {
              rules: [
                { required: true,message: '请输入联系号码',},
                { max: 20, message: '请输入20字以内联系号码',},
              ],
              getValueFromEvent: (event) => {
                return event.target.value.replace(/[^0-9|-]+/g,'')
              },
            })(<Input placeholder='请输入联系号码' maxLength={20} autoComplete='off' />)}
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            {getFieldDecorator('opinion', {})(<Input.TextArea
              placeholder="请输入您的诉求，仅限100个字"
              maxLength={100} autoComplete='off' 
              autoSize={{ minRows: 3, maxRows: 3 }}
            />)}
            <div className={styles['item-note']}><FormattedMessage id="cooperate.contact.note" /></div>
          </Form.Item>
          
          <Form.Item wrapperCol={{ span: 24 }}>
            <div className={styles['button-group']}>
              <Button className={styles.button} onClick={() => onClose()} ><FormattedMessage id="cooperate.contact.cancel" /></Button>
              <Button type="primary" htmlType="submit" loading={loading} className={styles.button}><FormattedMessage id="cooperate.contact.submit" /></Button>
            </div>
            
          </Form.Item>
        </Form>
}))

export default connect(({news, loading }) => {
  return ({
    total: news.total,
    list: news.list,
    loading: loading.effects['news/list'] || false,
  })
})(props => (
  <Media query="(max-width: 999px)">
    {isMobile => isMobile ? <CooperateMobile {...props} /> : <Cooperate {...props} />}
  </Media>
));;