import { list, getParams, getInfo } from '@/services/recruitment';

export default {
    namespace : 'recruitment',
    state : {
        list: [],
        sites: [],
        jobs: []
    },

    reducers : {
        save(state,  { payload }){
            return { ...state, ...payload }
        }
    },
    effects : {
        *list({payload},{call, put } ){
            //call：axios异步请求
            const response = yield call(list, payload);
            if(response){
                yield put({
                    type: 'save',
                    payload: {
                        list : response.list
                    },
                });
            }else{
                yield put({
                    type: 'save',
                    payload: {
                        list : []
                    },
                });
            }
        },
        *getParams({payload},{call, put } ){
            //call：axios异步请求
            const response = yield call(getParams, payload);
            if(response && response.map){
                //职位
                const sites = response.map['02'].filter(it => it.websiteShow === '2');
                //地点
                const jobs = response.map['01'].filter(it => it.websiteShow === '2');
                yield put({
                    type: 'save',
                    payload: {
                        sites : sites,
                        jobs: jobs
                    },
                });
                yield put({
                    type: 'list',
                    payload: {
                        "site": "",
                        "post": "",
                    },
                });
            }
            yield put({
                type: 'save',
                payload: {},
            });
        },
        *getInfo({payload},{call, put } ){
            //call：axios异步请求
            const response = yield call(getInfo, payload);
            if(response){
                return response.recruitmentInfo;
            }else{
                return undefined
            }
        }
    },

}