import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Overlap from './Overlap'
import styles from './index.m.less'


const Meta = ({calssName, style, children}) => {
  return (
    <div className={classNames(styles['card-meta'], calssName)} style={style}>
      {children}
    </div>
  );
}

const index = ({cover, coverStyle, className, style, bodyClassName, bodyStyle, offset, bodyOffset, children}) => {
  let childStyle = bodyStyle || {};
  if(offset){
    if(offset.left){
      //左偏移量
      childStyle.marginLeft = offset.left;
    }
    if(offset.right){
      //右偏移量
      childStyle.marginRight = offset.right;
    }
  }
  if(bodyOffset){
    if(bodyOffset.left){
      //左偏移量
      childStyle.paddingLeft = bodyOffset.left;
    }
    if(bodyOffset.right){
      //右偏移量
      childStyle.paddingRight = bodyOffset.right;
    }
  }
  const renderCover = cover ? <div className={styles['card-cover']} style={coverStyle}>{cover}</div> : null;
  return (
    <div className={classNames(styles.card, className)} style={style}>
      {
        renderCover
      }
      <div style={childStyle} className={classNames(styles['card-content'], bodyClassName)} >
        {children}
      </div>
    </div>
  );
};

index.Overlap = Overlap;
index.Meta = Meta;

index.propTypes = {
  cover: PropTypes.node,
};

export default index;