import React from 'react';
import PropTypes from 'prop-types';
import Grid from './Grid'
import Card from './Card'
import styles from './index.m.less'

const index = ({dataSource=[], renderItem, loadMore}) => {
  const renderList = dataSource.map((item, i) => <div key={i} className={styles['list-item-border']}>
    <div className={styles['list-item']}>
      <div className={styles['item-cover']}>
        <div className={styles.triangle}></div>
        <div className={styles.dot}></div>
      </div>
      <div className={styles['item-content']}>{renderItem(item)}</div>
    </div>
  </div>)
  return (
    <div className={styles.list}>
      {renderList}
      {loadMore}
    </div>
  );
};

index.Grid = Grid;
index.Card = Card;

index.propTypes = {
  dataSource: PropTypes.array.isRequired,
  renderItem: PropTypes.func,
  loadMore: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default index;