import React, {useState, useEffect}  from 'react';
import classNames from 'classnames'
import {connect} from 'dva'
import styles from './mobile.m.less';
import { FormattedMessage } from 'react-intl';
import { OverPack } from 'rc-scroll-anim';
import * as echarts from "echarts";
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import IconMoney from '@/assets/img/home/industry/icon-money.png';
import IconShop from '@/assets/img/home/industry/icon-shop.png';
import IconCompany from '@/assets/img/home/industry/icon-company.png';
import IconAgent from '@/assets/img/home/industry/icon-agent.png';

TweenOne.plugins.push(Children);

const keyMap ={
  'chart-data-service': 'IS_BAR_004',
  'chart-data-company': 'IS_BAR_005',
  'chart-data-shop': 'IS_BAR_006',
  'chart-data-agent': 'IS_BAR_007'
}

const Industry = ({dispatch, statistic, industryEndDate}) => {

  const [current, setCurrent] = useState('service');

  useEffect(()=> {
    dispatch({
      type: 'home/industryStatistic',
      payload: {},
    })
  },[dispatch])

  useEffect(()=> {
    if(current){
      let chartElement = document.getElementById(`chart-data-${current}`);
      const _data = statistic[keyMap[`chart-data-${current}`]];
      if(chartElement && _data){
        let dataChart = echarts.init(chartElement);
        let option ={
          grid: {
            left:65,
            right: 15,
            top: 45,
            bottom: 30
          },
          xAxis: {
            type: 'category',
            data: _data.list.map(_it => _it.xValue),
            axisLine: {
              lineStyle: {
                color: '#32C5FF'
              }
            },
            axisLabel: {
              interval:'0',
              rotate: 40,
              fontSize: 16,
              color: '#FFFFFF'
            }
          },
          yAxis: {
            name: `{i|}{t|年度业务}{u|单位：${_data.yUnit}}`,
            nameGap: 25,
            nameTextStyle: {
              align: 'left',
              padding: [0, 0, 0, -20],
              rich: {
                i: {
                  width: 10,
                  height: 10,
                  backgroundColor: '#47E8FF'
                },
                t: {
                  width: 100,
                  fontSize: 16,
                  padding: [3, 0, 0, 10],
                  color: '#FFFFFF',
                },
                u: {
                  color: '#FFFFFF',
                  fontSize: 16,
                  padding: [3, 0, 0, 0],
                },
              }
            },
            type: 'value',
            interval: Number(_data.yInterval),
            axisLine: {
              lineStyle: {
                color: '#FFFFFF'
              }
            },
            axisLabel: {
              fontSize: 16,
            },
            splitLine: {
              lineStyle:{
                color: 'rgba(50, 197, 255, 0.3)'
              }
            }, 
          },
          series: [
            {
              data: _data.list.map(_it => _it.yValue),
              type: 'bar',
              barWidth : 10,//柱图宽度
              itemStyle: {
                normal: {
                  //数值显示
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: '#fff',
                      fontSize: 16
                    }
                  },
                  //颜色渐变
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#47E8FF'
                  }, {
                    offset: 1,
                    color: '#487AFF'
                  }])
                }
              },
            },
          ]
        }
        dataChart.setOption(option);
      }
    }
  },[current, statistic])

  return (
    <div>
      <div className={styles.industry} id="industry" >
        <div className={styles['industry-title']}>
          <div className={styles.title}></div>
          <div className={styles.content}>
            <span ><FormattedMessage id="home.industry.content.text1" /></span>
            <span className="font-blod"><FormattedMessage id="home.industry.content.text2" /></span>
            <span ><FormattedMessage id="home.industry.content.text3" /></span>
            <span className="font-blod"><FormattedMessage id="home.industry.content.text4" /></span>
          </div>
        </div>
        <div className={styles['industry-content']}>
          <div className={styles['industry-cube']} >
            <QueueAnim type={"scale"} duration={150} interval={50}>
              <div key="cube-1" className={styles["icon-cube"]} style={{width: 60, height: 60, left: 70, top: 170, zIndex: 30}} ></div>
              <div key="cube-5" className={styles["icon-cube"]} style={{width: 130, height: 130, left: 175, top: 170, zIndex: 25}} ></div>
              <div key="cube-3" className={styles["icon-cube"]} style={{width: 140, height: 140, left: 175, top: 85, zIndex: 25}} ></div>
              <div key="cube-10" className={styles["icon-cube"]} style={{width: 80, height: 80, left: 310, top: 135, zIndex: 25}} ></div>
              <div key="cube-4" className={styles["icon-cube"]} style={{width: 120, height: 120, left: 250, top: 140, zIndex: 25}} ></div>
              <div key="cube-7" className={styles["icon-cube"]} style={{width: 150, height: 150, left: 135, top: 255, zIndex: 20}} ></div>
              <div key="cube-22" className={styles["icon-cube"]} style={{width: 90, height: 90, left: 250, top: 335, zIndex: 15}} ></div>
              <div key="cube-8" className={styles["icon-cube"]} style={{width: 110, height: 110, left: 75, top: 300, zIndex: 30}} ></div>
              <div key="cube-12" className={styles["icon-cube"]} style={{width: 60, height: 60, left: 320, top: 200, zIndex: 25}} ></div>
              <div key="cube-17" className={styles["icon-cube"]} style={{width: 80, height: 80, left: 390, top: 180, zIndex: 20}} ></div>
              <div key="cube-13" className={styles["icon-cube"]} style={{width: 110, height: 110, left: 265, top: 210, zIndex: 24}} ></div>
              <div key="cube-14" className={styles["icon-cube"]} style={{width: 110, height: 110, left: 210, top: 230, zIndex: 18}} ></div>
              <div key="cube-16" className={styles["icon-cube"]} style={{width: 110, height: 110, left: 330, top: 200, zIndex: 25}} ></div>
              <div key="cube-20" className={styles["icon-cube"]} style={{width: 100, height: 100, left: 350, top: 275, zIndex: 25}} ></div>
              <div key="cube-15" className={styles["icon-cube"]} style={{width: 110, height: 110, left: 155, top: 315, zIndex: 18}} ></div>
              <div key="cube-18" className={styles["icon-cube"]} style={{width: 100, height: 100, left: 230, top: 300, zIndex: 18}} ></div>
              <div key="cube-23" className={styles["icon-cube"]} style={{width: 90, height: 90, left: 305, top: 270, zIndex: 15}} ></div>
              <div key="cube-21" className={styles["icon-cube"]} style={{width: 90, height: 90, left: 305, top: 325, zIndex: 18}} ></div>
              <div key="cube-11" className={styles["icon-cube"]} style={{width: 80, height: 80, left: 350, top: 100, zIndex: 25}} ></div>

              <div key="cube-2" className={styles["icon-cube"]} style={{width: 140, height: 140, left: 130, top: 35, zIndex: 30}}>
                <img src={IconMoney} alt="" style={{position: 'absolute', left: 35, top: 34}} width={25} />
              </div>
              <div key="cube-6" className={styles["icon-cube"]} style={{width: 90, height: 90, left: 150, top: 210, zIndex: 30}}>
                <img src={IconShop} alt="" style={{position: 'absolute', left: 21, top: 24}} width={20} />
              </div>
              <div key="cube-9" className={styles["icon-cube"]} style={{width: 100, height: 100, left: 295, top: 40, zIndex: 30}}>
                <img src={IconCompany} alt="" style={{position: 'absolute', left: 23, top: 30}} width={20} />
              </div>
              <div key="cube-19" className={styles["icon-cube"]} style={{width: 170, height: 170, left: 250, top: 205, zIndex: 30}}>
                <img src={IconAgent} alt="" style={{position: 'absolute', left: 40, top: 45}} width={35} />
              </div>
            </QueueAnim>
          </div>
          <div style={{minHeight: 600}}>
            <OverPack playScale={[0.2, 0.2]} >
              <QueueAnim type="bottom" duration={100} interval={100} >
                <div key="card-01" className={styles['industry-data']}>
                  <div className={styles['industry-data-title']}>薪海数据库内用工企业数</div>
                  <div className={styles['industry-data-text']}>
                    <TweenOne
                    animation={{
                      Children: { 
                        value: statistic['IS_COUNT_001'] ? Number(statistic['IS_COUNT_001'].value) : 0,
                        floatLength: 0
                      }, 
                      duration: 1000,
                    }}
                    >0</TweenOne>
                  </div>
                </div>
                <div key="card-02" className={styles['industry-data']}>
                  <div className={styles['industry-data-title']}>
                    <div>薪企链平台</div>
                    <div>B2B用工匹配交易量</div>
                  </div>
                  <div className={styles['industry-data-text']} style={{marginTop: 5}} >
                      <TweenOne
                      animation={{
                        Children: { 
                          value: statistic['IS_COUNT_002'] ? Number(statistic['IS_COUNT_002'].value) : 0,
                          floatLength: 0
                        }, 
                        duration: 1000,
                      }}
                      style={{
                        display: 'inline-block'
                      }}
                      >0</TweenOne>
                      <span className={styles["industry-data-text-unit"]}>{statistic['IS_COUNT_002'] && statistic['IS_COUNT_002'].unit}</span>
                    </div>
                </div>
                <div key="card-03" className={styles['industry-data']}>
                  <div className={styles['industry-data-title']}>薪海数据库内人资企业数</div>
                  <div className={styles['industry-data-text']}>
                    <TweenOne
                    animation={{
                      Children: { 
                        value: statistic['IS_COUNT_003'] ? Number(statistic['IS_COUNT_003'].value) : 0,
                        floatLength: 0
                      }, 
                      duration: 1000,
                    }}
                    >0</TweenOne>
                  </div>
                </div>

                <div key="card-03" className={styles['industry-data']}>
                  <div className={styles['industry-data-title']}>薪海数据库内职业院校</div>
                  <div className={styles['industry-data-text']} >
                      <TweenOne
                      animation={{
                        Children: { 
                          value: statistic['IS_COUNT_008'] ? Number(statistic['IS_COUNT_008'].value) : 0,
                          floatLength: 0
                        }, 
                        duration: 1000,
                      }}
                      style={{
                        display: 'inline-block'
                      }}
                      >0</TweenOne>
                      <span className={styles["industry-data-text-unit"]}>{statistic['IS_COUNT_008'] && statistic['IS_COUNT_008'].unit}</span>
                    </div>
                </div>
              </QueueAnim>
            </OverPack>
          </div>
        </div>
        <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('industry-chart').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
      </div>

      <div className={styles['industry-chart']} id="industry-chart" >
        <div className={styles['industry-title']}>
          <div className={styles.title}></div>
          <div className={styles.content}>
            <span ><FormattedMessage id="home.industry.content.text1" /></span>
            <span className="font-blod"><FormattedMessage id="home.industry.content.text2" /></span>
            <span ><FormattedMessage id="home.industry.content.text3" /></span>
            <span className="font-blod"><FormattedMessage id="home.industry.content.text4" /></span>
          </div>
        </div>
        <div className={styles['industry-content']}>
          <OverPack playScale={[0.1, 0.1]} >
            <div className={styles['industry-chart-title-row']}>
              <div className={styles['industry-chart-title-col']}>
                <div className={styles['chart-title']}>
                  <div>薪企链平台</div>
                  <div>应收账款转让服务量</div>
                </div>
                {
                  statistic['IS_BAR_004'] && 
                  <div className={styles['chart-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_004'].value),
                            floatLength: 0,
                            formatMoney: true
                          }, 
                          duration: 500,
                        }}
                      >0</TweenOne>
                    <span className={styles['chart-text-unit']} >{statistic['IS_BAR_004'].unit}</span>
                  </div>
                }
              </div>
              <div className={styles['industry-chart-title-col']}>
                <div className={styles['chart-title']}>
                  <div>薪企链平台</div>
                  <div>入驻人资企业数</div>
                </div>
                {
                  statistic['IS_BAR_005'] && 
                  <div className={styles['chart-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_005'].value),
                            floatLength: 0,
                            formatMoney: true
                          }, 
                          duration: 500,
                        }}
                      >0</TweenOne>
                    <span className={styles['chart-text-unit']} >{statistic['IS_BAR_005'].unit}</span>
                  </div>
                }
              </div>
            </div>
            <div className={styles['industry-chart-title-row']}>
              <div className={styles['industry-chart-title-col']}>
                <div className={styles['chart-title']}>
                  <div>薪企链平台</div>
                  <div>入驻二手单(含门店)人资企业数</div>
                </div>
                {
                  statistic['IS_BAR_006'] && 
                  <div className={styles['chart-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_006'].value),
                            floatLength: 0,
                            formatMoney: true
                          }, 
                          duration: 500,
                        }}
                      >0</TweenOne>
                    <span className={styles['chart-text-unit']} >{statistic['IS_BAR_006'].unit}</span>
                  </div>
                }
              </div>
              <div className={styles['industry-chart-title-col']}>
                <div className={styles['chart-title']}>
                  <div>薪企链平台</div>
                  <div>入驻经纪人数</div>
                </div>
                {
                  statistic['IS_BAR_007'] && 
                  <div className={styles['chart-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_007'].value),
                            floatLength: 0,
                            formatMoney: true
                          }, 
                          duration: 500,
                        }}
                      >0</TweenOne>
                    <span className={styles['chart-text-unit']} >{statistic['IS_BAR_007'].unit}</span>
                  </div>
                }
              </div>
            </div>
          </OverPack>
          <div className={styles['industry-chart-area']}>
            <div className={styles['chart-data-menu']}>
              <div className={current === 'service' ? classNames(styles['menu-item'], styles['menu-item-actived']) : styles['menu-item']} 
              onClick={()=> setCurrent('service')}>
                <div>应收账款转让服务量</div>
              </div>
              <div className={current === 'company' ? classNames(styles['menu-item'], styles['menu-item-actived']) : styles['menu-item']}
              onClick={()=> setCurrent('company')}>
                <div>入驻人资企业数</div>
              </div>
              <div className={current === 'shop' ? classNames(styles['menu-item'], styles['menu-item-actived']) : styles['menu-item']}
              onClick={()=> setCurrent('shop')}>
                <div>入驻二手单(含门店)人资企业数</div>
              </div>
              <div className={current === 'agent' ? classNames(styles['menu-item'], styles['menu-item-actived']) : styles['menu-item']}
              onClick={()=> setCurrent('agent')}>
                <div>入驻经纪人数</div>
              </div>
            </div>
            {
              current ==='service' && <div id="chart-data-service" className={styles['chart-data']} >数据内容</div>
            }
            {
              current ==='company' && <div id="chart-data-company" className={styles['chart-data']} >数据内容</div>
            }
            {
              current ==='shop' && <div id="chart-data-shop" className={styles['chart-data']} >数据内容</div>
            }
            {
              current ==='agent' && <div id="chart-data-agent" className={styles['chart-data']} >数据内容</div>
            }
          </div>
          {
            industryEndDate && <div className={styles["data-note"]} >（数据截止至{industryEndDate}）</div>
          }
        </div>
        <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('news-center').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
      </div>
    </div>
    
  );
};

export default connect(({home}) => {
  return ({
    statistic: home.industryStatistic,
    industryEndDate: home.industryEndDate
  })
})(Industry);