export default {
    "default.submit" : '提交',
    "default.close" : '关闭',
    "default.submit.finish" : '提交成功',
    "crumb.prefix" : "位置：",

    "system.footer.company" : '薪海科技（上海）有限公司',
    "system.footer.company.phone" : '电话：400-850-2036',
    "system.footer.company.hotline" : '客服热线：137-9526-8282（微信同号）',
    "system.footer.company.email" : 'E-mail：service@51xinhai.com',
    "system.footer.company.working-time" : '工作时间  周一至周五 9：00-18：00',
    "system.footer.company.address-sh" : '上海总部：上海市杨浦区国权北路1688弄78号1505室',
    "system.footer.company.address-sz" : '深圳总部：深圳市南山区南山大道1088号南园枫叶大厦15楼',
    "system.footer.company.address-zj" : '浙江分公司：浙江省宁波市江北区文教路海田大厦1112',

    "system.footer.about.xql" : '关于薪企链',
    "system.footer.about.company" : '企业介绍',
    "system.footer.about.honor" : '荣誉资质',
    "system.footer.about.develop" : '发展历程',
    "system.footer.about.team" : '团队介绍',
    "system.footer.about.partner" : '合作伙伴',
    "system.footer.about.business" : '业务分布',
    "system.footer.product.hot" : '热门产品推荐',
    "system.footer.product.salary" : '日结/预付工资垫资',
    "system.footer.product.intelligent-time" : '智能工时采集服务',
    "system.footer.product.insurance-agent" : '商业保险代理',
    "system.footer.product.B2B" : 'B2B用工撮合交易',
    "system.footer.wechat-xql" : '扫码关注薪企链',
    "system.footer.wechat-xql-customer" : '扫码联系薪企链客服',
    "system.footer.copyright" : '官网版权所有@薪海科技（上海）有限公司',
    "system.footer.icp" : '沪ICP备19018231号',
    "system.footer.miit" : '沪B2-20201311',
    "system.footer.voucher" : '人力资源服务业务备案凭证'

}