import { list, getMap } from '@/services/news';

export default {
    namespace : 'news',
    state : {
        total: 0,
        list: [],
    },

    reducers : {
        save(state,  { payload }){
            return { ...state, ...payload }
        }
    },

    effects : {
        *list({payload},{call, put } ){
            //call：axios异步请求
            const data = yield call(list, payload);
            if(data){
                yield put({
                    type: 'save',
                    payload: {
                        total: data.count,
                        list : data.list
                    },
                });
            }else{
                yield put({
                    type: 'save',
                    payload: {
                        total: 0,
                        list : []
                    },
                });
            }
            
        },
        *getMap({payload},{call, put } ){
            //call：axios异步请求
            const data = yield call(getMap, payload);
            if(data){
                return data.map
            }else{
                return undefined;
            }
        },
    },

}