import React, {useState, useEffect} from 'react';
import classNames from 'classnames'
import {connect} from 'dva'
import styles from './mobile.m.less';
import { FormattedMessage } from 'react-intl';
import IconActivity from '@/assets/img/home/news/icon-activity.png'
import IconCompany from '@/assets/img/home/news/icon-company.png'
import IconIndustry from '@/assets/img/home/news/icon-industry.png'
import {fastDfsUrl} from 'utils/String'
import {Row, Col} from 'antd'


const NewsCenter = (props) => {
  const {history, dispatch} = props
  const [active, onActive] = useState("L02");
  const [dataMap, onChangeData] = useState(undefined);
  const imageProps = {
    width: 26,
    height: 26
  }

  useEffect(() => {
    dispatch({
      type: 'news/getMap',
      payload: {},
    }).then(result => {
      if(result){
        onChangeData(result)
      }
    });

  }, [dispatch])

  let _list = []
  if(dataMap && active){
    _list = dataMap[active];
  }else{
    _list = []
  }


  return (
    <div className={styles.news} id="news-center">
      <div className={styles['news-layout']}>
        <div className={styles.title}></div>
        <div className={styles.content}>
          <span className="font-blod" ><FormattedMessage id="home.news.content.text1" /></span>
          <span><FormattedMessage id="home.news.content.text2" /></span>
          <span className="font-blod"><FormattedMessage id="home.news.content.text3" /></span>
        </div>
        <div className={styles["news-container"]}>
          <div className={styles["switch-bar"]}>
            <Row gutter={16}>
              <Col span={8} >
                <div className={styles["switch-item"]} onClick={() => onActive("L02")} active={ active === "L02" ? 'true' : "false"}>
                  <img src={IconActivity} alt="" srcSet="" {...imageProps} />
                  <span className={styles["switch-item-text"]}><FormattedMessage id="home.news.bars.text1" /></span>
                  <div className={styles["switch-under-line"]}></div>
                </div>
              </Col>
              <Col span={8} >
                <div className={styles["switch-item"]} onClick={() => onActive("L03")} active={ active === "L03" ? 'true' : "false"}>
                  <img src={IconCompany} alt="" srcSet="" {...imageProps} />
                  <span className={styles["switch-item-text"]}><FormattedMessage id="home.news.bars.text2" /></span>
                  <div className={styles["switch-under-line"]}></div>
                </div>
              </Col>
              <Col span={8} >
                <div className={styles["switch-item"]} onClick={() => onActive("L04")} active={ active === "L04" ? 'true' : "false"}>
                  <img src={IconIndustry} alt="" srcSet="" {...imageProps} />
                  <span className={styles["switch-item-text"]}><FormattedMessage id="home.news.bars.text3" /></span>
                  <div className={styles["switch-under-line"]}></div>
                </div>
              </Col>
            </Row>
          </div>
          {
            _list.length > 0 && 
            <div className={styles["news-typography"]}>
              <div className={styles["new-cover"]} style={{backgroundImage: `url(${fastDfsUrl(_list[0].homeCover)}`}} onClick={() => window.open(_list[0].articleUrl)} >
              </div>
              <div className={styles["cover-container"]} >
                  <div className={classNames(styles["cover-title"])} >
                    {_list[0].articleTitle}
                  </div>
                  <div style={{display:'flex', marginTop: '-8px'}} >
                    <div className={styles["cover-prefix"]}></div>
                    <div className={styles["cover-content"]} >
                      <div className={styles["cover-content-text"]} >{_list[0].articleIntroduce}</div>
                    </div>
                  </div>
                </div>
            </div>
          }
          <div className={styles["load-more"]} onClick={() => history.push('/news.htm')}>
            <div><span><FormattedMessage id="home.news.list.loadmore" /></span></div>
            <div className={styles["more-icon"]}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(NewsCenter);