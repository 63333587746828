import React, {useState} from 'react';
import classNames from 'classnames';
import styles from './index.m.less';
import { Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import WxMember from '@/assets/img/home/banner/wx-member.png'
import WxB2B from '@/assets/img/home/banner/wx-b2b.png'
import WxXQL from '@/assets/img/wechat-xql.png'
import WxXQLCustomer from '@/assets/img/wechat-xql-customer.png'
import WxGSB from '@/assets/img/wechat-gsb.jpg'
import { Affix } from 'antd';

const Contact = () => {

  const [expand, onExpand] = useState(false);

  const popoverProps = {
    overlayClassName: styles["contact-action-popover"],
    placement: 'left',
    getPopupContainer: triggerNode => triggerNode.parentNode,
    trigger: "hover"
  }

  return (
    <Affix style={{ position: 'absolute', top: 100, right: 10 }} offsetTop={expand ? 100 : 400} >
      <div className={styles.contact}>
        <div className={styles['img-robot']}></div>
        <div className={styles['contact-action']} >
          {
            expand && 
            <div className={styles['action-content']} >
              <div className={styles['action-title']}><FormattedMessage id="home.contact.action1.title" /></div>
              <Popover {...popoverProps}
                content={<div className={styles["hot-line"]}><FormattedMessage id="home.contact.action1.option1.text" /></div>} >
                <div className={styles['action-text']}><FormattedMessage id="home.contact.action1.option1" /></div>
              </Popover>
              <Popover {...popoverProps}
                content={<div className={styles["hot-line"]}><FormattedMessage id="home.contact.action1.option2.text" /></div>} >
                <div className={styles['action-text']}><FormattedMessage id="home.contact.action1.option2" /></div>
              </Popover>
              <Popover {...popoverProps}
                content={<div className={styles["wechat"]}>
                  <img src={WxXQLCustomer} alt="" srcSet=""/>
                  <div className={styles['wechat-text']}><FormattedMessage id="home.contact.action1.option3.text" /></div>
                </div>} >
                <div className={styles['action-text']}><FormattedMessage id="home.contact.action1.option3" /></div>
              </Popover>
              <Popover {...popoverProps}
                content={<div className={styles["wechat"]}>
                  <img src={WxXQL} alt="" srcSet=""/>
                  <div className={styles['wechat-text']}><FormattedMessage id="home.contact.action1.option4.text" /></div>
                </div>} >
                <div className={styles['action-text']}><FormattedMessage id="home.contact.action1.option4" /></div>
              </Popover>
              <div className={styles['action-title']}><FormattedMessage id="home.contact.action2.title" /></div>
              <Popover {...popoverProps}
                content={<div className={styles["wechat"]}>
                  <img src={WxMember} alt="" srcSet=""/>
                  <div className={styles['wechat-text']}><FormattedMessage id="home.contact.action2.option1.text" /></div>
                </div>} >
                <div className={styles['action-text']}><FormattedMessage id="home.contact.action2.option1" /></div>
              </Popover>
              <Popover {...popoverProps}
                content={<div className={styles["wechat"]}>
                  <img src={WxB2B} alt="" srcSet=""/>
                  <div className={styles['wechat-text']}><FormattedMessage id="home.contact.action2.option2.text" /></div>
                </div>} >
                <div className={styles['action-text']}><FormattedMessage id="home.contact.action2.option2" /></div>
              </Popover>
              <Popover {...popoverProps}
                content={<div className={styles["wechat"]}>
                  <img src={WxGSB} alt="" srcSet=""/>
                  <div className={styles['wechat-text']}><FormattedMessage id="home.contact.action2.option3.text" /></div>
                </div>} >
                <div className={styles['action-text']}><FormattedMessage id="home.contact.action2.option3" /></div>
              </Popover>
            </div>
          }
          <div className={styles['action-footer']} onClick={() => onExpand(!expand)}>
            <div>{expand? '收起' : '展开'}</div>
            <div className={classNames(styles['action-arrow'], expand ? '' : styles['action-arrow-down'])}></div>
          </div>
        </div>
      </div>
    </Affix>
    
  );
};

export default Contact;