import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import ImageIntroduce from '@/assets/img/product/lwzf/image-introduce.png';
import ImageBusiness from '@/assets/img/product/lwzf/image-business.png';
import Title from './TitleMobile'


const Service_lwzf = () => {
  return (
    <div className={styles['rz-container']}>
      <div className={styles['rz-service-title']} ><FormattedMessage id="home.product.service.part6" /></div>

      <div className={styles['rz-card']} ><img alt="" src={ImageIntroduce} width="100%" /></div>
      <Title style={{marginTop : 20}} text={<FormattedMessage id="product.rz.introduce.title" />}/>
      <div className={styles['rz-text']} style={{marginTop: '10px'}}><FormattedMessage id="product.lwzf.introduce.text1" /></div>
      <Title style={{marginTop : 20}} text={<FormattedMessage id="product.rz.characteristic.title" />} />
      <div className={styles['rz-text']} style={{marginTop: '10px'}}>
        <span><FormattedMessage id="product.lwzf.characteristic.text1" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.lwzf.characteristic.text2" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.lwzf.characteristic.text3" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span ><FormattedMessage id="product.lwzf.characteristic.text4" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.lwzf.characteristic.text5" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.lwzf.characteristic.text6" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.lwzf.characteristic.text7" /></span>
      </div>

      <div style={{marginTop : 20}} className={styles['rz-card']} ><img alt="" src={ImageBusiness} width="100%" /></div>
      <Title style={{marginTop : 20}} text={<FormattedMessage id="product.business-object" />}/>
      <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.lwzf.business-object.text" /></div>
      <Title text={<FormattedMessage id="product.business-scenario" />} />
      <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.lwzf.business-scenario.text" /></div>
      <Title text={<FormattedMessage id="product.business-service" />}  />
      <div className={styles['rz-text']} style={{marginTop: '10px'}}>
        <div><FormattedMessage id="product.lwzf.business-service.text1" /></div>
        <div><FormattedMessage id="product.lwzf.business-service.text2" /></div>
      </div>

      <div style={{lineHeight: '33px', marginTop : 20}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.rz.case.title1" /></div>
      <div style={{lineHeight: '33px'}} className={styles['rz-case-text']}><FormattedMessage id="product.lwzf.case.text" /></div>
    
    </div>
  );
};

export default Service_lwzf;