import React,{ useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import classNames from 'classnames'
import { Carousel } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Banner01_bg from '@/assets/img/xql-data/banner-mobile-01-background.png';
import Banner01_image from '@/assets/img/xql-data/banner-01-image.png';
import Banner01_Hexagon from '@/assets/img/xql-data/banner-01-hexagon.png';
import Banner01_Shine from '@/assets/img/xql-data/banner-01-shine.png';
import Banner01_Ornament from '@/assets/img/xql-data/banner-01-ornament.png';
import Banner02_bg from '@/assets/img/xql-data/banner-mobile-02-background.png';
import Banner02_image from '@/assets/img/xql-data/banner-02-image.png';
import Banner02_shield from '@/assets/img/xql-data/banner-02-shield.png';
import Banner02_cube from '@/assets/img/xql-data/banner-02-cube.png';
import Banner03_bg from '@/assets/img/xql-data/banner-mobile-03-background.png';
import Banner03_image from '@/assets/img/xql-data/banner-03-image.png';
import Banner03_rocket from '@/assets/img/xql-data/banner-03-rocket.png';
import Banner03_matrix from '@/assets/img/xql-data/banner-03-matrix.png';
import Banner04_bg from '@/assets/img/xql-data/banner-mobile-04-background.png';
import Banner04_image from '@/assets/img/xql-data/banner-04-image.png';
import Banner04_portrait from '@/assets/img/xql-data/banner-04-portrait.png';
import Banner04_eye from '@/assets/img/xql-data/banner-04-eye.png';
import Banner04_cube from '@/assets/img/xql-data/banner-04-cube.png';

const imageStyle = {width: '475px', height: '495px', top: '12%'}

const Mobile = () => {
  const [current, setCurrent] =useState(0);
  const bannerRef = useRef(null);
  return (
    <div className={styles.main} id="banner-start">
      <Carousel  ref={bannerRef} afterChange={(current) => setCurrent(current)} 
      autoplay={false} autoplaySpeed={5000} 
      dots={false} pauseOnHover={false} 
      // beforeChange={() => document.getElementById('banner-start').scrollIntoView({block: 'start',behavior: 'smooth'})}
      >
        <div>
          <div className={styles.banner} style={{backgroundColor: '#0A003F'}}>
            <div className={styles['banner-layer']} 
            style={{background: `url(${Banner01_bg}) no-repeat center center`, backgroundSize: 'cover'}}
            >
              <div className={styles['banner-layer-content']} style={{width: '456px',margin: 'auto'}} >
                <div className={styles['banner-layer-title']}><FormattedMessage id="xqldata.banner01.title" /></div>
                <div className={classNames(styles['banner-layer-text'])}><FormattedMessage id="xqldata.banner01.text" /></div>
              </div>
              <div className={styles['banner-layer-image']} style={imageStyle} >
                <img style={{position: 'absolute',left: '40px', bottom: '0px'}} src={Banner01_image} alt="" srcSet="" width={395} height={408} />
                <img className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '162px', bottom: '235px'}} src={Banner01_Hexagon} alt="" srcSet="" width={150} height={144} />
                <img className={styles['banner-fade-animation']} style={{position: 'absolute',left: '125px', bottom: '80px'}} src={Banner01_Shine} alt="" srcSet="" width={226} height={199} />
                <img style={{position: 'absolute',left: '40px', bottom: '250px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
                <img style={{position: 'absolute',left: '80px', bottom: '150px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
                <img style={{position: 'absolute',right: '40px', bottom: '250px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
                <img style={{position: 'absolute',right: '80px', bottom: '150px'}} src={Banner01_Ornament} alt="" srcSet="" width={30} height={106} />
                <div style={{position: 'absolute', bottom: '0px', height: '420px', left: '80px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
                <div style={{position: 'absolute', bottom: '0px', height: '520px', left: '230px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
                <div style={{position: 'absolute', bottom: '0px', height: '340px', right: '80px'}}><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
              </div>
            </div>
            <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('banner-01-info').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
          </div>
          <div className={classNames(styles['banner-content'], styles.visual)}  id="banner-01-info" >
            <div className={styles['visual-container']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']}>
                  <FormattedMessage id="xqldata.visual.item-01.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.visual.item-01.text" /></div>
                <div className={styles['item-title']} style={{width: '220px', marginTop: '15px'}}>
                  <FormattedMessage id="xqldata.visual.item-02.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.visual.item-02.text" /></div>
                <div className={styles['item-title']}  style={{marginTop: '15px'}}>
                  <FormattedMessage id="xqldata.visual.item-03.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.visual.item-03.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '15px'}}>
                  <FormattedMessage id="xqldata.visual.item-04.title" />
                  <div className={styles['item-title-line']} style={{width: '112px'}}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.visual.item-04.text" /></div>
              </div>
              <div className={styles['visual-image']}></div>
              <div className={styles['visual-arrow']}></div>
              
              <div className={styles['visual-dot-lt']}></div>
              <div className={styles['visual-dot-rb']}></div>
              <div className={styles['visual-dot-rt']}></div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.banner} style={{backgroundColor: '#000000'}}>
            <div className={styles['banner-layer']} style={{background: `url(${Banner02_bg}) no-repeat center center`, backgroundSize: 'cover'}}>
              <div className={styles['banner-layer-content']} style={{width: '456px',margin: 'auto'}} >
                <div className={styles['banner-layer-title']}><FormattedMessage id="xqldata.banner02.title" /></div>
                <div className={classNames(styles['banner-layer-text'])}><FormattedMessage id="xqldata.banner02.text" /></div>
              </div>
              <div className={styles['banner-layer-image']} style={imageStyle} >
                <img style={{position: 'absolute', bottom: '0px'}} src={Banner02_image} alt="" srcSet="" width={'100%'} />
                <img className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '152px', bottom: '195px'}} src={Banner02_shield} alt="" srcSet="" width={176} height={200} />
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '40px', bottom: '420px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.4', filter: 'opacity(50%)'}} src={Banner02_cube} alt="" srcSet="" width={20} height={25} />
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '0px', bottom: '180px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'sepia(10%) opacity(80%)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '120px', bottom: '280px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'brightness(60%)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '150px', bottom: '100px'}}>
                  <img style={{position: 'relative', zIndex: '10', filter: 'blur(0.4px)'}} src={Banner02_cube} alt="" srcSet="" width={40} height={50} />
                  <div style={{position: 'absolute', width: '40px', height: '180px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF7FF)', top: '35px', opacity: '0.4'}}></div>
                </div>

                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '20px', bottom: '260px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'brightness(90%)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '130px', bottom: '300px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'blur(0.6px)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF78A)', top: '20px', opacity: '0.2'}}></div>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '40px', bottom: '120px'}}>
                  <img style={{position: 'relative', zIndex: '10', filter: 'blur(0.6px)'}} src={Banner02_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '160px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #3E9FF7FF)', top: '20px', opacity: '0.4'}}></div>
                </div>
                <div style={{position: 'absolute', bottom: '0px', height: '420px', left: '80px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
                <div style={{position: 'absolute', bottom: '0px', height: '520px', left: '230px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
                <div style={{position: 'absolute', bottom: '0px', height: '340px', right: '80px'}}><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
              </div>
            </div>
            <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('banner-02-info').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
          </div>
          <div className={classNames(styles['banner-content'], styles['risk-warning'])}  id="banner-02-info" >
            <div className={styles['risk-warning-container']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.risk-warning.item-01.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-warning.item-01.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-warning.item-02.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-warning.item-02.text" /></div>
                <div className={styles['item-title']}  style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-warning.item-03.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-warning.item-03.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-warning.item-04.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-warning.item-04.text" /></div>
              </div>
              <div className={styles['risk-warning-image']}></div>
              <div className={styles['risk-warning-arrow']}></div>
              
              <div className={styles['risk-warning-dot-lt']}></div>
              <div className={styles['risk-warning-dot-lb']}></div>
              <div className={styles['risk-warning-dot-rb']}></div>
              <div className={styles['risk-warning-dot-rt']}></div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.banner} style={{backgroundColor: '#000000'}}>
            <div className={styles['banner-layer']} style={{background: `url(${Banner03_bg}) no-repeat center bottom`, backgroundSize: 'contain'}}>
              <div className={styles['banner-layer-content']} style={{width: '456px', margin: 'auto'}} >
                <div className={styles['banner-layer-title']}><FormattedMessage id="xqldata.banner03.title" /></div>
                <div className={classNames(styles['banner-layer-text'])}><FormattedMessage id="xqldata.banner03.text" /></div>
              </div>
              <div className={styles['banner-layer-image']} style={imageStyle} >
                <img style={{position: 'absolute', left: '0px',bottom: '0px'}} src={Banner03_image} alt="" srcSet="" width={'100%'} />
                <img style={{position: 'absolute',left: '178px', bottom: '240px'}} src={Banner03_rocket} alt="" srcSet="" width={112} height={196} />
                <img className={styles['banner-fade-animation']} style={{position: 'absolute', zIndex: '10', left: '200px', bottom: '139px'}} src={Banner03_matrix} alt="" srcSet="" width={73} height={56} />

                <QueueAnim animConfig={[{ opacity:[1, 0] }]} delay={1000} interval={500}>
                  <div key="line-left-01" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '330px', bottom: '0px'}} ></div>
                  <div key="line-middle" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '245px', bottom: '0px'}} ></div>
                  <div key="line-right-01" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '90px', bottom: '0px'}} ></div>
                  <div key="line-right-02" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '120px', bottom: '0px'}} ></div>
                  <div key="line-left-02" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '400px', bottom: '0px'}} ></div>
                  <div key="line-left-03" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '360px', bottom: '0px'}} ></div>
                  <div key="line-right-03" className={styles['rocket-line-animation']}  style={{position: 'absolute',right: '150px', bottom: '0px'}} ></div>
                </QueueAnim>
                <QueueAnim animConfig={[{ opacity:[1, 0] }]} delay={100} interval={800}>
                  <div key="dot-01" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '5px', height: '5px',right: '230px', bottom: '120px'}} ></div>
                  <div key="dot-02" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '8px', height: '8px',right: '250px', bottom: '120px'}} ></div>
                  <div key="dot-03" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '6px', height: '6px',right: '240px', bottom: '120px'}} ></div>
                  <div key="dot-04" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '10px', height: '10px',right: '215px', bottom: '120px'}} ></div>
                  <div key="dot-05" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '8px', height: '8px',right: '240px', bottom: '120px'}} ></div>
                  <div key="dot-06" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '12px', height: '12px',right: '220px', bottom: '120px'}} ></div>
                  <div key="dot-07" className={styles['light-dot-animation']}  style={{position: 'absolute', width: '9px', height: '9px',right: '230px', bottom: '120px'}} ></div>
                </QueueAnim>
              </div>
            </div>
            <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('banner-03-info').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
          </div>
          <div className={classNames(styles['banner-content'], styles['risk-control'])}  id="banner-03-info" >
            <div className={styles['risk-control-container']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']} style={{width: '150px'}}>
                  <FormattedMessage id="xqldata.risk-control.item-01.title" />
                  <div className={styles['item-title-line']} style={{width: '75px'}}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-control.item-01.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '30px', width: '185px'}}>
                  <FormattedMessage id="xqldata.risk-control.item-02.title" />
                  <div className={styles['item-title-line']} style={{width: '110px'}}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-control.item-02.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '30px'}}>
                  <FormattedMessage id="xqldata.risk-control.item-03.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-control.item-03.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '30px'}}>
                  <FormattedMessage id="xqldata.risk-control.item-04.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '30px'}}><FormattedMessage id="xqldata.risk-control.item-04.text" /></div>
              </div>
              <div className={styles['risk-control-image']}></div>

              <div className={styles['risk-control-arrow']}></div>
              <div className={styles['risk-control-dot-left']}></div>
              <div className={styles['risk-control-dot-center']}></div>
              <div className={styles['risk-control-dot-right']}></div>
              <div className={styles['risk-control-dot-top']}></div>
            </div>
          </div>
        </div>
        <div>
        <div className={styles.banner} style={{backgroundColor: '#0A003F'}}>
            <div className={styles['banner-layer']} style={{background: `url(${Banner04_bg}) no-repeat center center`, backgroundSize: 'cover'}}>
              <div className={styles['banner-layer-content']} style={{width: '456px',margin: 'auto'}} >
                <div className={styles['banner-layer-title']}><FormattedMessage id="xqldata.banner04.title" /></div>
                <div className={classNames(styles['banner-layer-text'])}><FormattedMessage id="xqldata.banner04.text" /></div>
              </div>
              <div className={styles['banner-layer-image']} style={imageStyle} >
                <img style={{position: 'absolute', bottom: '0px'}} src={Banner04_image} alt="" srcSet="" width={'100%'} />
                <div style={{position: 'absolute',left: '20px', bottom: '130px'}} className={styles['banner-fade-animation-slow']}  >
                  <img style={{position: 'relative', zIndex: 20}}  src={Banner04_portrait} alt="" srcSet="" width={350} height={272} />
                  <img style={{position: 'absolute',left: '-58px', bottom: '-63px', zIndex: 10}} className={styles['banner-fade-animation']}  src={Banner04_eye} alt="" srcSet=""/>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '57px', bottom: '340px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.4', filter: 'opacity(50%)'}} src={Banner04_cube} alt="" srcSet="" width={20} height={25} />
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',left: '40px', bottom: '180px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'sepia(10%) opacity(80%)'}} src={Banner04_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #54468aFF)', top: '20px', opacity: '0.2'}}></div>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '20px', bottom: '360px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'brightness(90%)'}} src={Banner04_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #54468aFF)', top: '20px', opacity: '0.2'}}></div>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '100px', bottom: '400px'}}>
                  <img style={{position: 'relative', zIndex: '10', opacity: '0.6', filter: 'blur(0.6px)'}} src={Banner04_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '120px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #54468aFF)', top: '20px', opacity: '0.2'}}></div>
                </div>
                <div className={styles['banner-up-down-animation']} style={{position: 'absolute',right: '40px', bottom: '280px'}}>
                  <img style={{position: 'relative', zIndex: '10', filter: 'blur(0.6px)'}} src={Banner04_cube} alt="" srcSet="" width={25} height={30} />
                  <div style={{position: 'absolute', width: '25px', height: '160px', backgroundImage: 'linear-gradient(to top, #FFFFFF00, #54468aFF)', top: '20px', opacity: '0.4'}}></div>
                </div>

                <div style={{position: 'absolute', bottom: '0px', height: '420px', left: '80px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
                <div style={{position: 'absolute', bottom: '0px', height: '520px', left: '230px'}} ><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
                <div style={{position: 'absolute', bottom: '0px', height: '340px', right: '80px'}}><div className={styles['banner-line-animation']} style={{backgroundImage: 'linear-gradient(to top, #FFFFFF00, #00DBB4FF)'}}></div></div>
              </div>
            </div>
            <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('banner-04-info').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
          </div>

          <div className={classNames(styles['banner-content'], styles['artificial-intelligence'])}  id="banner-04-info" >
            <div className={styles['artificial-intelligence-container']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']}>
                  <FormattedMessage id="xqldata.artificial-intelligence.item-01.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '15px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-01.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '15px'}}>
                  <FormattedMessage id="xqldata.artificial-intelligence.item-02.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '15px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-02.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '15px', width: '185px'}}>
                  <FormattedMessage id="xqldata.artificial-intelligence.item-03.title" />
                  <div className={styles['item-title-line']} style={{width: '110px'}} ></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '15px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-03.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '15px', width: '220px'}}>
                  <FormattedMessage id="xqldata.artificial-intelligence.item-04.title" />
                  <div className={styles['item-title-line']} style={{width: '140px'}} ></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '15px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-04.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '15px', width: '185px'}}>
                  <FormattedMessage id="xqldata.artificial-intelligence.item-05.title" />
                  <div className={styles['item-title-line']} style={{width: '110px'}} ></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '15px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-05.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '15px'}}>
                  <FormattedMessage id="xqldata.artificial-intelligence.item-06.title" />
                  <div className={styles['item-title-line']}></div>
                </div>
                <div className={styles['item-text']} style={{marginTop: '15px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-06.text" /></div>
              </div>
              <div className={styles['artificial-intelligence-image']}></div>
              <div className={styles['artificial-intelligence-arrow']}></div>
              <div className={styles['artificial-intelligence-dot-rc']}></div>
              <div className={styles['artificial-intelligence-dot-lb']}></div>
              <div className={styles['artificial-intelligence-dot-rb']}></div>
              <div className={styles['artificial-intelligence-dot-lt']}></div>
              <div className={styles['artificial-intelligence-dot-lc']}></div>
            </div>
          </div>
        </div>
      </Carousel>
      {
        current!==0 ? <div className={styles['icon-left-arrow']} onClick={() => bannerRef.current.slick.slickGoTo(current>0? current-1 : 0 )} ></div>
        : <div className={styles['icon-left-arrow-disabled']}></div>
      }
      {
        current!==3 ? <div className={styles['icon-right-arrow']} onClick={() => bannerRef.current.slick.slickGoTo(current< 3 ? current+1 : 3)} ></div>
        : <div className={styles['icon-right-arrow-disabled']}></div>
      } 
    </div>
  );
};

export default Mobile;