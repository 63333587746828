import React from 'react';
import classNames from 'classnames'
import { ElfCard } from 'elf-ui'
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import ImageIntroduce from '@/assets/img/product/lwzf/image-introduce.png';
import ImageBusiness from '@/assets/img/product/lwzf/image-business.png';
import ImageCase from '@/assets/img/product/lwzf/image-case.png';
import Title from './Title'

const { Meta } = ElfCard;

const Service_lwzf = () => {
  return (
    <div className={styles['rz-container']}>
      <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageIntroduce} />} coverStyle={{left: '20px'}} bodyClassName={styles['rz-card-lg']}>
        <Meta style={{marginTop: '-15px', paddingRight: '10px', paddingLeft: '25px'}}>
          <Title text={<FormattedMessage id="product.rz.introduce.title" />}/>
          <div className={styles['rz-text']} style={{marginTop: '10px', marginBottom: '5px'}}><FormattedMessage id="product.lwzf.introduce.text1" /></div>
          <Title text={<FormattedMessage id="product.rz.characteristic.title" />} style={{marginBottom: '5px'}}/>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.lwzf.characteristic.text1" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.lwzf.characteristic.text2" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.lwzf.characteristic.text3" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span ><FormattedMessage id="product.lwzf.characteristic.text4" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.lwzf.characteristic.text5" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.lwzf.characteristic.text6" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.lwzf.characteristic.text7" /></span>
          </div>
        </Meta>
      </ElfCard>
      <ElfCard offset={{right: '345px'}} bodyOffset={{right: '300px'}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={ImageBusiness} />} coverStyle={{right: '0px'}} style={{marginTop: 30}} 
      >
        <Meta style={{paddingRight: '10px'}}>
          <Title text={<FormattedMessage id="product.business-object" />}/>
          <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.lwzf.business-object.text" /></div>
          <Title text={<FormattedMessage id="product.business-scenario" />} />
          <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.lwzf.business-scenario.text" /></div>
          <Title text={<FormattedMessage id="product.business-service" />}  />
          <div className={styles['rz-text']} style={{marginTop: '10px'}}>
            <div><FormattedMessage id="product.lwzf.business-service.text1" /></div>
            <div><FormattedMessage id="product.lwzf.business-service.text2" /></div>
          </div>
        </Meta>
      </ElfCard>
      
      <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageCase} />} coverStyle={{left: '32px', width: '600px'}} bodyClassName={styles['rz-card-lg']} style={{marginTop: 30}} >
        <Meta style={{paddingRight: '10px', paddingLeft: '25px'}}>
          <div style={{lineHeight: '33px'}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.rz.case.title1" /></div>
          <div style={{lineHeight: '33px'}} className={styles['rz-case-text']}><FormattedMessage id="product.lwzf.case.text" /></div>
        </Meta>
      </ElfCard>
    </div>
  );
};

export default Service_lwzf;