/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {connect} from 'dva'
import Media from 'react-media';
import { FormattedMessage } from 'react-intl';
import styles from './header.m.less'
import IndexMobile from 'pages/header/HeaderMobile'

import {ElfMenu} from 'elf-ui';

const Header = props => {
  const {fixed, history, location: {pathname}} = props;
  const gotoRouter = (path) => {
    history.push({pathname: path});
  }

  const menu =[
    {
      key: 'index.htm',
      title: '首页',
      children: []
    },
    {
      key: 'xql.htm',
      title: '薪企链',
      link: 'https://xql.51xinhai.com/enym',
      children: []
    },

    {
      key: '/xql-data.htm',
      title: '薪企数据',
      children: []
    },
    {
      key: 'cooperate.htm',
      title: '渠道合作',
      children: []
    },
    {
      key: '/product.htm',
      title: '产品服务',
      children: [
        {key : 'gzdz', title: <FormattedMessage id="home.product.service.gzdz" />},
        {key : 'zngs', title: <FormattedMessage id="home.product.service.part3" />},
        {key : 'qyxy', title: <FormattedMessage id="home.product.service.qyxy" />},
        {key : 'bxdl', title: <FormattedMessage id="home.product.service.part4" />},
        {key : 'htqy', title: <FormattedMessage id="home.product.service.part5" />},
        {key : 'lwzf', title: <FormattedMessage id="home.product.service.part6" />},
        {key : 'b2b', title: <FormattedMessage id="home.product.service.b2b" />},
        {key : 'oes', title: <FormattedMessage id="home.product.service.oes" />},
      ]
    },
    {
      key: '/news.htm',
      title: '新闻中心',
      children: []
    },
    {
      key: '/company.htm',
      title: '关于我们',
      children: []
    }
  ]
  
  const selectPath = pathname.split('/').filter(r => r);
  return (
      <div className={styles.main} style={ fixed ? {position : 'fixed' } : {position : 'absolute' }}>
          <div className={styles.header}>
            <div className={styles.logo}></div>
            <ElfMenu defaultActive={[pathname]} selectPath={selectPath} menu={menu} onClick={(key) => gotoRouter(key)} />
          </div>
      </div>
  );
};


const HeaderMibole = props => {
  const {fixed} = props;
  
  return (
      <div className={styles.mobile} style={ fixed ? {position : 'fixed' } : {position : 'absolute' }}>
          <IndexMobile {...props} />
      </div>
  );
};

export default connect()(props => (
  <Media query="(max-width: 999px)">
    {isMobile => isMobile ? <HeaderMibole {...props} /> : <Header {...props} isMobile={isMobile} />}
  </Media>
));