import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
import Service_gzdz from './Service_gzdz_mobile'
import Service_zngs from './Service_zngs_mobile'
import Service_bxdl from './Service_bxdl_mobile'
import Service_htqy from './Service_htqy_mobile'
import Service_lwzf from './Service_lwzf_mobile'
import Service_rzch from './Service_rzch_mobile'
import Service_qyxy from './Service_qyxy_mobile'
import Service_oes from './Service_oes_mobile'

const ProductRouter = () => {
  return (
    <Switch>
      <Route exact path="/product.htm/gzdz" component= {Service_gzdz}/>
      <Route exact path="/product.htm/zngs" component= {Service_zngs}/>
      <Route exact path="/product.htm/bxdl" component= {Service_bxdl}/>
      <Route exact path="/product.htm/htqy" component= {Service_htqy}/>
      <Route exact path="/product.htm/lwzf" component= {Service_lwzf}/>
      <Route exact path="/product.htm/b2b" component= {Service_rzch}/>
      <Route exact path="/product.htm/qyxy" component= {Service_qyxy}/>
      <Route exact path="/product.htm/oes" component= {Service_oes}/>
      <Redirect to="/product.htm/gzdz" />
    </Switch>
  );
};

export default ProductRouter;