import home from './zh-CN/home'
import system from './zh-CN/default'
import company from './zh-CN/company'
import product from './zh-CN/product'
import news from './zh-CN/news'
import cooperate from './zh-CN/cooperate'
import recruit from './zh-CN/recruit'
import xqldata from './zh-CN/xqldata'

export default {
    ...system,
    ...home,
    ...company,
    ...product,
    ...news,
    ...cooperate,
    ...recruit,
    ...xqldata
};
  