import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.m.less'

const Grid = ( {columns=3 , gutter=27, components, onRow, children=[]}) => {
  let gutters = []
  if(!Array.isArray(gutter)){
    gutters=[Number(gutter), Number(gutter)]
  }else{
    gutters= gutter;
  }
  let _childs = [];
  if(Array.isArray(children)){
    children.forEach((cid, k) => {
      if(k%columns ===0){
        _childs.push([cid])
      }else{
        _childs[_childs.length-1].push(cid)
      }
    })
  }else{
    _childs.push([children])
  }
  
  const renderList = _childs.map((row, k) => {
    // const _return = <ul key={k} style={{marginBottom: gutters[1]}}>
    //   {
    //     row.map((item, index) => {
    //       const _childCol = onRow? onRow(<div>{item}</div>, index) : <div>{item}</div>;
    //       if(index < row.length-1){
    //         return <React.Fragment key={index} >
    //         <li style={{
    //           // flex: '1'
    //         }} 
    //           >
    //           <div>{_childCol}</div>
    //         </li><li style={{width: `${gutters[0]}px`, }} 
    //           >
    //         </li></React.Fragment>;
    //       }else{
    //         return <li key={index} style={{flex: '1'}} >
    //           <div>{_childCol}</div>
    //         </li>;
    //       }
    //     })
    //   }
    // </ul>

    const _return = row.map((item, index) => {
          if(index < row.length-1){
            return < span key={index} >
            <li style={{
              // flex: '1'
            }} 
              >
              <div>{item}</div>
            </li><li style={{width: `${gutters[0]}px`, }} 
              >
            </li></span>;
          }else{
            return <li key={index} style={{flex: '1'}} >
              <div>{item}</div>
            </li>;
          }
        })
    return <ul key={k} style={{marginBottom: gutters[1]}}>{onRow? onRow(_return) : _return}</ul>;
  });
  return (
    <div className={styles.grid}>
      { components ? components(renderList) : renderList}
    </div>
  );
};

Grid.propTypes = {
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  columns: PropTypes.number,
};

export default Grid;