import React from 'react';
import {connect} from 'dva'
import Media from 'react-media';
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import Mobile from './Mobile'
import Banner from './Banner'
import VisualImage from '@/assets/img/xql-data/visual-image.png';
import RiskWarningImage from '@/assets/img/xql-data/risk-warning-image.png';
import RiskControlImage from '@/assets/img/xql-data/risk-control-image.png';
import ArtificialIntelligenceImage from '@/assets/img/xql-data/artificial-intelligence-image.png';

const Index = () => {
  return (
    <div className={styles.main}>
      <Banner />
      <div className={styles.visual} >
        <div className={styles['visual-container']}>
          <div className={styles['main']}>
            <div className={styles['visual-title']}><FormattedMessage id="xqldata.visual.title" /></div>
            <div className={styles['visual-content']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.visual.item-01.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.visual.item-01.text" /></div>
                <div className={styles['item-title']} style={{width: '216px', marginTop: '70px'}}><FormattedMessage id="xqldata.visual.item-02.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.visual.item-02.text" /></div>
              </div>
              <img src={VisualImage} alt="" srcSet="" width={472} height={489} />
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.visual.item-03.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.visual.item-03.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '70px'}}><FormattedMessage id="xqldata.visual.item-04.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.visual.item-04.text" /></div>
              </div>
            </div>
          </div>
          <div className={styles['visual-arrow']}></div>
          <div className={styles['visual-dot-lt']}></div>
          <div className={styles['visual-dot-rt']}></div>
          <div className={styles['visual-dot-rc']}></div>
          <div className={styles['visual-dot-rb']}></div>
        </div>
      </div>
      <div className={styles['risk-warning']}>
        <div className={styles['risk-warning-container']}>
          <div className={styles['main']}>
            <div className={styles['risk-warning-title']}><FormattedMessage id="xqldata.risk-warning.title" /></div>
            <div className={styles['risk-warning-content']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.risk-warning.item-01.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-warning.item-01.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '52px'}}><FormattedMessage id="xqldata.risk-warning.item-02.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-warning.item-02.text" /></div>
              </div>
              <img src={RiskWarningImage} alt="" srcSet="" width={524} height={468} />
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.risk-warning.item-03.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-warning.item-03.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '104px'}}><FormattedMessage id="xqldata.risk-warning.item-04.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-warning.item-04.text" /></div>
              </div>
            </div>
          </div>
          <div className={styles['risk-warning-arrow']}></div>
          <div className={styles['risk-warning-dot-lt']}></div>
          <div className={styles['risk-warning-dot-lb']}></div>
          <div className={styles['risk-warning-dot-rb']}></div>
        </div>
      </div>
      <div className={styles['risk-control']}>
        <div className={styles['risk-control-container']}>
          <div className={styles['main']}>
            <div className={styles['risk-control-title']}><FormattedMessage id="xqldata.risk-control.title" /></div>
            <div className={styles['risk-control-content']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.risk-control.item-01.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-control.item-01.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '78px'}}><FormattedMessage id="xqldata.risk-control.item-02.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-control.item-02.text" /></div>
              </div>
              <img src={RiskControlImage} alt="" srcSet="" width={542} height={476} />
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.risk-control.item-03.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-control.item-03.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '52px'}}><FormattedMessage id="xqldata.risk-control.item-04.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '32px'}}><FormattedMessage id="xqldata.risk-control.item-04.text" /></div>
              </div>
            </div>
          </div>
          <div className={styles['risk-control-arrow']}></div>
          <div className={styles['risk-control-dot-left']}></div>
          <div className={styles['risk-control-dot-center']}></div>
          <div className={styles['risk-control-dot-right']}></div>
        </div>
      </div>
      <div className={styles['artificial-intelligence']}>
        <div className={styles['artificial-intelligence-container']}>
          <div className={styles['main']}>
            <div className={styles['artificial-intelligence-title']}><FormattedMessage id="xqldata.artificial-intelligence.title" /></div>
            <div className={styles['artificial-intelligence-content']}>
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.artificial-intelligence.item-01.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-01.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '20px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-02.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-02.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '20px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-03.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-03.text" /></div>
              </div>
              <img src={ArtificialIntelligenceImage} alt="" srcSet="" width={600} height={530} />
              <div className={styles['item-content']}>
                <div className={styles['item-title']}><FormattedMessage id="xqldata.artificial-intelligence.item-04.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-04.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '20px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-05.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-05.text" /></div>
                <div className={styles['item-title']} style={{marginTop: '20px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-06.title" /></div>
                <div className={styles['item-text']} style={{marginTop: '37px'}}><FormattedMessage id="xqldata.artificial-intelligence.item-06.text" /></div>
              </div>
            </div>
          </div>
          <div className={styles['artificial-intelligence-arrow']}></div>
          <div className={styles['artificial-intelligence-dot-lt']}></div>
          <div className={styles['artificial-intelligence-dot-lb']}></div>
          <div className={styles['artificial-intelligence-dot-r']}></div>
        </div>
      </div>
    </div>
  );
};

export default connect()(props => (
  <Media query="(max-width: 999px)">
    {isMobile => isMobile ? <Mobile {...props} /> : <Index {...props} />}
  </Media>
));