import React, {useState, useEffect} from 'react';
import classNames from 'classnames'
import {connect} from 'dva'
import * as echarts from "echarts";
import styles from './index.m.less';
import { FormattedMessage } from 'react-intl';
import { OverPack } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import IconMoney from '@/assets/img/home/industry/icon-money.png';
import IconShop from '@/assets/img/home/industry/icon-shop.png';
import IconCompany from '@/assets/img/home/industry/icon-company.png';
import IconAgent from '@/assets/img/home/industry/icon-agent.png';

TweenOne.plugins.push(Children);

const keyMap ={
  'chart-data-service': 'IS_BAR_004',
  'chart-data-company': 'IS_BAR_005',
  'chart-data-shop': 'IS_BAR_006',
  'chart-data-agent': 'IS_BAR_007'
}

const Industry = ({dispatch, statistic, industryEndDate}) => {
  const lgText = {fontSize: 36, lineHeight: '46px', fontWeight: 700};
  console.log(statistic)

  const [current, setCurrent] = useState('');
  useEffect(()=> {
    dispatch({
      type: 'home/industryStatistic',
      payload: {},
    })
  },[dispatch])

  useEffect(()=> {
    if(current){
      let chartElement = document.getElementById(`chart-data-${current}`);
      const _data = statistic[keyMap[`chart-data-${current}`]];
      if(chartElement && _data){
        let dataChart = echarts.init(chartElement);
        let option ={
          grid: {
            left:45,
            right: 15,
            top: 45,
            bottom: 20
          },
          xAxis: {
            type: 'category',
            data: _data.list.map(_it => _it.xValue),
            axisLine: {
              lineStyle: {
                color: '#32C5FFFF'
              }
            },
            axisLabel: {
              interval:'0',
              rotate: 40,
              fontSize: 8,
              color: '#FFFFFF'
            }
          },
          yAxis: {
            name: `{i|}{t|年度业务}{u|单位：${_data.yUnit}}`,
            nameGap: 25,
            nameTextStyle: {
              align: 'left',
              padding: [0, 0, 0, -20],
              rich: {
                i: {
                  width: 10,
                  height: 10,
                  backgroundColor: '#47E8FF'
                },
                t: {
                  width: 100,
                  fontSize: 9,
                  padding: [3, 0, 0, 10],
                  color: '#FFFFFF',
                },
                u: {
                  color: '#FFFFFF',
                  fontSize: 9,
                  padding: [3, 0, 0, 0],
                },
              }
            },
            type: 'value',
            interval: Number(_data.yInterval),
            axisLine: {
              lineStyle: {
                color: '#FFFFFF'
              }
            },
            axisLabel: {
              fontSize: 8,
            },
            splitLine: {
              lineStyle:{
                color: 'rgba(50, 197, 255, 0.3)'
              }
            }, 
          },
          series: [
            {
              data: _data.list.map(_it => _it.yValue),
              type: 'bar',
              barWidth : 10,//柱图宽度
              itemStyle: {
                normal: {
                  //数值显示
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: '#fff',
                      fontSize: 8
                    }
                  },
                  //颜色渐变
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#47E8FF'
                  }, {
                    offset: 1,
                    color: '#487AFF'
                  }])
                }
              },
            },
          ]
        }
        dataChart.setOption(option);
      }
    }
  },[current, statistic])

  const onExpandChart = (key) => {
    setCurrent(key);
    
  }

  const onCloseChart = () => {
    setCurrent('')
  }

  return (
    <div className={styles.industry}>
      <div className={styles['industry-layout']}>
        <div className={styles.title}></div>
        <div className={styles.content}>
          <span ><FormattedMessage id="home.industry.content.text1" /></span>
          <span className="font-blod"><FormattedMessage id="home.industry.content.text2" /></span>
          <span ><FormattedMessage id="home.industry.content.text3" /></span>
          <span className="font-blod"><FormattedMessage id="home.industry.content.text4" /></span>
        </div>
        <OverPack  playScale={[0.2, 0.2]} >
          <div className={styles["data-layer"]}>
            <div className={styles["data-layer-collect"]}>
              <div className={styles["data-collect"]}>
                <div className={styles["data-collect-title"]}>薪海数据库内用工企业数</div>
                {
                  statistic['IS_COUNT_001'] && 
                  <div className={styles["data-collect-content"]}>
                    <TweenOne
                      animation={{
                        Children: { 
                          value: Number(statistic['IS_COUNT_001'].value), 
                          floatLength: 0,
                        }, 
                        duration: 3000,
                      }}
                    >0</TweenOne>
                  </div>
                }
              </div>
              <div className={styles["data-collect-lg"]}>
                <div className={styles["data-collect-title"]}>薪企链平台 B2B用工匹配交易量</div>
                {
                  statistic['IS_COUNT_002'] && 
                  <div className={styles["data-collect-content"]}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'baseline'}} >
                      <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_COUNT_002'].value), 
                            floatLength: 0
                          }, 
                          duration: 3000,
                        }}
                        style={{
                          paddingLeft: 64
                        }}
                      >0</TweenOne>
                      <div className={styles["data-collect-content-unit"]}>{statistic['IS_COUNT_002'].unit}</div>
                    </div>
                    
                  </div>
                }
              </div>
              <div className={styles["data-collect"]}>
                <div className={styles["data-collect-title"]}>薪海数据库内人资企业数</div>
                {
                  statistic['IS_COUNT_003'] && 
                  <div className={styles["data-collect-content"]}>
                    <TweenOne
                      animation={{
                        Children: { 
                          value: statistic['IS_COUNT_003'] && Number(statistic['IS_COUNT_003'].value), 
                          floatLength: 0,
                        }, 
                        duration: 3000,
                      }}
                    >0</TweenOne>
                  </div>
                }
              </div>

              <div className={styles["data-collect"]}>
                <div className={styles["data-collect-title"]}>薪海数据库内职业院校</div>
                {
                  statistic['IS_COUNT_002'] && 
                  <div className={styles["data-collect-content"]}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'baseline'}} >
                      <TweenOne
                        animation={{
                          Children: { 
                            value: statistic['IS_COUNT_008'] && Number(statistic['IS_COUNT_008'].value), 
                            floatLength: 0
                          }, 
                          duration: 3000,
                        }}
                        style={{
                          paddingLeft: 48
                        }}
                      >0</TweenOne>
                      <div className={styles["data-collect-content-unit"]}>{statistic['IS_COUNT_008'].unit}</div>
                    </div>
                    
                  </div>
                }
              </div>
            </div>
            <div className={styles["chart-layer"]}>
            <QueueAnim type={"scale"} duration={80} interval={80}>
              <div key="cube-1" className={styles["icon-cube"]} style={{width: 70, height: 70, left: 110, top: 200, zIndex: 30}} ></div>
              <div key="cube-5" className={styles["icon-cube"]} style={{width: 140, height: 140, left: 225, top: 200, zIndex: 25}} ></div>
              <div key="cube-3" className={styles["icon-cube"]} style={{width: 150, height: 150, left: 225, top: 115, zIndex: 25}} ></div>
              <div key="cube-10" className={styles["icon-cube"]} style={{width: 90, height: 90, left: 360, top: 165, zIndex: 25}} ></div>
              <div key="cube-4" className={styles["icon-cube"]} style={{width: 130, height: 130, left: 300, top: 170, zIndex: 25}} ></div>
              <div key="cube-7" className={styles["icon-cube"]} style={{width: 160, height: 160, left: 185, top: 285, zIndex: 20}} ></div>
              <div key="cube-22" className={styles["icon-cube"]} style={{width: 100, height: 100, left: 300, top: 365, zIndex: 15}} ></div>
              <div key="cube-8" className={styles["icon-cube"]} style={{width: 120, height: 120, left: 125, top: 330, zIndex: 30}} ></div>
              <div key="cube-12" className={styles["icon-cube"]} style={{width: 70, height: 70, left: 370, top: 230, zIndex: 25}} ></div>
              <div key="cube-17" className={styles["icon-cube"]} style={{width: 90, height: 90, left: 440, top: 210, zIndex: 20}} ></div>
              <div key="cube-13" className={styles["icon-cube"]} style={{width: 120, height: 120, left: 315, top: 240, zIndex: 24}} ></div>
              <div key="cube-14" className={styles["icon-cube"]} style={{width: 120, height: 120, left: 260, top: 260, zIndex: 18}} ></div>
              <div key="cube-16" className={styles["icon-cube"]} style={{width: 120, height: 120, left: 380, top: 230, zIndex: 25}} ></div>
              <div key="cube-20" className={styles["icon-cube"]} style={{width: 110, height: 110, left: 400, top: 305, zIndex: 25}} ></div>
              <div key="cube-15" className={styles["icon-cube"]} style={{width: 120, height: 120, left: 205, top: 345, zIndex: 18}} ></div>
              <div key="cube-18" className={styles["icon-cube"]} style={{width: 110, height: 110, left: 280, top: 330, zIndex: 18}} ></div>
              <div key="cube-23" className={styles["icon-cube"]} style={{width: 100, height: 100, left: 355, top: 300, zIndex: 15}} ></div>
              <div key="cube-21" className={styles["icon-cube"]} style={{width: 100, height: 100, left: 355, top: 355, zIndex: 18}} ></div>
              <div key="cube-11" className={styles["icon-cube"]} style={{width: 90, height: 90, left: 400, top: 130, zIndex: 25}} ></div>
              <div key="chart-1" className={classNames(styles["chart-view"], styles["chart-view-service"], current ==='service' ? styles["chart-view-show"] : '')} >
                <div className={styles['chart-view-title']} >薪企链平台应收账款 转让服务量</div>
                {
                  statistic['IS_BAR_004'] && 
                  <div className={styles['chart-view-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_004'].value),
                            floatLength: 0,
                            formatMoney: true
                          }, 
                          duration: 500,
                        }}
                        style={lgText}
                      >0</TweenOne>
                    <span style={{marginLeft: 10}}>{statistic['IS_BAR_004'].unit}</span>
                  </div>
                }
                {
                  current ==='service' && <div id="chart-data-service" className={styles['chart-data']} ></div>
                }
                <div className={styles['chart-view-action']} onClick={() => current ==='service' ?onCloseChart('') : onExpandChart('service')} >
                  <div>{current ==='service'? '收起': '展开数据图表' }</div>
                  <div className={current ==='service' ? styles['action-hidden'] : styles['action-show']} ></div>
                </div>
              </div>
              <div key="chart-2" className={classNames(styles["chart-view"], styles["chart-view-shop"], current ==='shop' ? styles["chart-view-show"] : '')} >
                <div className={styles['chart-view-title']} >入驻薪企链平台二手单 (含门店)人资企业数</div>
                {
                  statistic['IS_BAR_006'] && 
                  <div className={styles['chart-view-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_006'].value),
                            floatLength: 0,
                          }, 
                          duration: 500,
                        }}
                        style={lgText}
                      >0</TweenOne>
                    <span style={{marginLeft: 10}}>{statistic['IS_BAR_006'].unit}</span>
                  </div>
                }
                {
                  current ==='shop' && <div id="chart-data-shop" className={styles['chart-data']} >数据内容</div>
                }
                <div className={styles['chart-view-action']} onClick={() => current ==='shop' ?onCloseChart('') : onExpandChart('shop')} >
                  <div>{current ==='shop'? '收起': '展开数据图表' }</div>
                  <div className={current ==='shop' ? styles['action-hidden'] : styles['action-show']} ></div>
                </div>
              </div>
              <div key="chart-3" className={classNames(styles["chart-view"], styles["chart-view-company"], current ==='company' ? styles["chart-view-show"] : '')} >
                <div className={styles['chart-view-title']} >入驻薪企链平台人资 企业数</div>
                {
                  statistic['IS_BAR_005'] && 
                  <div className={styles['chart-view-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_005'].value),
                            floatLength: 0,
                          }, 
                          duration: 500,
                        }}
                        style={lgText}
                      >0</TweenOne>
                    <span style={{marginLeft: 10}}>{statistic['IS_BAR_005'].unit}</span>
                  </div>
                }
                {
                  current ==='company' && <div id="chart-data-company" className={styles['chart-data']} >数据内容</div>
                }
                <div className={styles['chart-view-action']} onClick={() => current ==='company' ?onCloseChart('') : onExpandChart('company')} >
                  <div>{current ==='company'? '收起': '展开数据图表' }</div>
                  <div className={current ==='company' ? styles['action-hidden'] : styles['action-show']} ></div>
                </div>
              </div>
              <div key="chart-4" className={classNames(styles["chart-view"], styles["chart-view-agent"], current ==='agent' ? styles["chart-view-show"] : '')} >
                <div className={styles['chart-view-title']} >入驻薪企链平台 经纪人数</div>
                {
                  statistic['IS_BAR_007'] && 
                  <div className={styles['chart-view-text']} >
                    <TweenOne
                        animation={{
                          Children: { 
                            value: Number(statistic['IS_BAR_007'].value),
                            floatLength: 0,
                          }, 
                          duration: 500,
                        }}
                        style={lgText}
                      >0</TweenOne>
                    <span style={{marginLeft: 10}}>{statistic['IS_BAR_007'].unit}</span>
                  </div>
                }
                {
                  current ==='agent' && <div id="chart-data-agent" className={styles['chart-data']} >数据内容</div>
                }
                <div className={styles['chart-view-action']} onClick={() => current ==='agent' ?onCloseChart('') : onExpandChart('agent')} >
                  <div>{current ==='agent'? '收起': '展开数据图表' }</div>
                  <div className={current ==='agent' ? styles['action-hidden'] : styles['action-show']} ></div>
                </div>
              </div>
              <div key="cube-2" className={classNames(styles["icon-cube"], styles['hover-link'])} style={{width: 150, height: 150, left: 150, top: 65, zIndex: 30}} 
              onMouseEnter={() => onExpandChart('service')}
              onMouseLeave={() => onCloseChart('')} 
              >
                <img src={IconMoney} alt="" style={{position: 'absolute', left: 37, top: 36}} width={25} />
              </div>
              <div key="cube-6" className={classNames(styles["icon-cube"], styles['hover-link'])} style={{width: 100, height: 100, left: 170, top: 240, zIndex: 30}} 
              onMouseEnter={() => onExpandChart('shop')}
              onMouseLeave={() => onCloseChart('')} 
              >
                <img src={IconShop} alt="" style={{position: 'absolute', left: 24, top: 26}} width={20} />
              </div>
              <div key="cube-9" className={classNames(styles["icon-cube"], styles['hover-link'])} style={{width: 120, height: 120, left: 315, top: 70, zIndex: 30}} 
              onMouseEnter={() => onExpandChart('company')}
              onMouseLeave={() => onCloseChart('')} 
              >
                <img src={IconCompany} alt="" style={{position: 'absolute', left: 31, top: 36}} width={20} />
              </div>
              <div key="cube-19" className={classNames(styles["icon-cube"], styles['hover-link'])} style={{width: 180, height: 180, left: 270, top: 235, zIndex: 30}} 
              onMouseEnter={() => onExpandChart('agent')}
              onMouseLeave={() => onCloseChart('')} 
              >
                <img src={IconAgent} alt="" style={{position: 'absolute', left: 43, top: 47}} width={35} />
              </div>
              <div key="line-1" className={classNames(styles["chart-line"], styles["chart-service-line"])} >
                <div className={styles["chart-service-dot"]} ></div>
              </div>
              <div key="line-2" className={classNames(styles["chart-line"], styles["chart-shop-line"])} >
                <div className={styles["chart-service-dot"]} ></div>
              </div>
              <div key="line-3" className={classNames(styles["chart-line"], styles["chart-company-line"])} >
                <div className={styles["chart-service-dot"]} ></div>
              </div>
              <div key="line-4" className={classNames(styles["chart-line"], styles["chart-agent-line"])} >
                <div className={styles["chart-service-dot"]} ></div>
              </div>
            </QueueAnim>
            </div>
            {
              industryEndDate && <div className={styles["data-note"]} >（数据截止至{industryEndDate}）</div>
            }
          </div>
        </OverPack>
      </div>
    </div>
  );
};

export default connect(({home}) => {
  return ({
    statistic: home.industryStatistic,
    industryEndDate: home.industryEndDate
  })
})(Industry);