/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import classNames from 'classnames'
import {connect} from 'dva'
import Media from 'react-media';
import  Qs from 'qs';
import styles from './index.m.less'
import {Card, Icon} from 'antd'
import { FormattedMessage } from 'react-intl';
 
class RecruitInfo extends Component {

  constructor(props){
    super(props);
    this.state={
      record: undefined
    }
    
  }

  componentDidMount(){
    const { history,location:{search}, dispatch } = this.props;
    const { id } = Qs.parse(search.replace(/^\?/, ''));
    if(!id){
      history.push('/recruit.htm')
    }
    dispatch({
      type: 'recruitment/getInfo',
      payload: {id : id},
    }).then(result => {
      if(result){
        this.setState({
          record: result
        })
      }else{
        history.push('/recruit.htm')
      }
    });
  }

  render() {
    const { record } = this.state;
    const {isMobile} = this.props;
    if(!record){
      return null

    }

    console.log(isMobile)
    if(isMobile){
      return <div className={styles['recruit-info-mobile']}>
        <div className={styles["recruit-content"]}>
          <div className={styles['recruit-conrainer-title']}>
            <div className={classNames(styles['recruit-title'], 'font-blod')}><FormattedMessage id="company.menu.joinUS" /></div>
            <div className={styles['recruit-title-bg']}></div>
          </div>
          <div className={styles['recruit-info-content-mobile']}>
            <div className={styles['recruit-info-title']}>
              <div className={styles['info-title']}>{record.jobName}</div>
              <div className={styles['info-text']}>
                <div>{record.recruitSite}</div>
                <div className={styles['text-divider']}></div>
                <div>{record.jobCategory || '--'}</div>
              </div>
            </div>
            {
              (() => {
                if(!record.recruitText){
                  return null;
                }
                const _list = JSON.parse(record.recruitText);
                return _list.map((item, index) => {
                  const _text = item.text.map((it, i) => {
                    return <div key={i}>{`${i+1}. ${it}`}</div>
                  })
                  return  <div key={index} className={styles['item']}>
                            <div className={classNames(styles['item-title'], 'font-blod')}>{item.title}</div>
                            <div className={styles['item-text']}>
                              {_text}
                            </div>
                          </div>
                })
              })()
            }
            {record.recruitUrl ? <a href={record.recruitUrl} target="_blank"><div  className={styles['recruit-apply-button']}><Icon type="upload" style={{marginRight: '10px', fontSize: 32}} /><span>申请岗位</span></div></a> : null} 
          </div>
        </div>

      </div>

    }

    return (
      <div className={styles['recruit-info']}>
        <Card headStyle={{ padding: '14px 40px'}} bodyStyle={{ padding: '30px 40px'}} style={{boxShadow: '2px 2px 10px 0px #D8D8D8'}}
          title={<div className={styles['recruit-info-title']}>
            <div className={styles['info-title']}>{record.jobName}</div>
            <div className={styles['info-text']}>
              <div>{record.recruitSite}</div>
              <div className={styles['text-divider']}></div>
              <div>{record.jobCategory || '--'}</div>
            </div>
          </div>} 
          // eslint-disable-next-line react/jsx-no-target-blank
          extra={record.recruitUrl ? <a href={record.recruitUrl} target="_blank"><div  className={styles['recruit-apply-button']}><Icon type="upload" style={{marginRight: '10px', fontSize: 20}} /><span>申请岗位</span></div></a> : null} 
          >
            <div className={styles['recruit-info-content']}>
              {
                (() => {
                  if(!record.recruitText){
                    return null;
                  }
                  const _list = JSON.parse(record.recruitText);
                  return _list.map((item, index) => {
                    const _text = item.text.map((it, i) => {
                      return <div key={i}>{`${i+1}. ${it}`}</div>
                    })
                    return  <div key={index} className={styles['item']}>
                              <div className={classNames(styles['item-title'], 'font-blod')}>{item.title}</div>
                              <div className={styles['item-text']}>
                                {_text}
                              </div>
                            </div>
                  })
                })()
              }
            </div>
          </Card>
      </div>
    );
  }
}

export default connect(({loading }) => {
  return ({
    loading: loading.effects['recruitment/list'] || false,
  })
})(props => (
  <Media query="(max-width: 999px)">
    {isMobile => <RecruitInfo {...props} isMobile={isMobile} /> }
  </Media>
));