import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import styles from './index.m.less'

const Overlap = ({style={}, className={}, reverse=false, renderLeft, renderRight}) => {
  return (
    <div className={classNames(styles.overlap, className)} style={style}>
      <div className={classNames(styles['card-left-container'], styles['card-bulge'])} style={{flexDirection: !reverse ? 'row' :'row-reverse'}}>
        <div style={!reverse ? {marginLeft: '4px'} : {marginRight: '4px'}} className={styles['card-left']}>{renderLeft()}</div>
        <div style={{flex: '1'}}></div>
      </div>
      <div className={classNames(styles['card-right'])} style={reverse ? {left: '4px'} : {right: '4px'}}>{renderRight()}</div>
    </div>
  );
};

Overlap.propTypes = {
  renderLeft: PropTypes.func.isRequired,
  renderRight: PropTypes.func.isRequired
};

export default Overlap;