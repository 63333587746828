import React,{useEffect } from 'react';
import {connect} from 'dva'
import Media from 'react-media';
import Banner from './Banner'
import ProductMatrix from './ProductMatrix'
import Industry from './Industry'
import NewsCenter from './NewsCenter'
import Contact from './Contact'

import BannerMibile from './BannerMibile'
import ProductMatrixMobile from './ProductMatrixMobile'
import IndustryMobile from './IndustryMobile'
import NewsCenterMobile from './NewsCenterMobile'


const Home = props => {
  return (
    <div>
      <Banner />
      <div style={{position: 'relative'}}>
        <ProductMatrix {...props} />
        <Industry />
        <NewsCenter {...props}/>
        <Contact />
      </div>
    </div>
  );
};


const HomeMobile = props => {
  const { location:{state }} = props;
  useEffect(()=> {
    if(state && state.position){
      let positionElement = document.getElementById(state.position);
      if(positionElement) { 
        positionElement.scrollIntoView(); 
      }
    }
  },[state])

  return (
    <div >
      <BannerMibile />
      <ProductMatrixMobile {...props} />
      <IndustryMobile />
      <NewsCenterMobile {...props}/>
    </div>
  );
};

export default connect()(props => (
  <Media query="(max-width: 999px)">
    {isMobile => isMobile ? <HomeMobile {...props} /> : <Home {...props} />}
  </Media>
));