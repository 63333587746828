import { delay } from 'dva/saga';
import moment from 'moment';
import { statistic } from '@/services/industry';

export default {
    namespace : 'home',
    state : {
        onWheel: false,
        industryStatistic: {},
        industryEndDate: ''
    },

    reducers : {
        save(state,  { payload }){
            return { ...state, ...payload }
        },
        saveWheel(state, {payload : {onWheel}}){
            return { ...state, onWheel }
        }
    },

    effects : {
        *onWheel({payload},{call, put, select } ){
            const {onWheel} = yield select(_=>_.home)
            if(!onWheel){
                yield put({
                    type: 'saveWheel',
                    payload: {
                        onWheel : !onWheel
                    },
                });
                yield call(delay, 1000);
                yield put({
                    type: 'saveWheel',
                    payload: {
                        onWheel : onWheel
                    },
                });
            }
        },
        *industryStatistic({payload},{call, put } ){
            //call：axios异步请求
            const data = yield call(statistic, payload);
            if(data && data.list){
                let _industryStatistic = {};
                let _end_date = '';
                data.list.forEach(_item => {
                    let _rebuild = {
                        label: _item.title,
                        value: _item.value,
                        unit: _item.unit,
                    }
                    if(_item.list && _item.list.length > 0){
                        if(_item.yinterval){
                            _rebuild.yInterval= _item.yinterval;
                        }
                        if(_item.yunit){
                            _rebuild.yUnit= _item.yunit;
                        }
                        _rebuild.list = _item.list.map(_l => {
                            if(!_end_date || moment(_l.xvalue, "YYYYMMDD").diff(moment(_end_date, "YYYYMMDD"), 'months') > 0){
                                _end_date = moment(_l.xvalue , "YYYYMMDD").format('YYYY年M月');
                            }
                            return ({xValue :moment(_l.xvalue , "YYYYMMDD").format('MMM'), yValue: _l.yvalue})
                        })
                    }
                    _industryStatistic[_item.type] = _rebuild;
                })
                yield put({
                    type: 'save',
                    payload: {
                        industryStatistic : _industryStatistic,
                        industryEndDate: _end_date
                    },
                });
            }
        },
    },

}