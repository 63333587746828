import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import ImageCase from '@/assets/img/product/rz/image_case.png';
import Title from './TitleMobile'

const Service_oes = () => {
  return (
    <div className={styles['rz-container']}>
      <div className={styles['rz-service-title']} ><FormattedMessage id="home.product.service.oes" /></div>

      <div className={styles['rz-card']} ><img alt="" src={ImageCase} width="100%" /></div>
      <Title style={{marginTop : 20}} text={<FormattedMessage id="product.rz.introduce.title" />}/>
      <div className={classNames(styles['rz-introduce'], styles['rz-text'])}><FormattedMessage id="product.oes.introduce.text" /></div>
      
    </div>
  );
};

export default Service_oes;