import React, {Component} from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import BasicLayout from '@/layouts/BasicLayout'


export default class RouteConfig extends Component {
    render () {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/" component= {BasicLayout}/>
          </Switch>
        </BrowserRouter>
      )
    }
  }