import React, { Component }  from 'react';
import * as echarts from "echarts";
import { FormattedMessage } from 'react-intl';
import {GeoJson_zh, GeoMap_zh} from "utils/Map.js"
import styles from './mobile.m.less'
import classNames from 'classnames'

const provincial_capital=['上海','北京','安徽','济南','沈阳','武汉','昆明','杭州','成都','石家庄','长春','哈尔滨','呼和浩特','太原','西安','郑州','合肥','南京','福州','广州','南昌','南宁','贵阳','长沙','天津','重庆'];
 
class DistributionMap extends Component {

  constructor(){
    super();
    this.distributionMap= null;
  }

  componentDidMount(){
    this.initBusinessDistributionMap();
  }
  initBusinessDistributionMap(){
    echarts.registerMap('china', GeoJson_zh);
    let mapBoxEchart = echarts.init(this.distributionMap);
    let geoCoordMap = GeoMap_zh;
    let HFData = provincial_capital.map((item, index) => {
        return [{name: '上海'},{name : item, value : 80}]
    });
    HFData.push([{name: '上海'}, {name: '上海',value: 120}]);
    const color = ['#11ee7d', '#FFDE00', '#FFDE00']; //圆圈和字的颜色，线的颜色，箭头颜色
    // 数据
    let series = [];
    // 遍历由上海到其他城市的线路
    [
        ['上海', HFData]
    ].forEach(function(item, i) {
        // 配置
      series.push(
        {
          // 目的地信息
          name: item[0],
          type: 'effectScatter',
          coordinateSystem: 'geo',
          zlevel: 2,
          rippleEffect: {
              brushType: 'stroke'
          },
          label: {
              normal: {
                  show: false,
                  position: 'right',
                  formatter: '{b}'
              }
          },
          symbolSize: function(val) {
              return val[2] / 8;
          },
          itemStyle: {
              normal: {
                  color:  function(val) {
                      if(val.name === val.seriesName){
                          return '#FF6023'
                      }
                      return (val.data.value[2] / 8) < 10 ?  color[i] : '#FFDE00';
                  }
              }
          },
          data: item[1].map(function(dataItem) {
              return {
                  name: dataItem[1].name,
                  value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
              };
          })
        }
      );
    });

    // 指定相关的配置项和数据
    let mapBoxOption = {
      geo: {
          map: 'china',
          roam: false, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
          aspectScale: 0.75,
          zoom: 1.20,
          label: {
              normal: {
                  show: false,
                  textStyle: {
                      color: '#00a0c9'
                  }
              },
              emphasis: { // 对应的鼠标悬浮效果
                  // show: false,
                  textStyle: {
                      color: "#fff"
                  }
              }
          },
          itemStyle: {
              normal: {
                  borderWidth: 1,
                  areaColor: 'rgba(0, 38, 132, 1)',
                  borderColor: '#33ebf5'
              },
              emphasis: {
                  borderWidth: 1,
                  borderColor: '#33ebf5',
                  areaColor: "rgba(0, 38, 132, 0.8)",
                  shadowColor: 'rgba(0, 38, 132, 0.8)'
              }
          }
      },
      series: series
    };
    // 使用制定的配置项和数据显示图表
    mapBoxEchart.setOption(mapBoxOption);
    mapBoxEchart.off("click");
    mapBoxEchart.on('click', function(params){
		});
    // echart图表自适应
    window.addEventListener("resize", function() {
        mapBoxEchart.resize();
    });
  }

  render() {
    return (
      <div className={styles['distribution-map']} id="distribution-map" >
        <div className={styles.business}>
          <div className={styles['map-container']}>
            <div id="business-distribution-map" style={{width: '100%',height: '564px',magin:"auto"}} ref={(r) => this.distributionMap = r} />
          </div>
          <div className={styles['content-container']}>
            <div className={classNames(styles['content-title'], 'font-blod')}><FormattedMessage id="company.business.title" /></div>
            <div className={styles['content-text']}><FormattedMessage id="company.business.content" /></div>
          </div>
          
        </div>
      </div>
    );
  }
}


export default DistributionMap;