import React, {useState, useRef} from 'react';
import { FormattedMessage } from 'react-intl';
import {Carousel} from 'antd'
import classNames from 'classnames'
import styles from './mobile.m.less'
import CoverBackground from '@/assets/img/company/team/cover-bg.png';
import CoverImage_SCH from '@/assets/img/company/team/cover-sch.png';
import CoverImage_SH from '@/assets/img/company/team/cover-sh.png';
import CoverImage_WZM from '@/assets/img/company/team/cover-wzm.png';
import CoverImage_HQC from '@/assets/img/company/team/cover-hqc.png';
import CoverImage_NYC from '@/assets/img/company/team/cover-nyc.png';

import CoverImage_XYB from '@/assets/img/company/team/cover-xyb.png';
import CoverImage_ZLL from '@/assets/img/company/team/cover-zll.png';
import CoverImage_WL from '@/assets/img/company/team/cover-wl.png';
import CoverImage_LN from '@/assets/img/company/team/cover-ln.png';

const team_list = [
  [
    {
      name: '孙才厚', post: '董事长', placement: 'left', image: CoverImage_SCH, text: ['曾任恒丰银行总行主持工作副行长，','烟台银行行长；','中国服务贸易协会保理专委会常务副主任；','广东省及深圳市保理协会常务副主任；',
      '中国供应链金融（商业保理）50人专家论坛专家委员；','中国中小企业金融服务50人论坛专家委员；','深圳大学供应链金融兼职研究生导师；','三十多年法人银行高管、五年专注供应链金融研究与实践。']
    },
    {
      name: '孙赫', post: 'CEO', placement: 'right', image: CoverImage_SH,  text: ['中国服务贸易协会商业保理专业委员会副主任；','广东省商业保理协会副会长；','广州人力资源服务协会副会长；','南京市人力资源产业协会副会长；',
        '深圳市商业保理协会副秘书长；','英国西英格兰大学工商管理硕士；','中欧国际工商学院高级工商管理硕士；','10多年保理公司副总裁高管经验；','供应链金融领域专家和运营专家；']
    },
    {
      name: '夏义博', post: '', placement: 'left', image: CoverImage_XYB, text: ['9年银行从业经验；','兰州银行反洗钱、金融IC卡业务负责人；','山东农信FTP项目负责人；','恒丰银行互联网创新高级业务专家。']
    }
  ],
  [
    {
      name: '王忠民', post: '', placement: 'left', image: CoverImage_WZM, text: ['曾任全国社保基金理事会党组成员，副理事长；','曾任陕西省政府办公厅主任，全国政协委员；','曾任西北大学教授，博导，校长；中国社科院博士。']
    },
    {
      name: '华庆成', post: '', placement: 'right', image: CoverImage_HQC, text: ['1982年加入中国银行上海分行，曾任职汉华银行；','1995年加入大通曼哈顿银行并出任副总裁兼上海分行副行长；','曾在摩根大通银行（中国）副行长兼上海分行行长；','2011年任苏格兰皇家银行（中国）有限公司行长。']
    },
    {
      name: '聂有诚', post: '', placement: 'left', image: CoverImage_NYC, text: ['亚太人才服务研究院执行院长；','深圳市人才交流服务协会副会长；','上海人才服务行业协会特聘顾问。','曾创建FESCO深圳公司，后被全球巨头Adecco收购，后致力于行业研究。']
    }
  ],
  [
    {
      name: '张玲丽', post: '销售副总裁', placement: 'left', image: CoverImage_ZLL, text: [ '大学本科学历，曾在中国平安产险工作14年，总监，管理人力550人，年度保费15个亿。', '擅长大团队管理，销售流程打造，管理体系建设，科技工具应用和客户价值研究。', '注重策略制定和执行，追求结果达成和利润贡献。']
    },
    {
      name: '刘宁', post: '副总裁', placement: 'right', image: CoverImage_LN, text: ['中欧国际工商学院高级工商管理硕士在读。', '十余年高管、合伙人及数字化架构经历，主导过多家企业的数字化转型项目，有完整的信息化建设及数字化平台落地经验，在组织效能提升以及数字化战略落地方面有丰富经验。', '曾主导并负责过国家电网旗下的电e宝、中国国际招标网等平台的研发和管理工作。']
    },
    {
      name: '王磊', post: '', placement: 'left', image: CoverImage_WL, text: ['10年IT架构和项目开发经验；','7年银行系统开发经历恒丰银行直销银行“一贯”高级架构师；','山盟支付云系统高级架构师。']
    }
  ]
]

const Team = () => {
  const [current, setCurrent] =useState(0);
  const teamRef = useRef(null);

  const _team_render = (list) => {
    return list.map((item, i) => {
      return <div key={i} 
            >
              <div className={styles['info-mian']}>
                <div className={styles['info-container']}>
                  <div className={styles['info-cover']} style={{background: `url(${CoverBackground}) no-repeat center center`, backgroundSize: 'cover'}}>
                    <img src={item.image} alt="" srcSet="" />
                  </div>
                  <div className={styles['info-text-container']}>
                    <div className={styles['info-text']}>
                      <div className={classNames(styles['info-name-container'], 'font-blod')}>
                      <div className={styles['info-name']}>{item.name}</div>
                      <div className={styles['info-name-bg']}></div>
                      </div>
                      <div className={styles['info-post']}>{item.post}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['info-content']}>
                <div className={styles['info-content-container']}>
                  {item.text.map((val, k) => <div key={k} className={styles['info-text']}>{val}</div>)}
                </div>
              </div>
          </div>
    })
  };

  const _team_list_render = team_list.map((it, i) => <div key={i} className={styles['team-list-conrainer']}>{_team_render(it)}</div>);
  return (
    <div className={styles['team-conrainer']} id="team-introduce">
      <div className={styles['team-content']} >
        <div className={styles['team-conrainer-title']}>
          <div className={classNames(styles['team-title'], 'font-blod')}><FormattedMessage id="company.menu.team" /></div>
          <div className={styles['team-title-bg']}></div>
        </div>
        <div className={styles.team}>
          <Carousel ref={teamRef} afterChange={(current) => setCurrent(current)} dots={false} >
            {_team_list_render}
          </Carousel>
          
        </div>
      </div>
      {
        current!==0 ? <div className={styles['team-left-arrow']} onClick={() => teamRef.current.slick.slickGoTo(current>0? current-1 : 0 )} ></div>
        : <div className={styles['team-left-arrow-disbaled']}></div>
      }
      {
        current!==team_list.length-1 ? <div className={styles['team-right-arrow']} onClick={() => teamRef.current.slick.slickGoTo(current< team_list.length-1 ? current+1 : team_list.length-1)} ></div>
        : <div className={styles['team-right-arrow-disbaled']}></div>
      } 
      <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('company-partner').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
    </div>
  );
};

export default Team;