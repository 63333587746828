import React from 'react';
import styles from './index.m.less';
import { FormattedMessage } from 'react-intl';
import {ElfList} from 'elf-ui';
import B2bImage01 from '@/assets/img/home/product/b2b-image-01.png'
import B2bImage02 from '@/assets/img/home/product/b2b-image-02.png'
import B2bImage03 from '@/assets/img/home/product/b2b-image-03.png'
import B2bImage04 from '@/assets/img/home/product/b2b-image-04.png'
import OesImage01 from '@/assets/img/home/product/oes-image-01.png'
import OesImage02 from '@/assets/img/home/product/oes-image-02.png'
import BxdlImage01 from '@/assets/img/home/product/bxdl-image-01.png'
import BxdlImage02 from '@/assets/img/home/product/bxdl-image-02.png'
import QyxyImage01 from '@/assets/img/home/product/qyxy-image-01.png'
import QyxyImage02 from '@/assets/img/home/product/qyxy-image-02.png'
import LwzfImage01 from '@/assets/img/home/product/lwzf-image-01.png'
import LwzfImage02 from '@/assets/img/home/product/lwzf-image-02.png'
import LwzfImage03 from '@/assets/img/home/product/lwzf-image-03.png'
import LwzfImage04 from '@/assets/img/home/product/lwzf-image-04.png'
import LwzfImage05 from '@/assets/img/home/product/lwzf-image-05.png'
import HtqyImage01 from '@/assets/img/home/product/htqy-image-01.png'
import HtqyImage02 from '@/assets/img/home/product/htqy-image-02.png'
import HtqyImage03 from '@/assets/img/home/product/htqy-image-03.png'
import ZngsImage01 from '@/assets/img/home/product/zngs-image-01.png'
import ZngsImage02 from '@/assets/img/home/product/zngs-image-02.png'
import GzdzImage01 from '@/assets/img/home/product/gzdz-image-01.png'
import GzdzImage03 from '@/assets/img/home/product/gzdz-image-03.png'
import GzdzImage04 from '@/assets/img/home/product/gzdz-image-04.png'

import IconHexagon from '@/assets/img/home/product/icon-hexagon.png'
import IconDotline from '@/assets/img/home/product/icon-dotline.png'
import IconDot_lg from '@/assets/img/home/product/icon-dot-lg.png'
import IconDot_md from '@/assets/img/home/product/icon-dot-md.png'
import IconDot_sm from '@/assets/img/home/product/icon-dot-sm.png'
import IconLine from '@/assets/img/home/product/icon-line.png'



const {Grid} = ElfList

const Service_B2B = <div className={styles['item-image']} >
  <img style={{ position: 'absolute', left: '82px', top: '39px'}} src={B2bImage01} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '109px', top: '109px'}} className={styles['b2b-fade-animation-1']} src={B2bImage02} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '109px', top: '136px'}} className={styles['b2b-fade-animation-2']} src={B2bImage03} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '84px', top: '86px'}} className={styles['b2b-fade-animation-6']} src={B2bImage04} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '125px', top: '108px'}} className={styles['b2b-fade-animation-7']} src={B2bImage04} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '84px', top: '124px'}} className={styles['b2b-fade-animation-8']} src={B2bImage04} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '125px', top: '145px'}} className={styles['b2b-fade-animation-9']} src={B2bImage04} alt="" srcSet=""/> 
  <div style={{ position: 'absolute', left: '45px', top: '145px'}} className={styles['b2b-fade-animation-3']} >
    <img style={{ position: 'absolute' }} src={IconHexagon} alt="" srcSet="" width={17} height={17} />
    <img style={{ position: 'absolute', left: '7px', top: '13px', opacity: '0.6'}} src={IconDotline} alt="" srcSet="" width={2} height={45} />
  </div>
  <div style={{ position: 'absolute', right: '75px', top: '42px'}} className={styles['b2b-fade-animation-4']} >
    <img style={{ position: 'absolute' }} src={IconHexagon} alt="" srcSet="" width={22} height={22} />
    <img style={{ position: 'absolute', left: '10px', top: '18px', opacity: '0.8'}} src={IconDotline} alt="" srcSet="" width={2} height={61} />
  </div>
  <div style={{ position: 'absolute', right: '45px', top: '95px'}} className={styles['b2b-fade-animation-5']} >
    <img style={{ position: 'absolute' }} src={IconHexagon} alt="" srcSet="" width={17} height={17} />
    <img style={{ position: 'absolute', left: '7px', top: '13px', opacity: '0.6'}} src={IconDotline} alt="" srcSet="" width={2} height={46} />
  </div>
</div>;

const Service_OES = <div className={styles['item-image']} >
  <img style={{ position: 'absolute', left: '100px', top: '39px', zIndex: '10'}} className={styles['oes-up-down-animation-1']} src={OesImage01} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '67px', top: '97px'}} src={OesImage02} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', top: '154px', right: '32px'}} className={styles['dot-rise-animation-1']}  src={IconDot_lg} alt="" srcSet="" width={29} height={29} />
  <img style={{ position: 'absolute', top: '144px', left: '32px'}} className={styles['dot-rise-animation-2']}  src={IconDot_lg} alt="" srcSet="" width={19} height={19} />
  <img style={{ position: 'absolute', top: '90px', left: '36px'}} className={styles['dot-rise-animation-3']}  src={IconDot_lg} alt="" srcSet="" width={9} height={9} />
  <img style={{ position: 'absolute', top: '70px', right: '40px'}} className={styles['dot-rise-animation-4']}  src={IconDot_md} alt="" srcSet="" width={15} height={15} />
  <img style={{ position: 'absolute', top: '33px', right: '90px'}} className={styles['dot-rise-animation-5']}  src={IconDot_sm} alt="" srcSet="" width={10} height={10} />
  <img style={{ position: 'absolute', top: '186px', left: '86px'}} className={styles['dot-rise-animation-6']}  src={IconDot_sm} alt="" srcSet="" width={10} height={10} />
  <img style={{ position: 'absolute', top: '205px', left: '137px'}} className={styles['dot-rise-animation-7']}  src={IconDot_sm} alt="" srcSet="" width={5} height={5} />
</div>;

const Service_BXDL = <div className={styles['item-image']} >
  <img style={{ position: 'absolute', left: '74px', top: '39px', zIndex: '10'}} src={BxdlImage01} alt="" srcSet=""/> 
  <div style={{ position: 'absolute', left: '98px', top: '96px', zIndex: '12'}} className={styles['bxdl-horizontal-gradient']}>
    <img src={BxdlImage02} alt="" srcSet=""/> 
	</div>
  <img style={{ position: 'absolute', left: '47px', top: '77px', opacity: 0.6}} className={styles['line-rise-animation-1']} src={IconLine} alt="" srcSet="" width={2} height={60} />
  <img style={{ position: 'absolute', left: '73px', top: '116px', opacity: 0.6}} className={styles['line-rise-animation-2']} src={IconLine} alt="" srcSet="" width={2} height={40} />
  <img style={{ position: 'absolute', right: '73px', top: '55px', opacity: 0.6}} className={styles['line-rise-animation-3']} src={IconLine} alt="" srcSet="" width={2} height={50} />
  <img style={{ position: 'absolute', right: '48px', top: '100px', opacity: 0.6}} className={styles['line-rise-animation-4']} src={IconLine} alt="" srcSet="" width={2} height={55} />
</div>;

const Service_QYXY = <div className={styles['item-image']} >
  <img style={{ position: 'absolute', left: '84px', top: '38px'}} src={QyxyImage01} alt="" srcSet=""/> 
  <div style={{ position: 'absolute', left: '96px', top: '104px', zIndex: '10'}} className={styles['qyxy-horizontal-gradient-1']}>
    <img src={QyxyImage02} alt="" srcSet=""/> 
	</div>
  <div style={{ position: 'absolute', left: '100px', top: '120px', zIndex: '10'}} className={styles['qyxy-horizontal-gradient-2']}>
    <img src={QyxyImage02} alt="" srcSet=""/> 
	</div>
  <div style={{ position: 'absolute', left: '105px', top: '140px', zIndex: '10'}} className={styles['qyxy-horizontal-gradient-3']}>
    <img src={QyxyImage02} alt="" srcSet=""/> 
	</div>
  <div style={{ position: 'absolute', left: '48px', top: '38px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-1']}>1</div>
  <div style={{ position: 'absolute', left: '63px', top: '82px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-2']}>1</div>
  <div style={{ position: 'absolute', left: '130px', top: '26px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-3']}>1</div>
  <div style={{ position: 'absolute', left: '195px', top: '43px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-4']}>1</div>
  <div style={{ position: 'absolute', left: '215px', top: '91px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-5']}>1</div>
  <div style={{ position: 'absolute', left: '60px', top: '60px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-6']}>0</div>
  <div style={{ position: 'absolute', left: '170px', top: '53px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-7']}>0</div>
  <div style={{ position: 'absolute', right: '47px', top: '113px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-8']}>0</div>
  <div style={{ position: 'absolute', right: '56px', top: '137px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-9']}>0</div>
</div>;

const Service_LWZF = <div className={styles['item-image']} >
  <img style={{ position: 'absolute', left: '45px', top: '39px'}} src={LwzfImage01} alt="" srcSet=""/> 
  <div style={{ position: 'absolute', left: '110px', top: '73px', zIndex: '10', width: '18px', height: '53px'}} >
    <img style={{position: 'absolute', left: '0px', bottom: '0px'}} src={LwzfImage02} alt="" srcSet="" width="100%" className={styles['lwzf-animation-1']}/> 
  </div>
  <div style={{ position: 'absolute', left: '122px', top: '68px', zIndex: '10', width: '17px', height: '64px'}} >
    <img style={{position: 'absolute', left: '0px', bottom: '0px'}} src={LwzfImage03} alt="" srcSet="" width="100%" className={styles['lwzf-animation-2']}/> 
  </div>
  <div style={{ position: 'absolute', left: '136px', top: '106px', zIndex: '10', width: '18px', height: '34px'}} >
    <img style={{position: 'absolute', left: '0px', bottom: '0px'}} src={LwzfImage04} alt="" srcSet="" width="100%" className={styles['lwzf-animation-3']}/> 
  </div>
  <div style={{ position: 'absolute', left: '148px', top: '93px', zIndex: '10', width: '18px', height: '56px'}} >
    <img style={{position: 'absolute', left: '0px', bottom: '0px'}} src={LwzfImage05} alt="" srcSet="" width="100%" className={styles['lwzf-animation-4']}/> 
  </div>
  <div style={{ position: 'absolute', left: '48px', top: '38px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-1']}>1</div>
  <div style={{ position: 'absolute', left: '63px', top: '82px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-2']}>1</div>
  <div style={{ position: 'absolute', left: '130px', top: '26px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-3']}>1</div>
  <div style={{ position: 'absolute', left: '195px', top: '43px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-4']}>1</div>
  <div style={{ position: 'absolute', left: '215px', top: '91px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-5']}>1</div>
  <div style={{ position: 'absolute', left: '60px', top: '60px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-6']}>0</div>
  <div style={{ position: 'absolute', left: '170px', top: '53px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-7']}>0</div>
  <div style={{ position: 'absolute', right: '47px', top: '113px', color: '#0091FF', fontSize: '10px', fontWeight: 600}} className={styles['number-animation-8']}>0</div>
  <div style={{ position: 'absolute', right: '56px', top: '137px', color: '#0091FF', fontSize: '20px', fontWeight: 600}} className={styles['number-animation-9']}>0</div>
</div>;

const Service_HTQY = <div className={styles['item-image']} >
  <img style={{ position: 'absolute', left: '73px', top: '39px', zIndex: '10'}} src={HtqyImage01} alt="" srcSet=""/> 
  <div style={{ position: 'absolute', left: '88px', top: '107px', zIndex: '10'}} className={styles['htqy-horizontal-gradient-1']}>
    <img src={HtqyImage03} alt="" srcSet=""/> 
	</div>
  <div style={{ position: 'absolute', left: '88px', top: '120px', zIndex: '10'}} className={styles['htqy-horizontal-gradient-2']}>
    <img src={HtqyImage03} alt="" srcSet=""/> 
	</div>
  <div style={{ position: 'absolute', left: '88px', top: '135px', zIndex: '10'}} className={styles['htqy-horizontal-gradient-3']}>
    <img src={HtqyImage03} alt="" srcSet=""/> 
	</div>
  <img style={{ position: 'absolute', left: '37px', top: '95px', opacity: 0.6}} className={styles['line-rise-animation-1']} src={IconLine} alt="" srcSet="" width={2} height={60} />
  <img style={{ position: 'absolute', left: '63px', top: '134px', opacity: 0.6}} className={styles['line-rise-animation-2']} src={IconLine} alt="" srcSet="" width={2} height={40} />
  <img style={{ position: 'absolute', right: '60px', top: '45px', opacity: 0.6}} className={styles['line-rise-animation-3']} src={IconLine} alt="" srcSet="" width={2} height={50} />
  <img style={{ position: 'absolute', right: '48px', top: '122px', opacity: 0.6}} className={styles['line-rise-animation-4']} src={IconLine} alt="" srcSet="" width={2} height={55} />
  <img style={{ position: 'absolute', left: '142px', top: '130px', zIndex: '10'}} src={HtqyImage02} alt="" srcSet="" className={styles['htqy-pencil-animation']}/> 
</div>;

const Service_ZNGS = <div className={styles['item-image']} >
  <img style={{ position: 'absolute', left: '93px', top: '71px'}} src={ZngsImage01} alt="" srcSet=""/> 
  <img style={{ position: 'absolute', left: '100px', top: '119px'}} src={ZngsImage02} alt="" srcSet=""/> 
  {/* <img style={{ position: 'absolute', left: '115px', top: '43px'}} src={ZngsImage03} alt="" srcSet=""/>  */}
  <div style={{ position: 'absolute', left: '115px', bottom: '155px'}} className={styles['zngs-paper-animation']} >
    <div style={{top: '10px'}} className={styles['zngs-paper-line']} ></div>
    <div style={{top: '30px'}} className={styles['zngs-paper-line']} ></div>
    <div style={{top: '50px'}} className={styles['zngs-paper-line']} ></div>
  </div>

  <div style={{ position: 'absolute', left: '45px', top: '145px'}} className={styles['b2b-fade-animation-3']} >
    <img style={{ position: 'absolute' }} src={IconHexagon} alt="" srcSet="" width={17} height={17} />
    <img style={{ position: 'absolute', left: '7px', top: '13px', opacity: '0.6'}} src={IconDotline} alt="" srcSet="" width={2} height={45} />
  </div>
  <div style={{ position: 'absolute', right: '75px', top: '42px'}} className={styles['b2b-fade-animation-4']} >
    <img style={{ position: 'absolute' }} src={IconHexagon} alt="" srcSet="" width={22} height={22} />
    <img style={{ position: 'absolute', left: '10px', top: '18px', opacity: '0.8'}} src={IconDotline} alt="" srcSet="" width={2} height={61} />
  </div>
  <div style={{ position: 'absolute', right: '45px', top: '95px'}} className={styles['b2b-fade-animation-5']} >
    <img style={{ position: 'absolute' }} src={IconHexagon} alt="" srcSet="" width={17} height={17} />
    <img style={{ position: 'absolute', left: '7px', top: '13px', opacity: '0.6'}} src={IconDotline} alt="" srcSet="" width={2} height={46} />
  </div>
</div>;

const Service_GZDZ =  <div className={styles['item-image']} >
<img style={{ position: 'absolute', left: '73px', top: '71px', zIndex: '10'}} src={GzdzImage01} alt="" srcSet=""/> 
<img style={{ position: 'absolute', left: '97px', top: '53px'}} src={GzdzImage03} alt="" srcSet=""/> 
<img style={{ position: 'absolute', left: '112px', top: '39px', opacity: '0.4'}} src={GzdzImage03} alt="" srcSet="" className={styles['gzdz-animation-1']}  /> 
<img style={{ position: 'absolute', left: '95px', top: '110px', zIndex: '15'}} src={GzdzImage04} alt="" srcSet="" className={styles['gzdz-animation-2']}/> 
<img style={{ position: 'absolute', top: '154px', right: '32px'}} className={styles['dot-rise-animation-1']}  src={IconDot_lg} alt="" srcSet="" width={29} height={29} />
<img style={{ position: 'absolute', top: '144px', left: '32px'}} className={styles['dot-rise-animation-2']}  src={IconDot_lg} alt="" srcSet="" width={19} height={19} />
<img style={{ position: 'absolute', top: '90px', left: '36px'}} className={styles['dot-rise-animation-3']}  src={IconDot_lg} alt="" srcSet="" width={9} height={9} />
<img style={{ position: 'absolute', top: '70px', right: '40px'}} className={styles['dot-rise-animation-4']}  src={IconDot_md} alt="" srcSet="" width={15} height={15} />
<img style={{ position: 'absolute', top: '33px', right: '90px'}} className={styles['dot-rise-animation-5']}  src={IconDot_sm} alt="" srcSet="" width={10} height={10} />
<img style={{ position: 'absolute', top: '186px', left: '86px'}} className={styles['dot-rise-animation-6']}  src={IconDot_sm} alt="" srcSet="" width={10} height={10} />
<img style={{ position: 'absolute', top: '205px', left: '137px'}} className={styles['dot-rise-animation-7']}  src={IconDot_sm} alt="" srcSet="" width={5} height={5} />
</div>;

const products = [
  { element: Service_GZDZ, text: <FormattedMessage id="home.product.service.gzdz" />, path: '/product.htm/gzdz' },
  { element: Service_ZNGS, text: <FormattedMessage id="home.product.service.part3" />, path: '/product.htm/zngs' },
  { element: Service_QYXY, text: <FormattedMessage id="home.product.service.qyxy" />, path: '/product.htm/qyxy' },
  { element: Service_BXDL, text: <FormattedMessage id="home.product.service.part4" />, path: '/product.htm/bxdl' },
  { element: Service_HTQY, text: <FormattedMessage id="home.product.service.part5" />, path: '/product.htm/htqy' },
  { element: Service_LWZF, text: <FormattedMessage id="home.product.service.part6" />, path: '/product.htm/lwzf' },
  { element: Service_B2B, text: <FormattedMessage id="home.product.service.b2b" />, path: '/product.htm/b2b' },
  { element: Service_OES, text: <FormattedMessage id="home.product.service.oes" />, path: '/product.htm/oes' },
]

const ProductMatrix = (props) => {
  const {history} = props;
  const productsRender = products.map((item, index) => {
    return  <div key={index} className={styles["product-list-item"]} onClick={() => history.push(item.path)}>
              {item.element}
              <div className={styles["item-text"]}>{item.text}</div>
            </div>
  })

  return (
    <div className={styles.product}>
      <div className={styles['product-layout']}>
        <div className={styles.title}></div>
        <div className={styles.content}>
          <span className="font-blod"><FormattedMessage id="home.product.content.text1" /></span>
          <span><FormattedMessage id="home.product.content.text2" /></span>
          <span className="font-blod"><FormattedMessage id="home.product.content.text3" /></span>
        </div>
        <div className={styles["product-list"]}>
          <Grid columns={4} gutter={[27, 24]}>
              {productsRender}
          </Grid>
        </div>
      </div>
    </div>
  );
};




export default ProductMatrix;