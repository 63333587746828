import React, {useState, useEffect} from 'react';
import classNames from 'classnames'
import {connect} from 'dva'
import styles from './index.m.less';
import { FormattedMessage } from 'react-intl';
import {ElfList} from 'elf-ui';
import IconActivity from '@/assets/img/home/news/icon-activity.png'
import IconCompany from '@/assets/img/home/news/icon-company.png'
import IconIndustry from '@/assets/img/home/news/icon-industry.png'
import {fastDfsUrl} from 'utils/String'

const {Grid} = ElfList

const NewsCenter = (props) => {
  const {history, dispatch} = props
  const [active, onActive] = useState("L02");
  const [dataMap, onChangeData] = useState(undefined);
  const imageProps = {
    width: 44,
    height: 44
  }

  useEffect(() => {
    dispatch({
      type: 'news/getMap',
      payload: {},
    }).then(result => {
      if(result){
        onChangeData(result)
      }
    });

  }, [dispatch])

  let _list = []
  if(dataMap && active){
    _list = dataMap[active];
  }else{
    _list = []
  }

  const renderNews = _list.map((item, index) => {
    return <div key={index} className={styles["new-item"]} onClick={() => window.open(item.articleUrl)} >
      <div className={classNames(styles["item-title"], "font-blod")} >
        <div className={styles["title-text"]}>{item.articleTitle}</div>
        <div className={styles["prefix"]}></div>
      </div>
      <div className={styles["item-content"]} >{item.articleIntroduce}</div>
      <div className={styles["item-date"]} >{item.publicDate}</div>
    </div>
  })

  return (
    <div className={styles.news}>
      <div className={styles['news-layout']}>
        <div className={styles.title}></div>
        <div className={styles.content}>
          <span className="font-blod" ><FormattedMessage id="home.news.content.text1" /></span>
          <span><FormattedMessage id="home.news.content.text2" /></span>
          <span className="font-blod"><FormattedMessage id="home.news.content.text3" /></span>
        </div>
        <div className={styles["news-container"]}>
          <div className={styles["switch-bar"]}>
            <Grid columns={3} gutter={20}>
              <div className={styles["switch-item"]} onClick={() => onActive("L02")} active={ active === "L02" ? 'true' : "false"}>
                <img src={IconActivity} alt="" srcSet="" {...imageProps} />
                <span className={styles["switch-item-text"]}><FormattedMessage id="home.news.bars.text1" /></span>
                <div className={styles["switch-under-line"]}></div>
              </div>
              <div className={styles["switch-item"]} onClick={() => onActive("L03")} active={ active === "L03" ? 'true' : "false"}>
                <img src={IconCompany} alt="" srcSet="" {...imageProps} />
                <span className={styles["switch-item-text"]}><FormattedMessage id="home.news.bars.text2" /></span>
                <div className={styles["switch-under-line"]}></div>
              </div>
              <div className={styles["switch-item"]} onClick={() => onActive("L04")} active={ active === "L04" ? 'true' : "false"}>
                <img src={IconIndustry} alt="" srcSet="" {...imageProps} />
                <span className={styles["switch-item-text"]}><FormattedMessage id="home.news.bars.text3" /></span>
                <div className={styles["switch-under-line"]}></div>
              </div>
            </Grid>
          </div>
          {
            _list.length > 0 && 
            <div className={styles["news-typography"]}>
              <div className={styles["new-cover"]} style={{backgroundImage: `url(${fastDfsUrl(_list[0].homeCover)}`}} onClick={() => window.open(_list[0].articleUrl)} >
                <div className={styles["cover-container"]} >
                  <div>
                    <div className={classNames(styles["cover-title"])} >
                      {_list[0].articleTitle}
                    </div>
                    <div className={styles["cover-content"]} >
                      <div className={styles["cover-content-text"]} >{_list[0].articleIntroduce}</div>
                    </div>
                    <div className={styles["cover-prefix"]}></div>
                  </div>
                  
                </div>
              </div>
              <div className={styles["new-list"]}>
                {renderNews}
              </div>
            </div>
          }
          <div className={styles["load-more"]} onClick={() => history.push('/news.htm')}>
            <div><span><FormattedMessage id="home.news.list.loadmore" /></span></div>
            <div className={styles["more-icon"]}></div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default connect()(NewsCenter);