import React, { Component }  from 'react';
import * as echarts from "echarts";
import { FormattedMessage } from 'react-intl';
import {GeoJson_zh, GeoMap_zh} from "utils/Map.js"
import styles from './mobile.m.less'
import classNames from 'classnames'

const provincial_capital=['上海','北京','安徽','济南','沈阳','武汉','昆明','杭州','成都','石家庄','长春','哈尔滨','呼和浩特','太原','西安','郑州','合肥','南京','福州','广州','南昌','南宁','贵阳','长沙','天津','重庆'];
 
class BusinessDistribution extends Component {

  constructor(){
    super();
    this.distributionMap= null;
  }

  componentDidMount(){
    this.initBusinessDistributionMap();
  }
  initBusinessDistributionMap(){
    echarts.registerMap('china', GeoJson_zh);
    let mapBoxEchart = echarts.init(this.distributionMap);
    let geoCoordMap = GeoMap_zh;
    let HFData = provincial_capital.map((item, index) => {
        return [{name: '上海'},{name : item, value : 80}]
    });
    HFData.push([{name: '上海'}, {name: '上海',value: 120}]);
    // let planePath = 'circle'; // 箭头的svg  圆点circle  箭头arrow
    // // push进去线路开始-结束地点-经纬度
    // let convertData = function(data) {
    //     var res = [];
    //     for (let i = 0; i < data.length; i++) {
    //       const dataItem = data[i];
    //       const fromCoord = geoCoordMap[dataItem[0].name];
    //       const toCoord = geoCoordMap[dataItem[1].name];
    //       if (fromCoord && toCoord) {
    //         res.push([{
    //             coord: fromCoord
    //         }, {
    //             coord: toCoord
    //         }]);
    //       }
    //     }
    //     return res;
    // };
    const color = ['#11ee7d', '#FFDE00', '#FFDE00']; //圆圈和字的颜色，线的颜色，箭头颜色
    // 数据
    let series = [];
    // 遍历由上海到其他城市的线路
    [
        ['上海', HFData]
    ].forEach(function(item, i) {
        // 配置
      series.push(
        //移动轨迹
        // {
        //   // 系列名称，用于tooltip的显示
        //   name: item[0],
        //   type: 'lines',
        //   zlevel: 1, // 用于 Canvas 分层，不同zlevel值的图形会放置在不同的 Canvas 中
        //   // effect出发到目的地 的白色尾巴线条
        //   // 线特效的配置
        //   effect: {
        //       show: true,
        //       period: 6, // 特效动画的时间，单位为 s
        //       trailLength: 0.1, // 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长
        //       color: '#46bee9', // 移动箭头颜色
        //       symbol: planePath,
        //       symbolSize: 4 // 特效标记的大小
        //   },
        //   // lineStyle出发到目的地 的线条颜色
        //   lineStyle: {
        //       normal: {
        //           color: color[i],
        //           width: 0,
        //           curveness: -0.4 //幅度
        //       }
        //   },
        //   data: convertData(item[1]) //开始到结束数据
        // }, 
        //路线
        // {
        //   //出发地信息
        //   name: item[0],
        //   type: 'lines',
        //   zlevel: 2,
        //   effect: {
        //       show: true,
        //       period: 6,
        //       trailLength: 0,
        //       symbol: planePath,
        //       symbolSize: 6
        //   },
        //   lineStyle: {
        //       normal: {
        //           color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        //               offset: 0,
        //               color: '#FFDE00' // 出发
        //           }, {
        //               offset: 1,
        //               color: '#FFDE00 ' // 结束 颜色
        //           }], false),
        //           width: 1.5,
        //           opacity: 1,
        //           curveness: -0.4
        //       }
        //   },
        //   data: convertData(item[1])
        // }, 
        {
          // 目的地信息
          name: item[0],
          type: 'effectScatter',
          coordinateSystem: 'geo',
          zlevel: 2,
          rippleEffect: {
              brushType: 'stroke'
          },
          label: {
              normal: {
                  show: false,
                  position: 'right',
                  formatter: '{b}'
              }
          },
          symbolSize: function(val) {
              return val[2] / 8;
          },
          itemStyle: {
              normal: {
                  color:  function(val) {
                      if(val.name === val.seriesName){
                          return '#FF6023'
                      }
                      return (val.data.value[2] / 8) < 10 ?  color[i] : '#FFDE00';
                  }
              }
          },
          data: item[1].map(function(dataItem) {
              return {
                  name: dataItem[1].name,
                  value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
              };
          })
        }
      );
    });

    // 指定相关的配置项和数据
    let mapBoxOption = {
      geo: {
          map: 'china',
          roam: false, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
          aspectScale: 0.75,
          zoom: 1.20,
          label: {
              normal: {
                  show: false,
                  textStyle: {
                      color: '#00a0c9'
                  }
              },
              emphasis: { // 对应的鼠标悬浮效果
                  // show: false,
                  textStyle: {
                      color: "#fff"
                  }
              }
          },
          itemStyle: {
              normal: {
                  borderWidth: 1,
                  areaColor: 'rgba(0, 38, 132, 1)',
                  borderColor: '#33ebf5'
              },
              emphasis: {
                  borderWidth: 1,
                  borderColor: '#33ebf5',
                  areaColor: "rgba(0, 38, 132, 0.8)",
                  shadowColor: 'rgba(0, 38, 132, 0.8)'
              }
          }
      },
      series: series
    };
    // 使用制定的配置项和数据显示图表
    mapBoxEchart.setOption(mapBoxOption);
    mapBoxEchart.off("click");
    mapBoxEchart.on('click', function(params){
      console.log('click')
		});
    // echart图表自适应
    window.addEventListener("resize", function() {
        mapBoxEchart.resize();
    });
  }

  render() {
    return (
      <div className={styles['business-container']} id="company-business">
        <div className={styles.business}>
          <div className={styles['business-conrainer-title']}>
            <div className={classNames(styles['business-title'], 'font-blod')}><FormattedMessage id="company.menu.business" /></div>
            <div className={styles['business-title-bg']}></div>
          </div>
          <div id="business-distribution-map" style={{width: '100%',height: '564px',magin:"auto"}} ref={(r) => this.distributionMap = r} />
          <div className={styles['content-container']}>
            <div className={classNames(styles['content-title'], 'font-blod')}><FormattedMessage id="company.business.title" /></div>
            <div className={styles['content-text']}><FormattedMessage id="company.business.content" /></div>
          </div>
        </div>
        <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('company-recruit').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
      </div>
    );
  }
}


export default BusinessDistribution;