import axios from 'axios'
import qs from 'querystring';
import config from '@/config'
import {message} from 'antd'

axios.defaults.baseURL = config.http.baseUrl;
axios.defaults.timeout = config.http.timeout

axios.interceptors.request.use(config => {//拦截器处理
    return config
})

axios.interceptors.response.use(response => {//请求返回数据处理
    if(response.status === '200' || response.status === 200){
        const response_data = response.data;
        if(response_data ){
            const {errorCode, errorMessage, data} = response_data
            if(errorCode === '0000'){
                return data;
            }else{
                message.error(errorMessage || '未知错误');
            }
        }else{
            message.error('服务异常');
            return undefined;
        }
    }else{
        // 非200请求抱错
        throw Error(response.opt || '服务异常')
    }
})

class http {
    static async get(url, params) {
        try {
            let query = await qs.stringify(params)
            let res = null;
            if (!params) {
                res = await axios.get(url)
            } else {
                res = await axios.get(url + '?' + query)
            }
            return res
        } catch (error) {
            return error
        }
    }
    static async post(url, params) {
        try {
            let res = await axios.post(url, params)
            return res
        } catch (error) {
            return undefined
        }
    }
    static async patch(url, params) {
        try {
            let res = await axios.patch(url, params)
            return res
        } catch (error) {
            return error
        }
    }
    static async put(url, params) {
        try {
            let res = await axios.put(url, params)
            return res
        } catch (error) {
            return error
        }
    }
    static async delete(url, params) {
        /**
        * params默认为数组
        */
        try {
            let res = await axios.post(url, params)
            return res
        } catch (error) {
            return error
        }
    }
    


}

export default http;