import React from 'react';
import classNames from 'classnames'
import { ElfCard } from 'elf-ui'
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import ImageIntroduce from '@/assets/img/product/bxdl/image-introduce.png';
import ImageBusiness from '@/assets/img/product/bxdl/image-business.png';
import InsuranceLiability from '@/assets/img/product/bxdl/insurance-liability.png';
import InsuranceCasualty from '@/assets/img/product/bxdl/insurance-casualty.png';
import Title from './Title'

const { Meta } = ElfCard;

const Service_bxdl = () => {
  return (
    <div className={styles['rz-container']}>
      <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageIntroduce} />} coverStyle={{left: '20px'}} bodyClassName={styles['rz-card-lg']}>
        <Meta style={{ paddingRight: '10px'}}>
          <Title text={<FormattedMessage id="product.rz.introduce.title" />}/>
          <div className={styles['rz-text']} style={{marginTop: '10px', marginBottom: '15px'}}><FormattedMessage id="product.bxdl.introduce.text1" /></div>
          <Title text={<FormattedMessage id="product.rz.characteristic.title" />} style={{marginBottom: '20px'}}/>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.bxdl.characteristic.text1" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text2" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text3" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.bxdl.characteristic.text4" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text5" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text6" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.bxdl.characteristic.text7" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text8" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text9" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.bxdl.characteristic.text10" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.bxdl.characteristic.text11" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text12" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text13" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text14" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text15" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.bxdl.characteristic.text16" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text17" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text18" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text19" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text20" /></span>
          </div>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.bxdl.characteristic.text21" /></span>
            <span className="font-blod"><FormattedMessage id="product.bxdl.characteristic.text22" /></span>
            <span><FormattedMessage id="product.bxdl.characteristic.text23" /></span>
          </div>
        </Meta>
      </ElfCard>
      <ElfCard offset={{right: '345px'}} bodyOffset={{right: '300px'}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={ImageBusiness} />} coverStyle={{right: '0px'}} style={{marginTop: 30}} 
      >
        <Meta >
          <Title text={<FormattedMessage id="product.business-object" />} style={{marginTop: '20px'}} />
          <div className={classNames(styles['rz-text'])} style={{marginTop: '20px', marginBottom: '20px'}}><FormattedMessage id="product.bxdl.business-object.text" /></div>
          <Title text={<FormattedMessage id="product.business-scenario" />} style={{marginBottom: '20px'}} />
          <div className={styles['rz-text']}><span><FormattedMessage id="product.bxdl.business-scenario.text" /></span></div>
        </Meta>
      </ElfCard>

      <div className={styles['bxdl-title']}></div>
      <ElfCard offset={{left: '345px'}} bodyOffset={{left: '268px'}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={InsuranceLiability} />} >
        <Meta>
          <Title text={<FormattedMessage id="product.bxdl.liability.title" />} style={{marginTop: 10}} />
          <div style={{marginTop: 25, marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title1" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.liability.text1" /></div>
          </div>
          <div style={{marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title2" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.liability.text2" /></div>
          </div>
          <div style={{marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title3" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.liability.text3" /></div>
          </div>
          <div style={{marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title4" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.liability.text4" /></div>
          </div>
          <div style={{ marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title5" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.liability.text5" /></div>
          </div>
        </Meta>
      </ElfCard>

      <ElfCard offset={{right: '345px'}} bodyOffset={{right: '300px'}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={InsuranceCasualty} />} coverStyle={{right: '0px'}} style={{marginTop: 30}} 
      >
        <Meta >
          <Title text={<FormattedMessage id="product.bxdl.casualty.title" />} style={{marginTop: 10}} />
          <div style={{marginTop: 25, marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title1" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.casualty.text1" /></div>
          </div>
          <div style={{marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title2" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.casualty.text2" /></div>
          </div>
          <div style={{marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title3" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.casualty.text3" /></div>
          </div>
          <div style={{marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title4" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.casualty.text4" /></div>
          </div>
          <div style={{ marginBottom: 8}} className={styles['bxdl-insurance']}>
            <div className={classNames(styles['text-prominent'], styles['rz-text'], styles.title)}><FormattedMessage id="product.bxdl.insurance.title5" /></div>
            <div className={classNames(styles.text, styles['rz-text'])}><FormattedMessage id="product.bxdl.casualty.text5" /></div>
          </div>
        </Meta>
      </ElfCard>
      
    </div>
  );
};

export default Service_bxdl;