import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import styles from './index.m.less'

const index = ({data=[], titleClassName={}, contentClassName={}, lineHeight=70}) => {

  console.log(lineHeight)
  const _render = data.map((item, index) => {
    const _textRender = Array.isArray(item.text) ? item.text.map((it, i) => {
      return <div key={i}>{it}</div>
    }) : item.text;
    const _is_line = index < data.length-1
    return <li key={index} className={styles['timeline-item-container']}>
      <div className={styles['timeline-item']}>
        <div className={styles['timeline-item-dot']}></div>
        {
          _is_line && <div className={styles['timeline-item-line']} style={{height: lineHeight,}}></div>
        }
        <div className={classNames(styles['timeline-item-content'], index % 2 === 0 ? styles['timeline-item-content-left'] : styles['timeline-item-content-right'])}>
          <div className={classNames(styles['item-content-title'], 'font-blod', titleClassName)}>{item.title}</div>
          <div className={classNames(styles['item-content-text'], contentClassName)}>
            { _textRender }
          </div>
        </div>
      </div>

    </li>
  })
  return (
    <div className={styles.timeline}>
      <ul className={styles['timeline-container']}>
        {_render}
      </ul>
    </div>
  );
};

index.propTypes = {
  data: PropTypes.array.isRequired
};

export default index;