import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import IconCompany from '@/assets/img/company/icon-company.png'
import IconTeam from '@/assets/img/company/icon-team.png'
import IconPartner from '@/assets/img/company/icon-partner.png'
import IconBusiness from '@/assets/img/company/icon-business.png'
import IconJoin from '@/assets/img/company/icon-join.png'

const imageProps = {
  width: 44,
  height: 44
}

const Banner = () => {

  const onTab = (type) => {
    document.getElementById(type).scrollIntoView({block: 'start',behavior: 'smooth'});
  }

  return (
    <div className={styles.banner}>
      <div className={styles['banner-layer']}>
        <div className={styles['banner-layer-image']}></div>
        <div className={styles['banner-layer-text']}><FormattedMessage id="company.banner.text1" /></div>
        <div className={classNames(styles['banner-layer-text'], 'font-blod')}><FormattedMessage id="company.banner.text2" /></div>
      </div>

      <div className={styles['layout-menu']}>
        <div key="cp" className={styles["menu-item"]} onClick={() => onTab("company-introduce")} >
          <img src={IconCompany} alt="" srcSet="" {...imageProps} />
          <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.company" /></span>
          <div className={styles["menu-under-line"]}></div>
        </div>
        <div key="tm" className={styles["menu-item"]} onClick={() => onTab("team-introduce")} >
          <img src={IconTeam} alt="" srcSet="" {...imageProps} />
          <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.team" /></span>
          <div className={styles["menu-under-line"]}></div>
        </div>
        <div key="pt" className={styles["menu-item"]} onClick={() => onTab("company-partner")} >
          <img src={IconPartner} alt="" srcSet="" {...imageProps} />
          <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.partner" /></span>
          <div className={styles["menu-under-line"]}></div>
        </div>
        <div key="bn" className={styles["menu-item"]} onClick={() => onTab("company-business")} >
          <img src={IconBusiness} alt="" srcSet="" {...imageProps} />
          <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.business" /></span>
          <div className={styles["menu-under-line"]}></div>
        </div>
        <div key="join" className={styles["menu-item"]} onClick={() => onTab("company-recruit")} >
          <img src={IconJoin} alt="" srcSet="" {...imageProps} />
          <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.joinUS" /></span>
          <div className={styles["menu-under-line"]}></div>
        </div>
      </div>
      <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('company-introduce').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
    </div>
  );
};

export default Banner;