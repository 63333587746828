/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { Popover } from 'antd';

import styles from './mobile.m.less'

class HeaderMobile extends Component {

    constructor(props){
        super(props);
        this.state={
            visible : false,
        }
        // 是否在进行点击
        this.isClick = false;
        // 点击次数
        this.clickNum = 0;
    }

    // 判断点击类型
    getClickCount = () => {
        this.clickNum++;
        // 毫秒内点击过后阻止执行定时器
        if (this.isClick) {
        return;
        }
        // 毫秒内第一次点击
        this.isClick = true;
        setTimeout(() => {
            // 超过1次都属于双击
            if (this.clickNum > 1) {
                document.body.scrollIntoView({block: 'start',behavior: 'smooth'})
                this.setState({visible: false})
            } 
            this.clickNum = 0;
            this.isClick = false;
        }, 300);
    };

    render() {
        const {visible} = this.state;
        const {history} = this.props;
        const routeChange = (path, title, state) =>{
            // if(title){
            //     document.title = title
            // }
            this.setState({visible: !visible}, ()=>history.push({pathname: path, state: state}))
        }

        const content = (
            <div>
                <div className={styles['menu-item']} onClick={() => routeChange('/index.htm', '首页')} ><span>首页</span></div>
                <div className={styles['menu-item']} onClick={() => routeChange('/xql-data.htm', '薪企数据')}><span>薪企数据</span></div>
                <div className={styles['menu-item']} onClick={() => routeChange('/cooperate.htm', '渠道合作')}><span>渠道合作</span></div>
                <div className={styles['menu-item']} onClick={() => routeChange('/index.htm', '首页', {position: 'product-matrix'})} ><span>产品服务</span></div>
                <div className={styles['menu-item']} onClick={() => routeChange('/news.htm', '新闻中心')}><span>新闻中心</span></div>
                <div className={styles['menu-item']} onClick={() => routeChange('/company.htm', '关于我们')}><span>关于我们</span></div>
            </div>
          );
        return (
            <div className={styles.main} >
                <Popover placement="bottomRight" content={content} trigger="click" arrowPointAtCenter overlayClassName={styles.menu} 
                getPopupContainer={triggerNode => triggerNode.parentNode}
                visible={visible}
                onVisibleChange={() => this.setState({visible: !this.state.visible})}
                >
                    <div className={styles['menu-icon']} onClick={this.getClickCount} ></div>
                </Popover>
            </div>
            
        );
    }
}

export default HeaderMobile;