import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import ImageIntroduce from '@/assets/img/product/rzch/image-introduce.png';
// import ImageBusiness from '@/assets/img/product/rzch/image-business.png';
import ImageCase from '@/assets/img/product/rzch/image-case.png';
import Title from './TitleMobile'

const Service_rzch = () => {
  return (
    <div className={styles['rz-container']}>
      <div className={styles['rz-service-title']} ><FormattedMessage id="home.product.service.b2b" /></div>

      <div className={styles['rz-card']} ><img alt="" src={ImageIntroduce} width="100%" /></div>
      <Title style={{marginTop : 20}} text={<FormattedMessage id="product.rz.introduce.title" />}/>
      <div className={styles['rz-text']} style={{marginTop: '20px'}}><FormattedMessage id="product.rzch.introduce.text1" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text2" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text3" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text4" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text5" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text6" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text7" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text8" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text9" /></div>
      <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text10" /></div>

      <div style={{marginTop : 20}} className={styles['rz-card']} ><img alt="" src={ImageCase} width="100%" /></div>
      <div style={{lineHeight: '33px', marginTop : 10}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.rz.case.title1" /></div>
      <div className={styles['rzch-case']} >
        <div className={styles['rzch-case-text']} >
          <div className={classNames(styles['title'], 'font-blod')} ><FormattedMessage id="product.rzch.case.text1" /></div>
          <div className={styles.text}><FormattedMessage id="product.rzch.case.text2" /></div>
        </div>
        <div className={styles['rzch-case-logo']} >
          <div className={styles.logo}></div>
          <div className={styles['logo-text']}></div>
        </div>
        <div className={styles['rzch-case-text']} >
          <div className={classNames(styles['title'], 'font-blod')} ><FormattedMessage id="product.rzch.case.text3" /></div>
          <div className={styles.text}><FormattedMessage id="product.rzch.case.text4" /></div>
        </div>
      </div>
      
    </div>
  );
};

export default Service_rzch;