import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import {ElfList} from 'elf-ui';
import styles from './index.m.less'
import { Spin, Icon } from 'antd';
import {fastDfsUrl} from 'utils/String'

const {Grid} = ElfList

const Activities = ({dataSource=[], total=0, loading=false, onLoad}) => {
  const renderList = dataSource.map((item, index) => {
    return <div key={index} className={styles["activies-item"]} onClick={() => window.open(item.articleUrl)} >
            <img src={fastDfsUrl(item.cover)} alt="" srcSet=""  />
            <div className={styles['text-container']}>
              <div className={styles.title}>{item.articleTitle}</div>
              <div className={styles.date}>{moment(item.publicDate).format('YYYY年MM月DD日')}</div>
            </div>
          </div>
  })
  

  return (
    <div className={styles.activies}>
      <Grid columns={3} gutter={[30, 23]}>
        {renderList}
      </Grid>
      {
        onLoad && total > dataSource.length && <div style={{marginTop: 20}} className={styles['load-more']} onClick={() => loading ? {} : onLoad()}>
          <Spin spinning={loading} indicator={<Icon type="loading" style={{ fontSize: 18, color: '#333333' }}  spin />}>
            <span>加载更多</span>
          </Spin>
        </div>
      }
    </div>
  );
};

Activities.propTypes = {
  dataSource: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onLoad: PropTypes.func
};

export default Activities;