import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'

const Banner = () => {
  return (
    <div className={styles.banner}>
      <div className={styles['banner-layer']}>
        <div className={styles['banner-layer-image']}></div>
        <div className={styles['banner-layer-text']}><FormattedMessage id="company.banner.text1" /></div>
        <div className={classNames(styles['banner-layer-text'], 'font-blod')}><FormattedMessage id="company.banner.text2" /></div>
      </div>
    </div>
  );
};

export default Banner;