import React from 'react';
import classNames from 'classnames'
import { ElfCard } from 'elf-ui'
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import ImageIntroduce from '@/assets/img/product/rzch/image-introduce.png';
// import ImageBusiness from '@/assets/img/product/rzch/image-business.png';
import ImageCase from '@/assets/img/product/rzch/image-case.png';
import Title from './Title'

const { Meta } = ElfCard;

const Service_rzch = () => {
  return (
    <div className={styles['rz-container']}>
      <ElfCard offset={{left: '345px'}} bodyOffset={{left: '268px'}} bodyClassName={styles['rz-card']} cover={<img alt="" src={ImageIntroduce} />} >
        <Meta >
          <Title text={<FormattedMessage id="product.rz.introduce.title" />}/>
          <div className={styles['rz-text']} style={{marginTop: '20px'}}><FormattedMessage id="product.rzch.introduce.text1" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text2" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text3" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text4" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text5" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text6" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text7" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text8" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text9" /></div>
          <div className={styles['rz-text']} ><FormattedMessage id="product.rzch.introduce.text10" /></div>
          {/* <Title text={<FormattedMessage id="product.rz.characteristic.title" />} style={{marginBottom: '5px'}}/>
          <div className={styles['rz-text']}>
            <span><FormattedMessage id="product.rzch.characteristic.text" /></span>
          </div> */}
        </Meta>
      </ElfCard>
      {/* <ElfCard offset={{right: '345px'}} bodyOffset={{right: '300px'}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={ImageBusiness} />} coverStyle={{right: '0px'}} style={{marginTop: 30}} 
      >
        <Meta style={{paddingRight: '10px'}}>
          <Title text={<FormattedMessage id="product.business-object" />}/>
          <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.rzch.business-object.text" /></div>
          <Title text={<FormattedMessage id="product.business-scenario" />} />
          <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.rzch.business-scenario.text" /></div>
          <Title text={<FormattedMessage id="product.business-service" />}  />
          <div className={styles['rz-text']} style={{marginTop: '10px'}}>
            <div><FormattedMessage id="product.rzch.business-service.text1" /></div>
            <div><FormattedMessage id="product.rzch.business-service.text2" /></div>
            <div><FormattedMessage id="product.rzch.business-service.text3" /></div>
            <div><FormattedMessage id="product.rzch.business-service.text4" /></div>
          </div>
        </Meta>
      </ElfCard> */}
      
      <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageCase} />} coverStyle={{left: '32px', width: '600px'}} bodyClassName={styles['rz-card-lg']} style={{marginTop: 30}} >
        <Meta style={{paddingRight: '10px', paddingLeft: '25px'}}>
          <div style={{lineHeight: '33px'}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.rz.case.title1" /></div>
          <div className={styles['rzch-case']} >
            <div className={styles['rzch-case-text']} >
              <div className={classNames(styles['title'], 'font-blod')} ><FormattedMessage id="product.rzch.case.text1" /></div>
              <div className={styles.text}><FormattedMessage id="product.rzch.case.text2" /></div>
            </div>
            <div className={styles['rzch-case-logo']} >
              <div className={styles.logo}></div>
              <div className={styles['logo-text']}></div>

            </div>
            <div className={styles['rzch-case-text']} >
              <div className={classNames(styles['title'], 'font-blod')} ><FormattedMessage id="product.rzch.case.text3" /></div>
              <div className={styles.text}><FormattedMessage id="product.rzch.case.text4" /></div>
            </div>
            
          
          </div>
        </Meta>
      </ElfCard>
    </div>
  );
};

export default Service_rzch;