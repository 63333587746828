/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styles from './index.m.less'

const XqlEncy = () => {
  return (
    <div className={styles.main}>
      <div style={{ fontSize: '24px', textAlign: 'center', marginBottom: '42px', fontWeight: 600}} >薪企链</div>
      <div>薪企链隶属于薪海科技（上海）有限公司，是一家现代人力资源服务业供应链服务商、人力资源产业数字科技品牌。薪企链运用大数据、人工智能、区块链、云计算、物联网等技术，搭建B2B企业用工交易、薪酬垫资融资服务、企业用工供需交易支付结算及信用担保等业务平台。平台是“找项目、找人员、找垫资、找交易增信担保”一站式服务解决方案的提供商。</div>
      <div style={{marginTop: '14px'}} >“薪企链”作为以人力资源服务产业供应链数字科技电子商务平台公司，始终致力于打造行业数字供应链生态圈，形成了集用工采购方、人资服务供应商、金融机构、政府部门、科研机构、监管机构、行业协会，“七位一体”的“开放+产业互联网”生态场景。</div>
      
      <div style={{marginTop: '21px'}} >
        <span>中文名: </span>
        <span>薪企链&nbsp;&nbsp;&nbsp;&nbsp;网站：</span>
        <a href="https://www.51xinhai.com" target='_blank'><span style={{ color: '#018CFF', cursor: 'pointer'}} >www.51xinhai.com</span></a>
      </div>
      <div><span>公司名称：</span><span>薪海科技（上海）有限公司</span></div>
      <div style={{ fontSize: '16px', fontWeight: 600, marginTop: '21px'}} >品牌介绍</div>
      <div>薪企链总部位于上海，在深圳、广州、浙江、山东、江苏、河南等设有办事机构，核心团队来自股份制银行总行高管、互联网金融科技公司等。</div>
      <div>薪海科技运用大数据、人工智能、区块链、云计算、物联网等技术搭建的人力资源服务业数字科技服务平台。平台立足赋能、促进人力资源产业数字化转型，助力人力资源服务业发展。</div>
      <div style={{ fontSize: '16px', fontWeight: 600, marginTop: '42px', marginBottom: '21px'}} >产品与服务</div>
      <div style={{ fontWeight: 600}} >1、薪企链B2B企业用工交易各方：</div>
      <div>(1) 用工企业</div>
      <div>(2) 人力资源服务供应商（一手单、二手单）</div>
      <div>(3) 学校</div>
      <div>(4) 产业园</div>
      <div>(5) 各类中介服务机构</div>
      <div style={{ fontWeight: 600, marginTop: '21px'}} >2、平台产品与服务：</div>
      <div>(1) 用工企业、人力资源服务企业供需信息发布；</div>
      <div>(2) 企业用工B2B供需双方交易智能撮合匹配；</div>
      <div>(3) 企业图片、视频、文字宣传，受托企业征信展示等；</div>
      <div>(4) 招工岗位、员工生活环境图片、视频等展示；</div>
      <div>(5) 应聘员工可登录“薪企链”小程序查询用工信息：</div>
      <div style={{ textIndent: 14}} >①用工单位、聘用单位的征信（诚信）；②工作岗位、生活环境（如餐厅，宿舍图片、视频）等信息；</div>
      <div>(6) 招投标保证金、预付（履约）金的担保或资金监管；</div>
      <div>(7) 招投标保证金、履约保证金、预付金等垫资服务。</div>

      <div style={{ fontWeight: 600, marginTop: '21px'}} >3、主要功能模块：</div>
      <div>平台有：企业征信（诚信）展示、供需信息发布、智能交易撮合服务、动态发布等菜单模块。</div>
      <div>举例：</div>
      <div>1、 首页，找企业、查企业征信、画像；</div>
      <div>2、 选择菜单工作台：员工管理、发布管理、订单管理；</div>
      <div>3、 信息发布、查看：用工供应、需求、动态等；</div>
      <div>4、 企业申请各项垫资服务（日结垫资、预付款、履约保证金等）；</div>
      <div>5、 在线交易资金的监管、企业信用担保服务。</div>

      <div style={{ fontSize: '16px', fontWeight: 600, marginTop: '21px'}} >薪海大数据平台</div>
      <div>平台数据库已有用工企业、人力资源服务公司、平台以国家公示数据为基础，以人工智能、可信计算、区块链等技术为手段，构建了我国人力资源商业世界的数字双胞胎。</div>
      <div>平台用实时及历史信息，通过自然语言处理、统计学习等方法，对工商信息、法律、招投标、與情等数据进行了开放域的实体抽取、消歧、对齐与推理，将分散的商业信息实现统一表征。即为数据库内的企业提供大数据智能动态画像、征信评级，为用工企业与人资企业需、供业务交易和企业融资等服务。大数据风控风险评估模型，是公司与商务部国际贸易服务研究院联合开发的系统。</div>
      
      <div style={{ fontSize: '16px', fontWeight: 600, marginTop: '42px', marginBottom: '21px'}} >薪企链垫资融资服务平台</div>
      <div style={{ fontWeight: 600}} >1、平台产品与服务：</div>
      <div>(1) 日结垫资融资服务；</div>
      <div>(2) 预付工资垫资融资服务；</div>
      <div>(3) 周、月、循环垫资融资服务；</div>
      <div>(4) 智能工时采集考勤打卡业务系统提供、发薪服务。</div>
      <div style={{ fontWeight: 600, marginTop: '21px'}} >2、薪酬垫资服务的优势：</div>
      <div>(1) 利息、综合服务费用低；</div>
      <div>(2) 不需要抵押物、融资金额大〈可为开发票数80％～90％）；</div>
      <div>(3) 期限灵活〈用几天计算几天利息、随借随还、最长账期5个月）；</div>
      <div>(4) 免费智能发薪服务，跨行不需要手续费。</div>
    </div>
  );
};

export default XqlEncy;