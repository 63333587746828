import React, {Component} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import Home from '@/pages/home'
import Company from '@/pages/company'
import Product from '@/pages/product'
import News from '@/pages/news'
import RecruitInfo from '@/pages/recruit/recruit-info'
import Cooperate from '@/pages/cooperate'
import XQLData from '@/pages/xql-data'
import XqlEncy from '@/pages/xql-ency'
import {ScrollToTop} from 'components/Router'

export default class BasicRouter extends Component {
    render () {
      return (
        <ScrollToTop>
          <Switch>
            <Route exact path="/index.htm"  component= {Home}/>
            <Route exact path="/xql-data.htm"  component= {XQLData}/>
            <Route exact path="/company.htm" component= {Company}/>
            <Route exact path="/company.htm/xql" component= {XqlEncy}/>
            <Route path="/product.htm" component= {Product}/>
            <Route path="/news.htm" component= {News}/>
            <Route path="/company/recruitinfo" component={RecruitInfo}/>
            <Route path="/cooperate.htm" component= {Cooperate}/>
            <Redirect from="/" to="/index.htm" />
          </Switch>
        </ScrollToTop>
      )
    }
  }