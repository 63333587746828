import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import ImageIntroduce from '@/assets/img/product/rz/image_introduce.png';
import ImageDay from '@/assets/img/product/rz/image_day.png';
import ImageWeek from '@/assets/img/product/rz/image_week.png';
import Title from './TitleMobile'

const Service_gzdz = () => {
  return (
    <div className={styles['rz-container']}>
      <div className={styles['rz-service-title']} ><FormattedMessage id="home.product.service.gzdz" /></div>

      <div className={styles['rz-card-lg']} ><img alt="" src={ImageIntroduce} width="100%" /></div>
      <Title style={{marginTop : 20}} text={<FormattedMessage id="product.rz.introduce.title" />}/>
      <div className={classNames(styles['rz-introduce'], styles['rz-text'])}><FormattedMessage id="product.gzdz.introduce.text" /></div>
      <Title text={<FormattedMessage id="product.rz.characteristic.title" />} className={styles['rz-characteristic']}/>
      <div className={styles['rz-text']}><FormattedMessage id="product.gzdz.characteristic.text" /></div>
      
      <div className={styles['rz-card']} style={{marginTop: 20}} ><img alt="" src={ImageDay} width="100%" /></div>
      <Title text={<FormattedMessage id="product.business-object" />} style={{marginTop: '20px'}} />
      <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.gzdz.business-object.text" /></div>
      <Title text={<FormattedMessage id="product.business-scenario" />} style={{marginBottom: '10px'}} />
      <div className={styles['rz-text']}><span><FormattedMessage id="product.gzdz.business-scenario.text" /></span></div>

      <div className={styles['rz-card']} style={{marginTop: 20}} ><img alt="" src={ImageWeek} width="100%" /></div>
      <div style={{lineHeight: '33px', marginTop: 20}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.gzdz.case.title1" /></div>
      <div style={{lineHeight: '33px'}} className={styles['rz-case-text']}><FormattedMessage id="product.gzdz.case.text1" /></div>
      <div style={{lineHeight: '33px'}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.gzdz.case.title2" /></div>
      <div style={{lineHeight: '33px'}} className={styles['rz-text']}><FormattedMessage id="product.gzdz.case.text2" /></div>


      {/* <ElfCard bodyOffset={{left: '633px'}} cover={<img alt="" src={ImageIntroduce} />} coverStyle={{left: '20px'}} bodyClassName={styles['rz-card-lg']}>
        <Meta>
          <Title text={<FormattedMessage id="product.rz.introduce.title" />}/>
          <div className={classNames(styles['rz-introduce'], styles['rz-text'])}><FormattedMessage id="product.gzdz.introduce.text" /></div>
          <Title text={<FormattedMessage id="product.rz.characteristic.title" />} className={styles['rz-characteristic']}/>
          <div className={styles['rz-text']}><FormattedMessage id="product.gzdz.characteristic.text" /></div>
        </Meta>
      </ElfCard> */}
      {/* <ElfCard offset={{right: '345px'}} bodyOffset={{right: '280px'}} bodyClassName={styles['rz-card']} style={{marginTop: 30}} 
        cover={<img alt="" src={ImageDay} />} coverStyle={{right: '0px'}} 
      >
        <Meta >
          <Title text={<FormattedMessage id="product.business-object" />} style={{marginTop: '20px'}} />
          <div className={classNames(styles['rz-text'])} style={{marginTop: '20px', marginBottom: '20px'}}><FormattedMessage id="product.gzdz.business-object.text" /></div>
          <Title text={<FormattedMessage id="product.business-scenario" />} style={{marginBottom: '20px'}} />
          <div className={styles['rz-text']}><span><FormattedMessage id="product.gzdz.business-scenario.text" /></span></div>
        </Meta>
      </ElfCard>
      <ElfCard offset={{left: '345px'}} bodyOffset={{left: '268px'}} style={{marginTop: 30}} bodyClassName={styles['rz-card']}
        cover={<img alt="" src={ImageWeek} />} >
        <Meta>
          <div style={{lineHeight: '33px'}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.gzdz.case.title1" /></div>
          <div style={{lineHeight: '33px'}} className={styles['rz-case-text']}><FormattedMessage id="product.gzdz.case.text1" /></div>
          <div style={{lineHeight: '33px'}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.gzdz.case.title2" /></div>
          <div style={{lineHeight: '33px', width: '435px'}} className={styles['rz-text']}><FormattedMessage id="product.gzdz.case.text2" /></div>
         </Meta>
      </ElfCard> */}
    </div>
  );
};

export default Service_gzdz;