import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import styles from './index.m.less'

const index = ({className={}, style={}, children}) => {
  const _len = React.Children.count(children);
  const renderChild = children.map((item, i) => {
    return <React.Fragment key={i} >
      {item}
      {i<_len-1 && <div style={{flex: '1'}}></div>}
    </React.Fragment>;
  })
  return (
    <div className={classNames(styles.columns, className)} style={style} >
      {renderChild}
    </div>
  );
};

index.propTypes = {
  children: PropTypes.array.isRequired
};

export default index;