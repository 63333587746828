import React from 'react';
// import { Modal} from 'antd'
import styles from './mobile.m.less';
import { FormattedMessage } from 'react-intl';
// import {fastDfsUrl} from 'utils/String'
// import { Player, BigPlayButton } from 'video-react';
import WxB2B from '@/assets/img/home/banner/wx-b2b.png'
import WxChannel from '@/assets/img/home/banner/wx-channel.png'

const Banner = () => {

  return (
    <div className={styles.banner}>
      <div className={styles.logo}></div>
      <div className={styles["cloud-layer"]}>
        <div className={styles["cloud-layer-image"]}></div>
        <div className={styles["cloud-layer-text"]}>
          <div >
            <FormattedMessage id="home.banner.saascloud1" />
          </div>
          <div>
            <FormattedMessage id="home.banner.saascloud2" />
          </div>
        </div>
      </div>
      <div className={styles["cloud-layer-switch"]}>
          <div className={styles["switch-l"]}>
            <div className={styles['switch-text']}>
              <div style={{fontSize: '24px', lineHeight: '33px', textShadow: '2px 2px 4px #002A87'}}><FormattedMessage id="home.banner.switch1.text1" /></div>
              <div style={{fontSize: '30px', lineHeight: '44px', textShadow: '2px 2px 4px #002A87'}}><FormattedMessage id="home.banner.switch1.text2" /></div>
              <div style={{fontSize: '20px', lineHeight: '22px', marginTop: '22px'}}><FormattedMessage id="home.banner.switch1.text3" /></div>
            </div>
            <div className={styles['switch-code']}>
              <div className={styles['switch-wx']}>
                <img src={WxB2B} alt="" srcSet=""/>
              </div>
              <div className={styles['switch-wx-text']}><FormattedMessage id="home.banner.switch1.text4" /></div>
            </div>
          </div>
          <div className={styles["switch-r"]}>
            <div className={styles['switch-text']}>
              <div style={{fontSize: '24px', lineHeight: '33px', textShadow: '2px 2px 4px #341A8F'}}><FormattedMessage id="home.banner.switch2.text1" /></div>
              <div style={{fontSize: '34px', lineHeight: '44px', textShadow: '2px 2px 4px #341A8F'}}><FormattedMessage id="home.banner.switch2.text2" /></div>
              <div style={{fontSize: '20px', lineHeight: '22px', marginTop: '22px'}}><FormattedMessage id="home.banner.switch2.text3" /></div>
            </div>
            <div className={styles['switch-code']}>
              <div className={styles['switch-wx']}>
                <img src={WxChannel} alt="" srcSet=""/>
              </div>
              <div className={styles['switch-wx-text']}><FormattedMessage id="home.banner.switch2.text4" /></div>
            </div>
          </div>
        </div> 
        <div className={styles['down-icon']} ><div className={styles.icon} onClick={() => document.getElementById('product-matrix').scrollIntoView({block: 'start',behavior: 'smooth'})} ></div></div>
    </div>
  );
};

export default Banner;