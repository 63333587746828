import React, {useState, useEffect} from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import {ElfList} from 'elf-ui';
import Introduce from './Introduce'
import Team from './Team'
import Partner from './Partner'
import BusinessDistribution from './BusinessDistribution'
import IconCompany from '@/assets/img/company/icon-company.png'
import IconTeam from '@/assets/img/company/icon-team.png'
import IconPartner from '@/assets/img/company/icon-partner.png'
import IconBusiness from '@/assets/img/company/icon-business.png'
import Recruit from '../recruit'
import IconJoin from '@/assets/img/company/icon-join.png'
import  Qs from 'qs';

const {Grid} = ElfList

const Layout = ({sType='cp', position=undefined, history}) => {
  const [active, onActive] = useState('');
  const imageProps = {
    width: 44,
    height: 44
  }
  useEffect(()=> {
    onActive(sType.trim())
  },[sType]);

  const onTab = (type) => {
    onActive(type);
    history.push({pathname: '/company.htm', search: Qs.stringify({sType: type}), state:{ unscroll: true }})
  }
  return (
    <div className={styles.layout}>
      <div className={styles["layout-menu"]}>
        <Grid columns={5} gutter={12}>
        {/* <Grid columns={4} gutter={27}> */}
          <div key="cp" className={styles["menu-item"]} onClick={() => onTab("cp")} active={ active === "cp" ? 'true' : "false"}>
            <img src={IconCompany} alt="" srcSet="" {...imageProps} />
            <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.company" /></span>
            <div className={styles["menu-under-line"]}></div>
          </div>
          <div key="tm" className={styles["menu-item"]} onClick={() => onTab("tm")} active={ active === "tm" ? 'true' : "false"}>
            <img src={IconTeam} alt="" srcSet="" {...imageProps} />
            <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.team" /></span>
            <div className={styles["menu-under-line"]}></div>
          </div>
          <div key="pt" className={styles["menu-item"]} onClick={() => onTab("pt")} active={ active === "pt" ? 'true' : "false"}>
            <img src={IconPartner} alt="" srcSet="" {...imageProps} />
            <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.partner" /></span>
            <div className={styles["menu-under-line"]}></div>
          </div>
          <div key="bn" className={styles["menu-item"]} onClick={() => onTab("bn")} active={ active === "bn" ? 'true' : "false"}>
            <img src={IconBusiness} alt="" srcSet="" {...imageProps} />
            <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.business" /></span>
            <div className={styles["menu-under-line"]}></div>
          </div>
          <div key="xhy" className={styles["menu-item"]} onClick={() => onTab("ju")} active={ active === "ju" ? 'true' : "false"}>
            <img src={IconJoin} alt="" srcSet="" {...imageProps} />
            <span className={styles["menu-item-text"]}><FormattedMessage id="company.menu.joinUS" /></span>
            <div className={styles["menu-under-line"]}></div>
          </div>
        </Grid>
      </div>
      {(()=>{
         switch(active){
             case "cp":return <Introduce position={position} history={history}/>;
             case "tm":return <Team />;
             case "pt": return <Partner />;
             case "bn": return <BusinessDistribution />;
             case "ju": return <Recruit history={history}/>
             default:return null;
          }
        }
      )()}
      
    </div>
  );
};

export default Layout;