export default {
  "cooperate.banner.text1" : '高佣金',
  "cooperate.banner.text2" : '、',
  "cooperate.banner.text3" : '零风险',
  'cooperate.banner.text4': '，薪企链助您实现',
  'cooperate.banner.text5': '资源变现',

  'cooperate.content1.title': '成为渠道业务合作人的核心优势',
  'cooperate.content1.sub-title1': '零投入、收益高、市场潜力大',
  'cooperate.content1.sub-text1': '无需投入一分钱，就能赚取佣金；推荐客户越多，收益越多；万亿级市场潜力巨大，前景广阔。',
  'cooperate.content1.sub-title2': '完善的服务体系公司可以提供',
  'cooperate.content1.sub-text2': '稳定的产品技术体系、完善的运营服务体系、配套的业务培训体系、完善的渠道业务合作人制度体系，多重服务体系保障城市合作人权益。',
  'cooperate.content1.sub-title3': '强大的项目落地执行能力',
  'cooperate.content1.sub-text3': '风控、销售跨区域联动，助力垫资项目快速成单并落地。',

  'cooperate.content2.title': '渠道业务合作人佣金结算',
  'cooperate.content2.text1': '推荐的项目完成资金垫付，经财务审核无误，资金垫付完成的次月即支付',
  'cooperate.content2.text2': '5000元',
  'cooperate.content2.text3': '佣金',
  'cooperate.content3.title': '渠道业务合作人招募要求',
  'cooperate.content3.text': '招募人员必须人品好，认可并遵守《薪企链业务合作协议》有丰富的人力资源（派遣、外包）企业客户资源，并能够推荐企业关键决策者富有挑战精神，乐于强强联合、互惠互赢，愿意投身人力资源产业，助力人力资源企业发展兼职或专职均可',
  'cooperate.content4.title': '渠道业务合作人职责',
  'cooperate.content4.text': '渠道业务合作人职责负责推荐有垫资需求的各地人力资源（派遣、外包）服务企业只需提供企业及关键人信息，后由公司专人对接项目，前期需要配合公司与企业对接人进行接洽',
  'cooperate.content5.title': '业务合作流程',

  'cooperate.wechat1.title': '进入薪海云小程序',
  'cooperate.wechat1.text': '您可扫码进入，或者在微信中，搜索“薪海云信息员”小程序',
  'cooperate.wechat2.title': '成为薪海云信息专员',
  'cooperate.wechat2.text': '注册并签约，成为薪海云信息专员',
  'cooperate.wechat3.title': '推荐客户赚取佣金',
  'cooperate.wechat3.text': '经财务审核无误，资金垫付完成的次月即支付5000元佣金',

  'cooperate.apply.text': '我要申请',
  'cooperate.contact.title': '联系我们',
  'cooperate.contact.note': '请留下您的联系信息，我们收到您的申请后，会安排专业的顾问联系您',
  'cooperate.contact.cancel': '取消',
  'cooperate.contact.submit': '立即提交',

  
  'cooperate.support.title': '渠道合作八大扶持',
  'cooperate.support.sub-title1': '赋能层',
  'cooperate.support.sub-title2': '基建层',
  'cooperate.support.text1': '培训支持',
  'cooperate.support.icon1.text': '注册登录后48小时内进行1对1产品培训',
  'cooperate.support.icon2.text': '每周五渠道合作人精准推荐客户培训',
  'cooperate.support.icon3.text': '60天带新追踪专人答疑。',
  
  'cooperate.support.text2': '会销支持',
  'cooperate.support.text3': '针对协会、产业园、人力资源服务企业等有人力资源背景的渠道合作人，总部运营可以支持联办各类大小型活动，协助渠道合作人进行资源变现。',
  'cooperate.support.text4': '官方授牌',
  'cooperate.support.text5': '对优秀的渠道合作人/渠道合作商官方授牌，全力提升合作伙伴精准推荐客户和资源变现能力。',
  'cooperate.support.text6': '专属服务',
  'cooperate.support.text7': '专属客服和运营总监全程支持、专属渠道合作人服务沟通群、推荐客户沟通服务群。',
  'cooperate.support.text8': '佣金丰厚',

  'cooperate.support.text9': '1.薪酬融资服务佣金支付规则',
  'cooperate.support.text10': '佣金计算方式：按借款人使用天数计算（包含放款日和还款日）',
  'cooperate.support.text11': '佣金结算方式：按自然月累计时间统一结算',
  'cooperate.support.text12': '佣金计算公式：',
  'cooperate.support.text13': '还款金额（元）*年化率/360 天*用款天数*渠道商佣金比例',
  'cooperate.support.text20': '举例：',
  'cooperate.support.text21': '客户在4月1日借款100万，5月31日还款100万，用款61天，按照年化8%，分配佣金是10%的比例计算，渠道商所得佣金1355.6元。',
  'cooperate.support.text22': '计算公式：',
  'cooperate.support.text23': '1000000（元）*0.08/360（天）*61（天）*0.1=1355.6 1000000（元）*0.08/360（天）*61（天）*0.1=1355.6元。',
  'cooperate.support.text17': '2.招商入驻薪企链平台客户佣金支付规则',
  'cooperate.support.text18': '渠道商推荐的用工单位、一手单人资企业、二手单人资企业（招聘门店、经纪人）、产业园、学校、劳动者等，其在薪企链平台购买相关平台产品服务（含会员费、技术费、充值）等所支付费用，按照薪企链平台净收入的10%向渠道商支付佣金。',
  'cooperate.support.text27': '3.校企合作、商业保险，电子签，工时本，人才港智慧就业服务佣金支付规则',
  'cooperate.support.text38': '渠道商推荐的校企合作、商业保险，电子签，工时本，人才港智慧就业务成单后，按薪企链平台净收入的50%支付佣金。',
  'cooperate.support.text37': '4.灵活用工服务佣金支付规则',
  'cooperate.support.text14': '渠道商推荐灵活用工服务，业务成单后，按薪企链平台净收入的30%支付佣金。',
  'cooperate.support.text47': '5.VIP套餐服务佣金支付规则',
  'cooperate.support.text16': '渠道商推荐薪企链VIP套餐服务，成单后，按薪企链平台净收入的20%支付佣金。',

  'cooperate.channel.left.title': '他们都是我们的渠道合作商',
  'cooperate.channel.left.text1': '1、银行等金融机构从业者',
  'cooperate.channel.left.text2': '2、劳务派遣外包公司老板及从业者',
  'cooperate.channel.left.text3': '3、人力资源服务行业协会工作者',
  'cooperate.channel.left.text4': '4、服务劳务派遣外包企业的第三方从业者 ',
  'cooperate.channel.left.text5': '5、产业园招商人员 ',
  'cooperate.channel.left.text6': '6、用工单位从业者',
  'cooperate.channel.left.text7': '7、学校/政府等机构从业者',
  'cooperate.channel.right.title': '渠道合作人招募要求',
  'cooperate.channel.right.text': '招募人员必须人品好，认可并遵守《薪企链业务合作协议》有丰富的人力资源（派遣、外包）企业客户资源，并能够推荐企业关键决策者，富有挑战精神，乐于强强联合、互惠互赢，愿意投身人力资源产业，助力人力资源企业发展兼职或专职均可。',

  'cooperate.publicity.menu1': '注册成为薪企链渠道商',
  'cooperate.publicity.menu2': '推广产品',
  'cooperate.publicity.menu3': '发展团队赚佣金',
}