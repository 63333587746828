import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from './mobile.m.less'
import ImageIntroduce from '@/assets/img/product/zngs/image-introduce.png';
import ImageBusiness from '@/assets/img/product/zngs/image-business.png';
import Title from './TitleMobile'

const Service_zngs = () => {
  return (
    <div className={styles['rz-container']}>
      <div className={styles['rz-service-title']} ><FormattedMessage id="home.product.service.part3" /></div>

      <div className={styles['rz-card']} ><img alt="" src={ImageIntroduce} width="100%" /></div>
      <Title style={{marginTop: 20}}  text={<FormattedMessage id="product.rz.introduce.title" />}/>
      <div className={styles['rz-text']} style={{marginTop: '10px', marginBottom: '5px'}}><FormattedMessage id="product.zngs.introduce.text1" /></div>
      <Title text={<FormattedMessage id="product.rz.characteristic.title" />} style={{marginBottom: '10px'}}/>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.zngs.characteristic.text1" /></span>
        <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text2" /></span>
        <span><FormattedMessage id="product.zngs.characteristic.text3" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.zngs.characteristic.text4" /></span>
        <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text5" /></span>
        <span><FormattedMessage id="product.zngs.characteristic.text6" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.zngs.characteristic.text7" /></span>
        <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text8" /></span>
        <span><FormattedMessage id="product.zngs.characteristic.text9" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.zngs.characteristic.text10" /></span>
        <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text11" /></span>
        <span><FormattedMessage id="product.zngs.characteristic.text12" /></span>
      </div>
      <div className={styles['rz-text']}>
        <span><FormattedMessage id="product.zngs.characteristic.text13" /></span>
        <span className="font-blod"><FormattedMessage id="product.zngs.characteristic.text14" /></span>
        <span><FormattedMessage id="product.zngs.characteristic.text15" /></span>
      </div>


      
      <div className={styles['rz-card']} style={{marginTop: 20}} ><img alt="" src={ImageBusiness} width="100%" /></div>
      <Title text={<FormattedMessage id="product.business-object" />} style={{marginTop: '20px'}} />
      <div className={classNames(styles['rz-text'])} style={{marginTop: '10px', marginBottom: '20px'}}><FormattedMessage id="product.zngs.business-object.text" /></div>
      <Title text={<FormattedMessage id="product.business-scenario" />} style={{marginBottom: '10px'}} />
      <div className={styles['rz-text']}><span><FormattedMessage id="product.zngs.business-scenario.text" /></span></div>

      <div style={{lineHeight: '33px', marginTop: '20px'}} className={classNames("font-blod", styles['case-text'])}><FormattedMessage id="product.rz.case.title1" /></div>
      <div style={{lineHeight: '33px'}} className={styles['rz-case-text']}><FormattedMessage id="product.zngs.case.text" /></div>

      
    </div>
  );
};

export default Service_zngs;