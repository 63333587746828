import React from 'react';
import classNames from 'classnames'
import { ElfCard } from 'elf-ui'
import { FormattedMessage } from 'react-intl';
import styles from './index.m.less'
import ImageCycle from '@/assets/img/product/rz/image_cycle.png';
import Title from './Title'

const { Meta } = ElfCard;

const Service_qyxy = () => {
  return (
    <div className={styles['rz-container']}>
      <ElfCard offset={{left: '345px'}} bodyOffset={{left: '268px'}} bodyClassName={styles['rz-card']}  cover={<img alt="" src={ImageCycle} />} >
        <Meta>
          <Title text={<FormattedMessage id="product.rz.introduce.title" />}/>
          <div className={classNames(styles['rz-introduce'], styles['rz-text'])}><FormattedMessage id="product.qyxy.introduce.text" /></div>
        </Meta>
      </ElfCard>
    </div>
  );
};

export default Service_qyxy;